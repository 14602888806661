import { Box, Button, Typography } from '@opswat/react-ui'

interface IProps {
  id: string
  name: string
  imageUrl: string
  isSelected: boolean
  handleSelectProduct: (id: string) => void
}

const SubmitCaseProduct: React.FC<IProps> = ({ id, name, imageUrl, isSelected, handleSelectProduct }) => {
  return (
    <Button
      fullWidth
      sx={{
        height: '100%',
        backgroundColor: isSelected ? '#1D6BFC' : '#E9EAEB',
        borderRadius: '5px',
        padding: '24px 12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        '&:hover': {
          backgroundColor: isSelected ? '#195DDB' : '#D2D4D6'
        }
      }}
      onClick={() => handleSelectProduct(id)}
    >
      {imageUrl ? (
        <img
          alt={name}
          src={imageUrl}
          width={'24px'}
          height={'24px'}
          style={{
            filter: isSelected ? 'invert(1)' : 'none'
          }}
        />
      ) : (
        <Box
          sx={{
            width: '24px',
            height: '24px'
          }}
        />
      )}
      <Typography
        variant="subtitle2"
        height="40px"
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: isSelected ? '#FFFFFF' : '#000000'
        }}
      >
        {name}
      </Typography>
    </Button>
  )
}

export default SubmitCaseProduct
