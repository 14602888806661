import { useState, useCallback } from 'react'

type UseDialogResult = {
  isOpen: boolean
  openDialog: () => void
  closeDialog: () => void
  toggleDialog: () => void
}

export const useDialog = (initialState = false): UseDialogResult => {
  const [isOpen, setIsOpen] = useState(initialState)

  const openDialog = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closeDialog = useCallback(() => {
    setIsOpen(false)
  }, [])

  const toggleDialog = useCallback(() => {
    setIsOpen(prev => !prev)
  }, [])

  return {
    isOpen,
    openDialog,
    closeDialog,
    toggleDialog
  }
}
