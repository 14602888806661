import { useCallback, useMemo, useState } from 'react'

import { selectOrganizations } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useTypedSelector } from 'myopswat-web/src/store'

export const useFilteredOrganizations = () => {
  const organizationsData = useTypedSelector(selectOrganizations)
  const [searchValue, setSearchValue] = useState('')

  const handleSearch = useCallback((value: string) => {
    setSearchValue(value)
  }, [])

  const filteredOrgs = useMemo(() => {
    return organizationsData.filter((org: any) => org.name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [organizationsData, searchValue])

  return { searchValue, handleSearch, filteredOrgs }
}
