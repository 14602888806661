export const TIME_FILTERS: Record<string, string> = {
  last24Hours: 'Last 24 hours',
  last7Days: 'Last 7 days',
  last30Days: 'Last 30 days',
  last12Months: 'Last 12 months'
}

export const ORGANIZATION_EVENT_ACTION_TYPES: Record<string, string> = {
  invited: 'Invited',
  changed: 'Changed',
  removed: 'Removed',
  signed_in: 'Sign In',
  signed_out: 'Sign Out',
  added: 'Added',
  deleted: 'Deleted'
}

export const ORGANIZATION_EVENT_ACTIONS: Record<string, string> = {
  organization_user_invited: 'invited',
  organization_user_changed: 'changed',
  // ACCOUNT_DELETED: 'Delete',
  // ORGANIZATION_ACCOUNT_ADDED: 'ADD',
  organization_user_removed: 'removed',
  organization_user_signed_in: 'signed_in',
  organization_user_signed_out: 'signed_out',
  portal_role_created: 'added',
  portal_role_updated: 'changed',
  portal_role_deleted: 'deleted'
}
