import { FC, useMemo } from 'react'

import { Grid, TableContainer, TreeCheckboxTable } from '@opswat/react-ui'

interface IProps {
  data: any[]
  isLoading: boolean
  columns: any[]
  childrenKeys: string[][]
  defaultExpandedAll?: boolean
  noDataText?: React.ReactNode
  noHeader?: boolean
}

const NestedSubOrgTable: FC<IProps> = ({
  data,
  isLoading,
  columns,
  childrenKeys,
  defaultExpandedAll,
  noDataText,
  noHeader
}) => {
  const TableMemo = useMemo(
    () => (
      <TreeCheckboxTable
        isChildTable
        defaultExpanded
        isLoading={isLoading}
        columns={columns}
        data={data}
        childrenKeys={childrenKeys}
        levelPadding={24}
        noDataText={noDataText ?? 'There are no licenses to display.'}
        childrenExpandedLevels={[0, 2]}
        noHeader={noHeader}
        noDataTextProps={{
          sx: {
            my: 1
          }
        }}
      />
    ),
    [data, isLoading, columns, childrenKeys, defaultExpandedAll, noHeader]
  )

  return (
    <Grid item xs={12}>
      <TableContainer sx={{ overflowY: 'auto' }}>{TableMemo}</TableContainer>
    </Grid>
  )
}

export default NestedSubOrgTable
