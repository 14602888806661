import _get from 'lodash/get'

export const useGetChatbotUser = (profileData: any): any => {
  return {
    firstname: _get(profileData, ['firstName']) || '',
    lastname: _get(profileData, ['lastName']) || '',
    email: _get(profileData, ['email']) || '',
    contactID: _get(profileData, ['sfdcContactId']) || '',
    accountID: _get(profileData, ['currentOrganizationAccountId']) || '',
    userType: _get(profileData, ['sfdcData', 'user_type']) || '',
    countryRestricted: _get(profileData, ['sfdcData', 'country_restricted']) || '',
    extendedCaseView: (_get(profileData, ['sfdcData', 'extended_case_view']) || false).toString(),
    userCountry: _get(profileData, ['fullCountryName']) || '',
    organizationID: _get(profileData, ['currentOrganizationId']) || ''
  }
}
