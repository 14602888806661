import React, { FC, useEffect, useState } from 'react'

import _map from 'lodash/map'
import _get from 'lodash/get'
import _size from 'lodash/size'
import _sortBy from 'lodash/sortBy'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  useTheme,
  Typography,
  TableLoading,
  DialogAdvanced,
  TypographyNoData
} from '@opswat/react-ui'
import { CloseIcon } from '@opswat/react-icon'

import { IDownloadedProductInput } from 'myopswat-web/src/api/product/types'

import BoxDownloadedCell from './BoxDownloadedCell'
import BoxDownloadedView from './BoxDownloadedView'
import DialogDownloadVersion from 'myopswat-web/src/components/Dialog/DialogDownloadVersion'

interface IProps {
  downloadedProductsData: any
  isFetchingDownloadedProducts: boolean
  handleDownloadedProductAdd: (data: IDownloadedProductInput, isICAP?: boolean) => void
  icapIntegrationsData: any
  productDetailData: any
  getProduct: (productId: string) => void
  isFetchingProductDetail: boolean
}

const BoxDownloadedTable: FC<IProps> = ({
  downloadedProductsData,
  isFetchingDownloadedProducts,
  handleDownloadedProductAdd,
  icapIntegrationsData,
  productDetailData,
  getProduct,
  isFetchingProductDetail
}) => {
  const theme = useTheme()

  const [selectItem, setSelectItem] = useState<any>()
  const [isOpenPVersion, setIsOpenPVersion] = useState<boolean>(false)
  const [isOpenView, setIsOpenView] = useState<boolean>(false)

  useEffect(() => {
    if (_get(selectItem, 'slug', '')) {
      getProduct(_get(selectItem, 'slug', ''))
    }
  }, [selectItem])

  const sortedArray = _sortBy(downloadedProductsData, 'name')

  return (
    <>
      <Box sx={{ minHeight: '85vh' }}>
        {isFetchingDownloadedProducts && <TableLoading />}
        {!isFetchingDownloadedProducts && _size(downloadedProductsData) === 0 && (
          <TypographyNoData>You have not downloaded any products.</TypographyNoData>
        )}
        {!isFetchingDownloadedProducts && _size(downloadedProductsData) > 0 && (
          <TableContainer component={Box}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 400, width: 400 }}
                  >
                    <Typography>Product</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0.25,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      minWidth: 150,
                      width: 150,
                      textAlign: 'center'
                    }}
                  >
                    <Typography>Number of downloads</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0.25,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      minWidth: 180,
                      width: 180,
                      textAlign: 'center'
                    }}
                  >
                    <Typography>Last download time</Typography>
                  </TableCell>
                  <TableCell
                    sx={{ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 150 }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_map(sortedArray, (item, idx) => (
                  <BoxDownloadedCell
                    key={idx.toString()}
                    data={item}
                    setSelectItem={setSelectItem}
                    setIsOpenPVersion={setIsOpenPVersion}
                    setIsOpenView={setIsOpenView}
                    index={idx}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      {selectItem && (
        <DialogDownloadVersion
          icapIntegrationsData={icapIntegrationsData}
          productDetailData={productDetailData}
          isOpenPVersion={isOpenPVersion}
          setIsOpenPVersion={setIsOpenPVersion}
          handleDownloadedProductAdd={handleDownloadedProductAdd}
          isFetchingProductDetail={isFetchingProductDetail}
        />
      )}

      <DialogAdvanced
        open={isOpenView}
        title={`Download History - ${_get(selectItem, 'name', '--')}`}
        iconClose={<CloseIcon />}
        onClose={() => setIsOpenView(false)}
        content={<BoxDownloadedView productDetailData={selectItem} />}
        dialogProps={{
          maxWidth: 'md'
        }}
      />
    </>
  )
}

export default BoxDownloadedTable
