import { FC, useMemo } from 'react'

import { Box, Button, ButtonLoading, TabAdvanced, TypographyDivider } from '@opswat/react-ui'
import { useParams } from 'react-router-dom'

import {
  saveEditMode,
  saveSubmitMode,
  selectEditMode,
  selectIsDirtyField,
  selectIsLoadingSubmit
} from 'myopswat-web/src/containers/MyInformationContainer/myInformationContainerSlice'
import {
  myInformationPersonalInfoPageURL,
  myInformationPreferencesPageURL,
  myInformationSecurityPageURL,
  personalInformationPath,
  preferencesPath
} from 'myopswat-web/src/routes'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import General from './General'
import Preferences from './Preferences'
import Security from './Security'

const MyInformationPage: FC<unknown> = () => {
  const { tabPath = '' } = useParams()
  const dispatch = useAppDispatch()
  const editMode = useTypedSelector(selectEditMode)
  const isDirtyField = useTypedSelector(selectIsDirtyField)
  const isLoadingSubmit = useTypedSelector(selectIsLoadingSubmit)

  const tabArray = [
    {
      label: 'Personal Information',
      content: <General />,
      tabPath: myInformationPersonalInfoPageURL
    },
    {
      label: 'Preferences',
      content: <Preferences />,
      tabPath: myInformationPreferencesPageURL
    },
    {
      label: 'Security',
      content: <Security />,
      tabPath: myInformationSecurityPageURL
    }
  ]

  const isShowButtonEdit = useMemo(() => {
    if (tabPath === personalInformationPath || tabPath === preferencesPath) return true
    return false
  }, [tabPath])

  const handleEditMode = () => {
    switch (tabPath) {
      case personalInformationPath:
        dispatch(saveEditMode('info'))
        break
      case preferencesPath:
        dispatch(saveEditMode('preferences'))
        break
    }
  }

  const handleSubmitMode = () => {
    switch (tabPath) {
      case personalInformationPath:
        dispatch(saveSubmitMode('info'))
        break
      case preferencesPath:
        // to allow for clicking submit multiple times
        dispatch(saveSubmitMode(`preferences-${Math.random()}`))
        break
    }
  }

  const handleClearMode = () => {
    dispatch(saveEditMode(''))
    dispatch(saveSubmitMode(''))
  }

  return (
    <>
      <TypographyDivider
        label="My Information"
        actions={
          <>
            {isShowButtonEdit && (
              <>
                {editMode ? (
                  <Box display="flex">
                    <Button
                      variant="text"
                      color="inherit"
                      disabled={isLoadingSubmit}
                      onClick={() => handleClearMode()}
                      sx={{ mr: 2 }}
                    >
                      Cancel
                    </Button>
                    <ButtonLoading
                      propsButton={{
                        variant: 'contained',
                        color: 'primary',
                        onClick: () => {
                          handleSubmitMode()
                        },
                        disabled: isLoadingSubmit || !isDirtyField
                      }}
                      propsLoading={{ color: 'inherit' }}
                      isLoading={isLoadingSubmit}
                    >
                      Save
                    </ButtonLoading>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleEditMode()
                    }}
                  >
                    Edit
                  </Button>
                )}
              </>
            )}
          </>
        }
      />
      <TabAdvanced tabs={tabArray} />
    </>
  )
}

export default MyInformationPage
