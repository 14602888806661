import _get from 'lodash/get'
import { homePageURL } from 'myopswat-web/src/routes'
import { oldUrlsMap } from 'myopswat-web/src/routes/handleRoutes'

import { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

const OldPageContainer = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname
    let targetPath = homePageURL
    if (path in oldUrlsMap) {
      const newPath = _get(oldUrlsMap, path)
      if (newPath) {
        targetPath = newPath
      }
      if (path === '/my-licenses' && searchParams.has('q')) {
        targetPath += `?q=${searchParams.get('q')}`
      }
    }
    navigate(targetPath)
  }, [])

  return <></>
}
export default OldPageContainer
