/** ----------------- PATHNAME -------------------- */
export const evaluatedLicensesPath = 'evaluated-licenses'
export const purchasedLicensesPath = 'purchased-licenses'
export const cloudBasedLicensesPath = 'cloud-based-products-licenses'

/** ----------------- ROUTE -------------------- */
export const licensedProductsPageURL = '/license-management'
export const licensedProductsFlexPageURL = '/license-management/:tabPath'
export const licensedProductsMyOrganizationPageUrl = `${licensedProductsPageURL}/my-organization`
export const licensedProductsCustomerPageURL = `${licensedProductsPageURL}/customer-organizations`

export const iframeLicensedProductsPageURL = '/iframe-pages/licensed-products'
export const iframeLicensedProductsFlexPageURL = `${iframeLicensedProductsPageURL}/:tabPath`
