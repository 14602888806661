import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../../store'

type LicensedProductState = {
  currentOrgs: any[]
  currentMyOrgs: any[]
}

const initialState: LicensedProductState = {
  currentOrgs: [],
  currentMyOrgs: []
}

const licensedProductSlice = createSlice({
  name: 'licensedProduct',
  initialState,
  reducers: {
    setCurrentOrgs: (state, action: PayloadAction<any>) => {
      state.currentOrgs = action.payload || []
    },
    setCurrentMyOrgs: (state, action: PayloadAction<any>) => {
      state.currentMyOrgs = action.payload || []
    }
  }
})

export const { setCurrentOrgs, setCurrentMyOrgs } = licensedProductSlice.actions

export default licensedProductSlice.reducer

export const selectCurrentOrgs = (state: RootState) => state.licensedProduct.currentOrgs
export const selectCurrentMyOrgs = (state: RootState) => state.licensedProduct.currentMyOrgs
