import { FC } from 'react'

import { Grid } from '@opswat/react-ui'

import CloudServicesSection from './sections/CloudServicesSection'
import LicensedProductsSection from './sections/LicensedProductsSection'

interface IProps {
  permissions: any
  setCsvFilter: (data: any) => void
}

const SingleOrgLicenseView: FC<IProps> = ({ permissions, setCsvFilter }) => {
  return (
    <Grid item container xs={12} spacing={2.5}>
      {/* cloud services */}
      <CloudServicesSection />

      {/* products */}
      <LicensedProductsSection setCsvFilter={setCsvFilter} permissions={permissions} />
    </Grid>
  )
}

export default SingleOrgLicenseView
