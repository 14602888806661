import { useMemo } from 'react'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { TabAdvanced, TypographyDivider } from '@opswat/react-ui'

import { useOrganizationQuery } from 'myopswat-web/src/api/organization'
import { useTypedSelector } from 'myopswat-web/src/store'

import {
  myOrganizationCriticalAlertUsersPageURL,
  myOrganizationEventHistoryPageURL,
  myOrganizationGeneralInfoPageURL,
  myOrganizationRolesPageURL,
  myOrganizationSecurityPageURL,
  myOrganizationUsersPageURL
} from 'myopswat-web/src/routes'
import CritalAlertUserList from './CriticalAlertUsers'
import EventHistory from './EventHistory'
import General from './GeneralInformation'
import Roles from './Roles'
import OrganizationSecurity from './Security'
import UserListView from './Users'

interface IProps {
  permissions: any
}

const MyOrganizationPage = ({ permissions }: IProps) => {
  const profile = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const { data, isFetching, refetch } = useOrganizationQuery(_get(profile, 'currentOrganizationId', ''), {
    skip: _isEmpty(_get(profile, 'currentOrganizationId')),
    refetchOnMountOrArgChange: true
  })

  const usingOrganization = useMemo(() => {
    return data || {}
  }, [data])

  const tabArray = useMemo(() => {
    const allTabs = [
      {
        label: 'General Information',
        content: <General organization={usingOrganization} isLoading={isFetching} afterSave={refetch} permissions={permissions} />,
        tabPath: myOrganizationGeneralInfoPageURL,
        requiredPermissions: ['view_my_organizations', 'full_my_organizations'],
      },
      {
        label: 'Users',
        content: (
          <UserListView isFetching={isFetching} organization={usingOrganization} refetchOrganization={refetch} permissions={permissions} />
        ),
        tabPath: myOrganizationUsersPageURL,
        requiredPermissions: ['view_my_organizations_users', 'full_my_organizations_users'],
      },
      {
        label: 'Roles',
        content: <Roles permissions={permissions} />,
        tabPath: myOrganizationRolesPageURL,
        requiredPermissions: ['view_my_organizations_role', 'full_my_organizations_role'],
      },
      {
        label: 'Security',
        content: <OrganizationSecurity isLoading={isFetching} organization={usingOrganization} afterSave={refetch} permissions={permissions} />,
        tabPath: myOrganizationSecurityPageURL,
        requiredPermissions: ['view_my_organizations_security', 'full_my_organizations_security'],
      },
      {
        label: 'Event History',
        content: <EventHistory />,
        tabPath: myOrganizationEventHistoryPageURL,
        requiredPermissions: ['view_my_organizations_eventhistory', 'full_my_organizations_eventhistory'],
      },
      {
        label: 'Critical Alerts Users',
        content: <CritalAlertUserList isFetching={isFetching} organization={usingOrganization} permissions={permissions} />,
        tabPath: myOrganizationCriticalAlertUsersPageURL,
        requiredPermissions: ['view_my_organizations_criticalalert', 'full_my_organizations_criticalalert'],
      },
    ];

    return allTabs.filter(tab =>
      tab.requiredPermissions.some(permission => permissions.includes(permission))
    );
  }, [isFetching, usingOrganization, permissions]);

  return (
    <>
      <TypographyDivider label="My Organization" />
      <TabAdvanced tabs={tabArray} />
    </>
  )
}

export default MyOrganizationPage
