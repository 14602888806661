import { yupResolver } from '@hookform/resolvers/yup'
import { CalendarIcon, CheckDoneIcon, ChevronDownIcon, CloseIcon } from '@opswat/react-icon'
import {
  Box,
  Button,
  ClickAwayListener,
  DialogAdvanced,
  Fade,
  Grid,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
  Typography
} from '@opswat/react-ui'
import _format from 'date-fns/format'
import _isValid from 'date-fns/isValid'
import _parse from 'date-fns/parse'
import _get from 'lodash/get'
import { useLazyCustomerLicenseExpiringKeysReportQuery } from 'myopswat-web/src/api/license'
import StackBarChart from 'myopswat-web/src/components/CustomCharts/StackBarChart'
import yup from 'myopswat-web/src/utils/yup'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import CustomerCloudServicesSection from '../../sections/CloudServicesSection'
import CustomerLicenseDetailSection from '../../sections/LicensedProductsSection'

interface IProps {
  customerOrgIds: string[]
}

interface IExpiringFilterForm {
  expiringDuration?: string
  expiringFrom?: string
  expiringTo?: string
}

const DEFAULT_EXPIRING_FILTER = {
  expiringDuration: '30',
  expiringFrom: undefined,
  expiringTo: undefined
}

const isValidDate = (val: string) => {
  return _isValid(_parse(val, 'yyyy-MM-dd', new Date()))
}

const isNotPastDate = (val: string) => {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  return Date.parse(val) >= currentDate.getTime()
}

const schema = yup.object().shape({
  expiringFrom: yup
    .string()
    .required('Expiring From is a required field')
    .test('is-date-string', 'Please enter valid date', isValidDate)
    .test('is-not-past-date', 'Please enter today or future date', isNotPastDate),
  expiringTo: yup
    .string()
    .required('Expiring To is a required field')
    .test('is-date-string', 'Please enter valid date', isValidDate)
    .test('is-not-past-date', 'Please enter today or future date', isNotPastDate)
    .test('is-valid-expiring-from', 'Expiring To must be after Expiring From', (val, context) => {
      const { expiringFrom } = context.parent
      if (!expiringFrom) return true // handle when null expiringFrom, we don't check for expiringTo
      return Date.parse(expiringFrom) < Date.parse(val)
    })
})

const TIME_ONE_DAY = 1
const TIME_ONE_DAY_MILI = TIME_ONE_DAY * 86400 * 1000
const TIME_ONE_MONTH = TIME_ONE_DAY * 30

const TIME_FILTERS = [
  { label: '24 hours', value: TIME_ONE_DAY },
  { label: '7 days', value: TIME_ONE_DAY * 7 },
  { label: '30 days', value: TIME_ONE_MONTH },
  { label: '3 months', value: TIME_ONE_MONTH * 3 },
  { label: '6 months', value: TIME_ONE_MONTH * 6 },
  { label: 'Custom', value: 'custom' }
]

const ExpiringLicenseChart: FC<IProps> = ({ customerOrgIds }) => {
  const [expiringData, setExpiringData] = useState<any>([])
  const [open, setOpen] = useState(false)
  const durationRef = useRef()
  const oldValueRef = useRef<IExpiringFilterForm>()
  const [anchorEl, setAnchorEl] = useState<any>()
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IExpiringFilterForm>({
    mode: 'onSubmit',
    defaultValues: DEFAULT_EXPIRING_FILTER,
    resolver: yupResolver(schema)
  })
  const [getExpiringKeysReport, { data: reportData, isFetching }] = useLazyCustomerLicenseExpiringKeysReportQuery()

  useEffect(() => {
    onSubmit(DEFAULT_EXPIRING_FILTER)
  }, [])

  useEffect(() => {
    if (!isFetching && reportData) {
      const expiringData = Object.entries(_get(reportData, 'expiring_licenses', [])).map(([_, value]: any[]) => {
        return { name: value.name, count: value.count }
      })

      setExpiringData(expiringData)
    }
  }, [reportData, isFetching])

  const onSubmit = async (data: IExpiringFilterForm) => {
    try {
      const filters = {
        expiringDuration: data.expiringDuration !== 'custom' ? Number(data.expiringDuration) : undefined,
        expiringFrom: data.expiringDuration === 'custom' ? data.expiringFrom : undefined,
        expiringTo: data.expiringDuration === 'custom' ? data.expiringTo : undefined
      }
      setExpiringData([])
      await getExpiringKeysReport({
        customerOrgIds,
        filters
      })
      oldValueRef.current = data

      handleCloseDropdown()
    } catch (error: any) {
      onError(error)
    }
  }

  const onError = (error: any) => {
    console.error(error)
  }

  const onSelectDuration = (evt: any) => {
    const selectedValue = evt.currentTarget.dataset.value
    setValue('expiringDuration', selectedValue)
    if (selectedValue === 'custom') {
      setAnchorEl(durationRef.current)
    } else {
      onSubmit(getValues())
    }
  }

  const handleCloseDropdown = () => {
    if (errors) reset(oldValueRef.current)
    setAnchorEl(null)
  }

  const renderSelectTimeRange = () => {
    const currentDate = new Date()
    const formattedCurrentDate = _format(currentDate, 'yyyy-MM-dd')
    const formatted1kPlusDate = _format(currentDate.getTime() + TIME_ONE_DAY_MILI * 1000, 'yyyy-MM-dd')
    return (
      <Box component="span" sx={{ position: 'absolute', right: 0 }}>
        <Controller
          control={control}
          name="expiringDuration"
          render={({ field }: any) => {
            return (
              <Select
                ref={durationRef}
                value={field.value}
                size="small"
                variant="standard"
                disableUnderline
                startAdornment={
                  <Box mr={1}>
                    <CalendarIcon color="#1D6BFC" size={16} />
                  </Box>
                }
                sx={{
                  '& .MuiInput-input': { padding: 0, backgroundColor: '#fff', color: '#1D6BFC' },
                  '& .MuiSelect-icon': { top: 0 }
                }}
                IconComponent={props => (
                  <Box {...props}>
                    <ChevronDownIcon color="#1D6BFC" />
                  </Box>
                )}
                renderValue={value => <>{TIME_FILTERS.find(v => v.value.toString() === value.toString())?.label}</>}
              >
                {TIME_FILTERS.map(v => {
                  return (
                    <MenuItem value={v.value} onClick={onSelectDuration}>
                      <Box width={100} display="flex" justifyContent="space-between">
                        {v.label}
                        <Box sx={{ display: v.value.toString() === field.value.toString() ? 'flex' : 'none' }}>
                          <CheckDoneIcon color="#1D6BFC" />
                        </Box>
                      </Box>
                    </MenuItem>
                  )
                })}
              </Select>
            )
          }}
        />

        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom" transition>
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleCloseDropdown}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper variant="outlined" sx={{ padding: 2 }}>
                  <Typography variant="h2" marginBottom={1}>
                    Custom Date
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" marginBottom={1}>
                        From
                      </Typography>
                      <Controller
                        control={control}
                        name="expiringFrom"
                        render={({ field: { value, onChange } }) => {
                          return (
                            <TextField
                              value={value}
                              disabled={isFetching}
                              fullWidth
                              size="small"
                              type="date"
                              sx={{ minHeight: '0px' }}
                              onChange={evt => {
                                onChange(evt.target.value)
                              }}
                              inputProps={{
                                min: formattedCurrentDate,
                                max: formatted1kPlusDate
                              }}
                              error={!!_get(errors, 'expiringFrom', '')}
                              helperText={_get(errors, 'expiringFrom.message', '')}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" marginBottom={1}>
                        To
                      </Typography>
                      <Controller
                        control={control}
                        name="expiringTo"
                        render={({ field: { value, onChange } }) => {
                          return (
                            <TextField
                              value={value}
                              disabled={isFetching}
                              fullWidth
                              size="small"
                              type="date"
                              sx={{ minHeight: '0px' }}
                              onChange={evt => onChange(evt.target.value)}
                              inputProps={{
                                min: formattedCurrentDate,
                                max: formatted1kPlusDate
                              }}
                              error={!!_get(errors, 'expiringTo', '')}
                              helperText={_get(errors, 'expiringTo.message', '')}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs="auto">
                          <Button disabled={isFetching} variant="text" color="inherit" onClick={handleCloseDropdown}>
                            Cancel
                          </Button>
                        </Grid>

                        <Grid item xs="auto">
                          <Button
                            disabled={isFetching}
                            type="submit"
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit(onSubmit, onError)}
                          >
                            Apply
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </Box>
    )
  }

  const renderContainerBody = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography fontSize="24px" lineHeight="28px" color="#1B273C" fontWeight={400}>
          {totalCount} Licenses
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', height: 30 }}>
          <Typography variant="caption" color="#1B273C">
            Display all licenses expiring within the selected time range.
          </Typography>
        </Box>
      </Box>
    )
  }

  const renderTooltip = (name: string, count: number) => {
    return (
      <Box
        sx={{
          padding: '4px 8px',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px'
        }}
      >
        <Typography fontSize="12px" fontWeight={500} color="#1B273C" lineHeight="16px" textAlign="center">
          {name}
        </Typography>
        <Typography fontSize="12px" fontWeight={400} color="#1B273C" lineHeight="16px" textAlign="center">
          Total no. of expiring
          <Typography
            component="span"
            fontSize="12px"
            fontWeight="400"
            lineHeight="16px"
            textAlign="center"
            color="#ED6706"
            marginLeft="8px"
          >
            {count}
          </Typography>
        </Typography>
      </Box>
    )
  }

  const totalCount = useMemo(() => {
    if (!expiringData) return 0
    return expiringData.reduce((prev: number, current: any) => prev + current.count, 0)
  }, [expiringData])

  return (
    <Box sx={{ position: 'relative' }}>
      {renderSelectTimeRange()}
      <StackBarChart
        data={expiringData}
        containerTitle={'EXPIRING LICENSES'}
        containerSx={{ maxWidth: 500, display: 'flex', flexDirection: 'column', gap: 6 }}
        maxCategory={4}
        chartTitle="By organizations"
        onRenderContainerBody={renderContainerBody}
        onRenderTooltip={renderTooltip}
        onClickActionBtn={() => setOpen(true)}
        hint="Hover over each color bar to see Organization & Sub-Organization that has expiring licenses."
      />
      <DialogAdvanced
        open={open}
        onClose={() => setOpen(false)}
        iconClose={<CloseIcon />}
        dialogProps={{
          maxWidth: 'xxl'
        }}
        title={`${totalCount} Expiring Licenses`}
        content={
          <>
            <CustomerCloudServicesSection
              forViewAllModal
              expiringDuration={getValues('expiringDuration')}
              expiringFrom={getValues('expiringFrom')}
              expiringTo={getValues('expiringTo')}
            />
            <Box sx={{ height: 8 }} />
            <CustomerLicenseDetailSection
              forViewAllModal
              expiringDuration={getValues('expiringDuration')}
              expiringFrom={getValues('expiringFrom')}
              expiringTo={getValues('expiringTo')}
              disableFirstLoad={true}
            />
          </>
        }
      />
    </Box>
  )
}

export default ExpiringLicenseChart
