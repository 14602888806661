import React from 'react'
import { Control, UseFormHandleSubmit } from 'react-hook-form'

import { ISubmitSurveyInput } from 'myopswat-web/src/api/survey/types'
import { dialogURL } from 'myopswat-web/src/routes'

export type DialogNPSSurveyContextProps = {
  control: Control<any>
  handleSubmit: UseFormHandleSubmit<any>
  handleSubmitNPSSurvey: (data: any) => void
  handleRejectNPSSurvey: (action: 'reject' | 'no_answer') => void
  isLoadingRejectSurvey: boolean
  isLoadingSubmitSurvey: boolean

  surveyData: any
  openSurveyDialog: boolean
  openSuccessDialog: boolean
  openNotificationDialog: boolean
  handleOpenSurveyDialog: () => void
  handleCloseSurveyDialog: () => void
  handleCloseSuccessDialog: (goBack?: boolean) => void
  handleOpenReferralDialog: () => any
  handleReset: () => void
  handleGetNPSOption: (key: string) => string

  setValue: (key: keyof ISubmitSurveyInput, value: any) => void
  isCustomer: boolean
}

export const DialogNPSSurveyContext = React.createContext<DialogNPSSurveyContextProps>({
  control: {} as Control<any>,
  handleSubmit: {} as UseFormHandleSubmit<any>,
  handleSubmitNPSSurvey: () => {
    return
  },
  handleRejectNPSSurvey: () => {
    return
  },
  isLoadingRejectSurvey: false,
  isLoadingSubmitSurvey: false,

  surveyData: {},
  openSurveyDialog: false,
  openSuccessDialog: false,
  openNotificationDialog: false,
  handleOpenSurveyDialog: () => {
    return
  },
  handleCloseSurveyDialog: () => {
    return
  },
  handleCloseSuccessDialog: () => {
    return
  },
  handleReset: () => {
    return
  },
  handleGetNPSOption: () => {
    return ''
  },
  setValue: () => {
    return
  },
  handleOpenReferralDialog: () => {
    return
  },
  isCustomer: false
})

export const NPS_MAX_LENGTH = 500

export const NPS_OPTIONS = {
  ALL_LICENSED_PRODUCTS: 'ALL_MY_LICENSED_PRODUCTS',
  ALL_ORGANIZATIONS: 'ALL',
  MYSELF: 'YOURSELF'
}

export const EXCLUDE_NPS_PATHS = [dialogURL]
