import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import _debounce from 'lodash/debounce'

import { Button, Grid, TextFieldSearch } from '@opswat/react-ui'
import { IPortalRolesFiltersInput } from 'myopswat-web/src/api/role/types'
import { useTranslation } from 'react-i18next'
import useHasPermissions from '../../../hooks/useHasPermissions'

interface IProps {
  children?: React.ReactNode
  setRolesFilter: Dispatch<SetStateAction<IPortalRolesFiltersInput>>
  onClickNew: () => void
  permissions: string[]
}

const BoxRolesFilter: FC<IProps> = ({ setRolesFilter, onClickNew, permissions }) => {
  const { t: translate } = useTranslation()
  const [keyword, setKeyword] = useState<string>('')
  const hasFullOrgRolePerm = useHasPermissions({targetPerms: ['full_my_organizations_role'], userPerms: permissions})

  useEffect(() => {
    if (keyword || keyword === '') {
      const handleSearch = _debounce(() => {
        setRolesFilter((prev: IPortalRolesFiltersInput) => {
          return {
            ...prev,
            q: keyword
          }
        })
      }, 500)

      handleSearch()

      return () => {
        handleSearch.cancel()
      }
    }
  }, [keyword])

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={4}>
        <TextFieldSearch
          value={keyword}
          placeholder={translate('filterRoleNameDescription') || ''}
          onChange={(e: any) => {
            if (e?.target) {
              setKeyword(e.target.value)
            }
          }}
          onClearText={() => setKeyword('')}
        />
      </Grid>

      <Grid item xs="auto">
        <Button variant="contained" onClick={onClickNew} color="primary" disabled={!hasFullOrgRolePerm}>
          Add Role
        </Button>
      </Grid>
    </Grid>
  )
}

export default BoxRolesFilter
