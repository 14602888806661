import { useCallback, useEffect, useRef, useState } from 'react'

import { Composer, Container, getClient, MessageList, WebchatClient, WebchatProvider } from '@botpress/webchat'
import { ChevronDownIcon } from '@opswat/react-icon'
import { Box, Button, FormHelperText, InputAdornment, TextField } from '@opswat/react-ui'

import {
  selectCurrentChatbotTopic,
  setCurrentChatbotTopic
} from 'myopswat-web/src/containers/SupportServicesContainer/supportServicesContainerSlice'
import { useGetChatbotUser } from 'myopswat-web/src/hooks/useGetChatbotUser'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

import CustomChatbotHeader from './ChatbotHeader'
import {
  CHAT_BOT_CUSTOM_EVENT,
  SUPPORT_CHATBOT_CONFIG,
  SUPPORT_CHATBOT_HELPER_TEXT,
  SUPPORT_CHATBOT_PLACEHOLDER
} from './constants'

import './chatbot.css'

export const ChatbotSearch: React.FC<unknown> = () => {
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const currentChatbotTopic = useTypedSelector(selectCurrentChatbotTopic)

  const dispatch = useAppDispatch()
  const helperTextRef = useRef<any>()
  const userData = useGetChatbotUser(profileData)

  const [isChatbotOpen, setIsChatbotOpen] = useState<boolean>(false)
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [lastMessage, setLastMessage] = useState<string>('')
  const [currentTopic, setCurrentTopic] = useState<string>('')
  const [client, setClient] = useState<WebchatClient | null>(null)

  const handleToggleChatbot = async () => {
    setIsChatbotOpen(prev => !prev)
  }

  const handleSendMessageToChatbot = async (message: string) => {
    if (hasSubmitted) {
      if (inputValue !== currentTopic) {
        await client?.sendMessage({
          type: 'text',
          text: message
        })
      }
    } else {
      await client
        ?.sendMessage({
          type: 'text',
          text: message
        })
        .then(() => {
          setHasSubmitted(true)
        })
    }
  }

  const handleSubmitChatbot = async () => {
    if (!inputValue) {
      return
    }
    if (inputValue === lastMessage) {
      handleToggleChatbot()
      return
    }
    handleSendMessageToChatbot(inputValue).then(() => {
      handleToggleChatbot()
      setLastMessage(inputValue)
    })
  }

  const handleReplaceVoiceIcon = () => {
    const svgElement = document.querySelector('.bpComposerVoiceButtonIcon')

    if (svgElement) {
      const customSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
      customSvg.setAttribute('width', '16')
      customSvg.setAttribute('height', '18')
      customSvg.setAttribute('viewBox', '0 0 16 18')
      customSvg.setAttribute('fill', 'none')
      customSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
      customSvg.innerHTML = `
          <g id="mic-2 1">
            <g id="Group">
              <path id="Vector" d="M12 10.332V10.9987C12 13.208 10.2093 14.9987 8 14.9987C5.79067 14.9987 4 13.208 4 10.9987V10.332" stroke="#1B273C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
              <path id="Vector_2" d="M2 10.332H14" stroke="#1B273C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
              <path id="Vector_3" d="M8 16.3333V15" stroke="#1B273C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
              <path id="Vector_4" d="M4 7.66602V5.66602C4 3.45668 5.79067 1.66602 8 1.66602C10.2093 1.66602 12 3.45668 12 5.66602V7.66602" stroke="#1B273C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
            </g>
          </g>
        `
      svgElement.replaceWith(customSvg)
    }
  }

  const handleOffsetChatbotPosition = () => {
    if (helperTextRef.current) {
      // check the height of the helper text and set the position of the chatbot modal accordingly
      const helperTextHeight = helperTextRef.current.offsetHeight ?? 0
      const chatbotContainer = document.querySelector('.bpContainer')
      chatbotContainer?.setAttribute('style', `top: -${helperTextHeight > 16 ? helperTextHeight - 16 : 0}px`)
    }
  }

  const handleRestartConversation = useCallback(() => {
    const interval = setInterval(() => {
      const chatbots = document.getElementsByClassName('bpOpen')
      if (chatbots.length > 0) {
        const elements = document.getElementsByName('webchat')
        if (elements.length > 0) {
          const iframeChat = elements[0] as HTMLIFrameElement
          const documentChat = iframeChat.contentWindow?.document
          const chatbotAction = documentChat?.querySelector('.bpHeaderContentActionsIcons')
          if (chatbotAction) {
            chatbotAction.dispatchEvent(
              new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
              })
            )
            setTimeout(() => {
              const restartConversationButton = documentChat?.querySelector('.bpModalDialogNewConversationButton')
              if (restartConversationButton) {
                restartConversationButton.dispatchEvent(
                  new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                  })
                )
              }
            }, 500)
            clearInterval(interval)
          }
        }
      }
    }, 500)
  }, [])

  const handleOpenLiveAgentChat = useCallback((userData: any) => {
    window.botpress
      .updateUser({
        data: userData
      })
      .then(() => {
        handleToggleChatbot()
        window.botpress.open()
        handleRestartConversation()
      })
  }, [])

  const handleConnectChatbot = () => {
    const newClient = getClient({ clientId: process.env.REACT_APP_SUPPORT_BOTPRESS_ID ?? '' })

    newClient.on('*', e => {
      // for debugging purposes, will be removed later
      if (['DEV', 'STAGING'].includes(process.env.REACT_APP_ENV ?? '')) {
        console.log('chatbot event:', e)
      }

      if (e.type === 'customEvent' && e.payload) {
        const payload = e.payload

        switch (payload.eventType) {
          case CHAT_BOT_CUSTOM_EVENT.TOPIC_CHANGED:
            setCurrentTopic(payload.topic)
            break
          case CHAT_BOT_CUSTOM_EVENT.LIVE_CHAT_REQUESTED:
            handleOpenLiveAgentChat({ ...userData, ...payload })
            break
        }
      }
    })

    setClient(newClient)
  }

  useEffect(() => {
    handleConnectChatbot()

    const observer = new MutationObserver(handleReplaceVoiceIcon)
    observer.observe(document.body, { childList: true, subtree: true })

    window.addEventListener('resize', handleOffsetChatbotPosition)
    handleOffsetChatbotPosition()

    return () => {
      observer.disconnect()
      window.removeEventListener('resize', handleOffsetChatbotPosition)
    }
  }, [])

  useEffect(() => {
    setInputValue(currentTopic)
    currentTopic && dispatch(setCurrentChatbotTopic(currentTopic))
  }, [currentTopic])

  useEffect(() => {
    setCurrentTopic(currentChatbotTopic)
    setLastMessage(currentChatbotTopic)
  }, [currentChatbotTopic])

  return (
    <Box className="bpChatbotContainer">
      <Box className="bpSearchContainer">
        <TextField
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              handleSubmitChatbot()
            }
          }}
          placeholder={SUPPORT_CHATBOT_PLACEHOLDER}
          className="bpChatbotSearchInput"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={'https://myopswat-staging.s3.us-east-2.amazonaws.com/images/chatbot_avatar.gif'}
                  width={40}
                  height={40}
                  style={{
                    borderRadius: '50%'
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <Button
                  className="bpChatbotHeaderCloseButton"
                  onClick={() => {
                    if (isChatbotOpen) {
                      handleToggleChatbot()
                    } else {
                      handleSubmitChatbot()
                    }
                  }}
                >
                  <ChevronDownIcon color="#FFFFFF" />
                </Button>
              </InputAdornment>
            )
          }}
          inputProps={{
            autocomplete: 'new-password',
            form: {
              autocomplete: 'off'
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root.Mui-disabled': {
              backgroundColor: '#FFFFFF'
            }
          }}
        />
        <FormHelperText
          ref={helperTextRef}
          sx={{
            marginTop: '7px',
            textAlign: 'center',
            color: '#616875'
          }}
        >
          {SUPPORT_CHATBOT_HELPER_TEXT.ACTION}{' '}
          <span
            style={{
              color: '#1B273C'
            }}
          >
            {SUPPORT_CHATBOT_HELPER_TEXT.KEYWORD_1}
          </span>{' '}
          or{' '}
          <span
            style={{
              color: '#1B273C'
            }}
          >
            {SUPPORT_CHATBOT_HELPER_TEXT.KEYWORD_2}
          </span>
        </FormHelperText>
        {client && (
          <WebchatProvider client={client} configuration={SUPPORT_CHATBOT_CONFIG} userData={userData}>
            <div
              className={`chatbot${isChatbotOpen ? 'Expanded' : 'Collapsed'}`}
              style={{
                visibility: isChatbotOpen ? 'visible' : 'hidden'
              }}
            >
              <Container>
                <Button className="bpChatbotHeaderCloseButton" onClick={handleToggleChatbot}>
                  <ChevronDownIcon color="#FFFFFF" />
                </Button>
                {isChatbotOpen && <CustomChatbotHeader handleCloseChatbot={handleToggleChatbot} />}
                <MessageList />
                <Composer />
              </Container>
            </div>
          </WebchatProvider>
        )}
      </Box>
    </Box>
  )
}
