import _get from 'lodash/get'

import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { selectCurrentMyOrgs, setCurrentMyOrgs } from 'myopswat-web/src/containers/LicensedProductsContainer/licensedProductContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import SubOrgLicenseView from '.'
import { SubOrgViewContext } from './interface'

interface IProps {
  permissions: any
  organizationTreeData: any
  activateSuccessful: boolean
  setCsvFilter: (data: any) => void
}

const SubOrgViewProvider: React.FC<IProps> = ({
  permissions,
  organizationTreeData,
  setCsvFilter,
  activateSuccessful
}) => {
  const dispatch = useAppDispatch()
  const currentOrgs = useTypedSelector(selectCurrentMyOrgs)
  
  const { control, watch } = useForm<any>({
    defaultValues: {
      organizationIds: currentOrgs
    }
  })

  const organizationList = useMemo(() => {
    const parent = _get(organizationTreeData, 'tree', {})
    const children = _get(parent, 'children', [])
      .map((item: any) => ({ ...item, level: 1 }))
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
    return [{ ...parent, level: 0 }, ...children]
  }, [organizationTreeData])

  const contextValue = useMemo(
    () => ({
      control,
      permissions,
      organizationList,
      activateSuccessful,
      setCsvFilter
    }),
    [control, permissions, organizationList, activateSuccessful, setCsvFilter]
  )

  useEffect(() => {
    dispatch(setCurrentMyOrgs(watch('organizationIds')))
  }, [watch('organizationIds')])

  return (
    <SubOrgViewContext.Provider value={contextValue}>
      <SubOrgLicenseView />
    </SubOrgViewContext.Provider>
  )
}
export default SubOrgViewProvider
