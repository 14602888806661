import { FC } from 'react'

import _get from 'lodash/get'

import { Control, Controller, useFormState } from 'react-hook-form'

import { Box, Grid, GridProps, Skeleton, Typography } from '@opswat/react-ui'

interface IProps {
  control: Control<any>
  title: string
  fieldName: string
  Component: FC<any>
  rules?: any
  isFetching?: boolean
  isLoading?: boolean
  gridProps?: GridProps
  hideRequiredMark?: boolean
  labelProps?: any
  titleIcon?: React.ReactNode
  titleInfo?: React.ReactNode
  placeholder?: string
  maxCharacters?: number
}

const SubmitCaseField: FC<IProps> = ({
  control,
  title,
  fieldName,
  Component,
  gridProps,
  rules,
  isFetching,
  isLoading,
  hideRequiredMark = false,
  labelProps,
  titleIcon,
  titleInfo,
  placeholder,
  maxCharacters
}) => {
  const { errors } = useFormState({ control })

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5
      }}
      {...gridProps}
    >
      {title && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography component="label" {...labelProps}>
            {title}
            {rules?.required && !hideRequiredMark && (
              <Typography component="span" color="error">
                {' *'}
              </Typography>
            )}
            {titleIcon}
          </Typography>
          {titleInfo}
        </Box>
      )}
      {isFetching || isLoading ? (
        <Skeleton variant="rectangular" height={37} width={'100%'} />
      ) : (
        <Controller
          name={fieldName}
          control={control}
          render={({ field }) => (
            <Component
              {...field}
              error={!!_get(errors, fieldName, '')}
              helperText={_get(errors, `${fieldName}.message`, '')}
              placeholder={placeholder}
              onChange={
                maxCharacters
                  ? (e: any) => {
                      const newValue = e.target.value
                      field.onChange(newValue.length > maxCharacters ? newValue.slice(0, maxCharacters) : newValue)
                    }
                  : field.onChange
              }
            />
          )}
          rules={{
            ...rules
          }}
        />
      )}
    </Grid>
  )
}

export default SubmitCaseField
