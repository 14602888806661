interface IProps {
  userPerms: string[],
  targetPerms: string[]
}

const useHasPermissions = ({ targetPerms, userPerms }: IProps) => {
  if (!userPerms) {
    return false
  }
  if (targetPerms.some(item => userPerms.includes(item))) {
    return true
  }
  return false
}

export { useHasPermissions as default }
