import _range from 'lodash/range'

import { useContext } from 'react'

import { Box, Button, Grid, Skeleton, Typography } from '@opswat/react-ui'

import SubmitCaseStep from '../components/SubmitCaseStep'
import SubmitCaseSuggestion from '../components/SubmitCaseSuggestion'

import { SupportSubmitCaseContext } from '../interface'
import SubmitCaseLoadingView from './loadingView'

const SubmitCaseSuggestionView = () => {
  const { suggestionList, isLoadingSuggestionList, handleSubmitCase, handleCancelSubmitCase, handleResolveSubmitCase } =
    useContext(SupportSubmitCaseContext)

  if (isLoadingSuggestionList) {
    return <SubmitCaseLoadingView />
  }

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <SubmitCaseStep
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img
              src={'https://myopswat-staging.s3.us-east-2.amazonaws.com/images/chatbot_avatar.gif'}
              width={20}
              height={20}
              alt="chatbot-avatar"
              style={{
                borderRadius: '50%'
              }}
            />
            <Typography variant="body1" color="#1B273C">
              Here are some helpful suggestions and articles that might help you resolve this issue:
            </Typography>
          </Box>
        }
      >
        {isLoadingSuggestionList &&
          _range(5).map((item: any) => (
            <Skeleton key={item} animation="wave" variant="rectangular" width="100%" height="64px" />
          ))}
        {!isLoadingSuggestionList &&
          suggestionList.results?.map((item: any) => (
            <SubmitCaseSuggestion key={item.link} title={item.title} link={item.url} />
          ))}
      </SubmitCaseStep>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Button disableElevation variant="outlined" color="inherit" onClick={handleCancelSubmitCase}>
          Cancel
        </Button>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            sx={{
              textTransform: 'none'
            }}
            onClick={handleResolveSubmitCase}
          >
            I resolved the issue
          </Button>
          <Button
            disableElevation
            variant="outlined"
            color="inherit"
            sx={{
              textTransform: 'none'
            }}
            onClick={handleSubmitCase}
          >
            Confirm Submission
          </Button>
        </Box>
      </Box>
    </Grid>
  )
}

export default SubmitCaseSuggestionView
