import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@opswat/react-ui'

import { supportServicesSubmitCasePageURL } from 'myopswat-web/src/routes'

import './chatbot.css'

const CustomChatbotHeader: React.FC<any> = () => {
  const navigate = useNavigate()

  const [showMessage, setShowMessage] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setShowMessage(true)
    }, 5000)
  }, [])

  return (
    <Box className={`bpChatbotHeader bpChatbotHeader${showMessage ? 'Expanded' : 'Collapsed'}`}>
      <Typography
        sx={{
          opacity: showMessage ? 1 : 0
        }}
      >
        Didn't find what you need?{' '}
        <Typography
          component="span"
          color="#1D6BFC"
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
          onClick={() => navigate(supportServicesSubmitCasePageURL, { state: { fromChatbot: true } })}
        >
          Click here to submit a case
        </Typography>
      </Typography>
    </Box>
  )
}

export default CustomChatbotHeader
