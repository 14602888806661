import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'

import { ISupportCaseCreateV2Input } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    supportCaseProducts: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            organizationSupportCaseProducts(organizationId: "${id}") {
              id
              name
              productIcon
              supportCasePlatforms
            }
          }
        `
      })
    }),
    supportCasePreferredPartners: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            preferredPartnerOrganizations {
              id
              name
              ssoId
              isPreferredPartner
            }
          }
        `
      })
    }),
    supportCaseProductLicenseKeys: builder.query<any, any>({
      query: ({ orgId, productId }) => ({
        document: gql`
          query {
            submitCaseSuggestions(
              orgId: "${orgId}"
              productId: "${productId}"
            ) {
              licenseKeys {
                id
                activationKey
                organizationId
                platform
                rmId
                status
                product {
                  id 
                  name
                }
              }
              productReleases {
                id
                semVer
                releaseDate
                productId
                productName
              }
            }
          }
        `
      })
    }),
    submitSupportCase: builder.mutation<any, ISupportCaseCreateV2Input>({
      query: input => ({
        document: gql`
          mutation supportCaseCreateV2($input: SupportCaseCreateV2Input!) {
            supportCaseCreateV2(input: $input) {
              message
              success
              errors
              caseId
              caseNumber
            }
          }
        `,
        variables: {
          input
        }
      })
    }),
    supportCaseSuggestions: builder.query<any, any>({
      query: ({ productId, keyword }) => ({
        document: gql`
          query {
            knowledgeBaseSearch(keyword: "${keyword}", productId: "${productId}") {
              results {
                title
                section
                url
              }
            }
          }
        `
      })
    })
  })
})

export const {
  useLazySupportCaseProductsQuery,
  useSupportCasePreferredPartnersQuery,
  useLazySupportCaseProductLicenseKeysQuery,
  useLazySupportCaseSuggestionsQuery,
  useSubmitSupportCaseMutation
} = api
