import { Collapse } from '@mui/material'
import { useCallback, useState } from 'react'
import { RecursiveTreeProps, TreeNode, TreeNodeRowProps } from './types'
import { withTree } from './withTree'

const TreeNodeRow = <T,>({
  node,
  mode,
  value,
  level,
  collapsed,
  Row,
  onChange,
  onToggleCollapse
}: TreeNodeRowProps<T>) => {
  return (
    <>
      <Row
        node={node}
        mode={mode}
        collapsed={collapsed}
        level={level}
        onChange={onChange}
        onToggle={() => onToggleCollapse(node.id)}
      />
      {node.children && node.children.length > 0 && (
        <Collapse in={!collapsed}>
          <RecursiveTree
            nodes={node.children}
            value={value}
            mode={mode}
            level={level + 1}
            Row={Row}
            onChange={onChange}
          />
        </Collapse>
      )}
    </>
  )
}

export const RecursiveTree = <T,>({ nodes, value, onChange, Row, mode = 'view', level = 0 }: RecursiveTreeProps<T>) => {
  const getCollapsedState = useCallback(
    (nodes: TreeNode[], targetId: string): Record<string, boolean> => {
      const findPathToNode = (nodes: TreeNode[], targetId: string, path: TreeNode[] = []): TreeNode[] | null => {
        for (const node of nodes) {
          if (node.id === targetId) {
            return [...path, node]
          }
          if (node.children) {
            const result = findPathToNode(node.children, targetId, [...path, node])
            if (result) {
              return result
            }
          }
        }
        return null
      }

      const path = findPathToNode(nodes, targetId)
      if (!path) return {}

      const initialState: Record<string, boolean> = {}
      path.forEach(node => {
        initialState[node.id] = false
      })

      const setCollapsedForRest = (nodes: TreeNode[], initialState: Record<string, boolean>) => {
        nodes.forEach(node => {
          if (initialState[node.id] === undefined) {
            initialState[node.id] = true
          }
          if (node.children) {
            setCollapsedForRest(node.children, initialState)
          }
        })
      }

      setCollapsedForRest(nodes, initialState)

      return initialState
    },
    [nodes]
  )

  const [collapsedState, setCollapsedState] = useState<Record<string, boolean>>(() =>
    getCollapsedState(nodes, value as string)
  )

  const handleToggleCollapse = useCallback((nodeId: string) => {
    setCollapsedState(prevState => ({
      ...prevState,
      [nodeId]: prevState[nodeId] !== undefined ? !prevState[nodeId] : false
    }))
  }, [])

  return (
    <>
      {nodes.map(node => (
        <TreeNodeRow
          key={node.id}
          node={node}
          mode={mode}
          value={value}
          Row={Row}
          level={level}
          collapsed={collapsedState[node.id] ?? true}
          onChange={onChange}
          onToggleCollapse={handleToggleCollapse}
        />
      ))}
    </>
  )
}

export const RecursiveTreeWithData = withTree(RecursiveTree)
