import { useState } from 'react'

import { CloseIcon } from '@opswat/react-icon'
import { Box, DialogAdvanced, IconButton, Typography } from '@opswat/react-ui'

import { OrgAccessTable } from './OrgAccessTable'

type IProps = {
  orgId: string
  openDialog: boolean
  handleCloseDialog: () => void
  orgCaseAccessInfo: any[]
  refreshData: () => Promise<void>
  hasFullSupportServicePerm?: boolean
}

const DialogUpdateCaseAccess = ({
  orgCaseAccessInfo,
  openDialog,
  handleCloseDialog,
  orgId,
  refreshData,
  hasFullSupportServicePerm
}: IProps) => {
  const [isUpdating, setIsUpdating] = useState(false)

  return (
    <DialogAdvanced
      open={openDialog}
      title={
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h3">Configure Organization Case Access</Typography>
          <IconButton onClick={handleCloseDialog} sx={{ padding: 0 }} disabled={isUpdating}>
            <CloseIcon />
          </IconButton>
        </Box>
      }
      content={
        <OrgAccessTable
          data={orgCaseAccessInfo}
          orgId={orgId}
          setIsUpdating={setIsUpdating}
          refreshData={refreshData}
          hasFullSupportServicePerm={hasFullSupportServicePerm}
        />
      }
      dialogTitleProps={{
        sx: {
          borderBottom: '1px solid #D3D9E6',
          marginInline: '24px',
          paddingY: '10px ',
          paddingX: '0px'
        }
      }}
      dialogContentProps={{
        sx: {
          padding: '0px 24px'
        }
      }}
      dialogProps={{
        sx: {
          '& .MuiPaper-root': {
            maxWidth: '800px'
          }
        }
      }}
    />
  )
}

export default DialogUpdateCaseAccess
