import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    dismissSystemNotification: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          mutation {
            dismissSystemNotification(id: "${id}") {
              success
              errors
            }
          }
        `
      })
    })
  })
})

export const { useDismissSystemNotificationMutation } = api
