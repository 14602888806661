import React from 'react'

import _get from 'lodash/get'


import { Box, Typography, TypographyDivider } from '@opswat/react-ui'
import { useTypedSelector } from '../../store'
import { PAGE_PORTAL_PERMISSIONS } from '../../routes/handleRoutes'

export function withPermission(Component: any, path: string, locationPath:any) {
  function PageWithPermission() {
    const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

    let userPermissions: string[] = _get(profileData, 'portalPermissions', [])
    if (userPermissions == null) {
      userPermissions = []
    }
    const pagePermissions: string[] = PAGE_PORTAL_PERMISSIONS[`${locationPath}`] ?? []
    const hasPermission = () => {
      if (pagePermissions.length == 0 || pagePermissions.some(item => userPermissions.includes(item))) {
        return true
      }
      return false
    }
    const showNoPermissionPage = () => {
      return (
        <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'left' }}>
          <TypographyDivider label="Permissions Denied"/>
          <Typography variant="body2" pt={5}>You do not have the permission to access this page. Your permissions might be modified by an administrator.</Typography>
          <Typography variant="body2" pt={2}>Please contact your administrators for help.</Typography>
        </Box>
      )
    }

    return hasPermission() ? React.useMemo(() => Component, []) : showNoPermissionPage()
  }

  return <PageWithPermission />
}
