import React, { useCallback } from 'react'

import { Grid, RadioGroup, TextFieldSearch, TypographyDivider } from '@opswat/react-ui'

import { useFilteredOrganizations } from 'myopswat-web/src/hooks/useFilteredOrganizations'

import { OrgRow } from './OrgRow'
import { RecursiveTreeWithData } from './RecursiveTree'

interface DefaultOrgProps {
  mode?: 'view' | 'edit'
  value: string
  onChange?: (value: string) => void
}

export const DefaultOrg = ({ mode = 'view', value, onChange }: DefaultOrgProps) => {
  const { searchValue, handleSearch, filteredOrgs } = useFilteredOrganizations()

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value)
    },
    [onChange]
  )

  return (
    <Grid container item xs={12} md={10} lg={8}>
      <Grid item xs={12}>
        <TypographyDivider label="Default Organization" propsTypo={{ variant: 'body1' }} />
      </Grid>

      <Grid item xs={12} md={4} pt={2} sx={{ minWidth: '280px' }}>
        <TextFieldSearch
          placeholder={'Search for Organization'}
          value={searchValue}
          onChange={e => handleSearch(e.target.value)}
          onClearText={() => handleSearch('')}
        />
      </Grid>

      <Grid item xs={12}>
        <RadioGroup value={value} onChange={handleChange}>
          <RecursiveTreeWithData mode={mode} data={filteredOrgs} Row={OrgRow} value={value} />
        </RadioGroup>
      </Grid>
    </Grid>
  )
}
