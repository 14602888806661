import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TableAdvanced,
  TextField,
  Typography
} from '@opswat/react-ui'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import { IPortalPermission } from 'myopswat-web/src/api/permission/types'
import { ICreateUpdatePortalRoleForm, IPermission, IRoleObject, IRoleObjectForm } from 'myopswat-web/src/api/role/types'
import { useMemo } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

interface IProps {
  formRef: UseFormReturn<ICreateUpdatePortalRoleForm>
  globalPortalPermissions: IPortalPermission[]
  defaultRoleObjects?: IRoleObject[]
  isFetchingRoleObjects: boolean
  onSelectPermission: (roleObject: IRoleObjectForm) => IRoleObjectForm[]
  viewOnly?: boolean
}

const CreateUpdateRoleForm = ({ formRef, globalPortalPermissions, defaultRoleObjects, isFetchingRoleObjects, onSelectPermission, viewOnly }: IProps) => {
  const {
    control,
    register,
    formState: { errors }
  } = formRef

  const loadCheckedValue = (
    roleObjectForms: IRoleObjectForm[],
    defaultRoleObjectId: string,
    currentPermission: IPermission
  ) => {
    return (
      roleObjectForms.some(
        roleObjectForm =>
          roleObjectForm.id === defaultRoleObjectId &&
          roleObjectForm.selectedPermissions.some(p => p === currentPermission.id)
      )
    )
  }

  const columnArray = useMemo(() => {
    return [
      {
        key: 'name',
        header: 'Name',
        body: (data: IRoleObject) => (
          <Typography variant="body2">
            {_get(data, 'name', '--')}
          </Typography>
        ),
        style: { minWidth: 300, width: 300, paddingTop: "0 !important", paddingBottom: "0 !important" }
      },
      {
        key: 'permissions',
        header: 'Permissions',
        body: (data: IRoleObject) => {
          const defaultRoleObjectId = _get(data, 'id')
          const defaultRoleObjectPermissions = _get(data, 'permissions', [])
          return (
            <Controller
              name="roleObjects"
              control={control}
              render={({ field }) => (
                <RadioGroup sx={{justifyContent: 'flex-end'}} row>
                  {globalPortalPermissions.map((permission: IPermission) => {
                    const isGlobalPermissionInDefault = defaultRoleObjectPermissions.find(p => p.id === permission.id)
                    return (
                      <FormControlLabel
                        key={defaultRoleObjectId + permission.id}
                        disabled={viewOnly}
                        checked={loadCheckedValue(
                          field.value,
                          defaultRoleObjectId,
                          permission
                        )}
                        sx={{ visibility: isGlobalPermissionInDefault ? "visible" : "hidden" }}
                        control={<Radio size="small" />}
                        label={<Typography variant="subtitle2">{permission.name}</Typography>}
                        onChange={() => {
                          field.onChange(
                            onSelectPermission({
                              id: defaultRoleObjectId,
                              selectedPermissions: [permission.id]
                            })
                          )
                        }}
                      />
                    )
                  })}
                </RadioGroup>
              )}
            />
          )
        },
        style: { minWidth: 300, textAlign: 'center', paddingTop: "0 !important", paddingBottom: "0 !important" }
      }
    ]
  }, [control, onSelectPermission])


  const sortedRoles = useMemo(() => {
    return _sortBy(defaultRoleObjects, item => {
      return Array.from(_get(item, 'name', '--')).map(char => {
        if (/\d/.test(char)) return `0${char}`
        if (/[a-z]/.test(char)) return `1${char}`
        if (/[A-Z]/.test(char)) return `2${char}`
        return `3${char}`
      })
    })
  }, [defaultRoleObjects])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1">Name*</Typography>
          <TextField
            required
            fullWidth
            disabled={viewOnly}
            size="small"
            {...register(`name`)}
            error={!!errors?.name}
            helperText={errors?.name?.message}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1">Description</Typography>
          <TextField
            required
            fullWidth
            disabled={viewOnly}
            size="small"
            multiline
            minRows={3}
            {...register(`description`)}
            error={!!errors?.description}
            helperText={errors?.description?.message}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TableAdvanced
            boxMinHeight="0px"
            boxMaxHeight="500px"
            columns={columnArray}
            isLoading={isFetchingRoleObjects}
            data={sortedRoles}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default CreateUpdateRoleForm
