import { useEffect } from 'react'

import { handleClearCookie } from '@myopswat/common'

import { useLazySaml2LoginQuery, useLazySaml2LogoutQuery } from 'myopswat-web/src/api/sso'

export const useSaml2Logout = () => {
  const [saml2Logout] = useLazySaml2LogoutQuery()
  const [saml2Login] = useLazySaml2LoginQuery()

  useEffect(() => {
    const logout = async () => {
      try {
        const response = await saml2Logout().unwrap()
        await handleClearCookie()
        window.location.href = response.url
      } catch (error: any) {
        if (error?.status !== 401) {
          console.error('Logout failed:', error)
          return
        }
        try {
          const loginResponse = await saml2Login().unwrap()
          window.location.href = loginResponse.url
        } catch (loginError) {
          console.error('Login failed:', loginError)
        }
      }
    }

    logout()
  }, [saml2Logout, saml2Login])

  return null
}
