import _get from 'lodash/get'

import { FC, useContext } from 'react'
import { useWatch } from 'react-hook-form'

import { Box, Grid, Typography } from '@opswat/react-ui'

import ExpiringLicenseChart from '../../components/charts/ExpiringLicenseChart'
import NearCapacityLicensesChart from '../../components/charts/NearCapacityLicensesChart'
import PurchaseRateChart from '../../components/charts/PurchaseRateChart'
import { CustomerLicensesContext } from '../../interface'

const CustomerAttentionNeededCharts: FC<any> = () => {
  const { controlProduct: control, reportData } = useContext(CustomerLicensesContext)

  const customerOrgIds = useWatch({ control, name: 'customerOrgIds' })
  const nearCapacityChartData = _get(reportData, ['near_capacity_licenses'], {})
  const purchaseRateChartData = _get(reportData, ['purchased_rate'], {})

  return (
    <Grid container xs={12}>
      <Grid item xs={12} md={6} lg={4} sx={{ pr: 4, mb: { xs: 2, lg: 0 } }}>
        <NearCapacityLicensesChart nearCapacityData={nearCapacityChartData} />
      </Grid>
      <Grid item xs={12} md={6} lg={4} sx={{ pr: 4, mb: { xs: 2, lg: 0 }, borderRight: '1px solid #E9EAEB' }}>
        <ExpiringLicenseChart customerOrgIds={customerOrgIds.map((c: any) => c.value)} />
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          pl: {
            xs: 0,
            lg: 2
          },
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <PurchaseRateChart data={purchaseRateChartData} />
        <Box sx={{ display: 'flex', flex: 1, width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          <Typography fontSize="12px" fontWeight={400} color="#707682" lineHeight="16px" fontStyle="italic">
            * Hover over the charts to see detailed insights.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CustomerAttentionNeededCharts
