import React, { FC } from 'react'
import { TableCell, Button } from '@opswat/react-ui'

interface ActionCellProps {
  level: number
  onClick: () => void
}

export const ActionCell: FC<ActionCellProps> = ({ level, onClick }) => (
  <TableCell
    sx={{
      p: 0.25,
      minWidth: 150,
      textAlign: 'right'
    }}
  >
    {level === 0 ? (
      <Button variant="contained" color="primary" onClick={onClick}>
        Download
      </Button>
    ) : (
      <div style={{ visibility: 'hidden' }}>
        <Button></Button>
      </div>
    )}
  </TableCell>
)
