import { ConfigureStoreOptions, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { apiGraphql, apiRestful, fetchAPI, fetchFilesAPI } from 'myopswat-web/src/api/base-query'
import home from '../containers/HomeContainer/homeContainerSlice'
import layout from '../containers/LayoutContainer/layoutContainerSlice'
import myDetail from '../containers/MyDetailContainer/myDetailContainerSlice'
import myInformation from '../containers/MyInformationContainer/myInformationContainerSlice'
import licensedProduct from '../containers/LicensedProductsContainer/licensedProductContainerSlice'
import supportServices from '../containers/SupportServicesContainer/supportServicesContainerSlice'

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: {
      layout,
      home,
      myDetail,
      myInformation,
      licensedProduct,
      supportServices,
      [apiGraphql.reducerPath]: apiGraphql.reducer,
      [fetchAPI.reducerPath]: fetchAPI.reducer,
      [fetchFilesAPI.reducerPath]: fetchFilesAPI.reducer,
      [apiRestful.reducerPath]: apiRestful.reducer
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        fetchAPI.middleware,
        apiGraphql.middleware,
        fetchFilesAPI.middleware,
        apiRestful.middleware
      ),
    ...options
  })

export const store = createStore()

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
