import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar } from 'notistack'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { ButtonLoading, DialogAdvanced, Divider, Grid, TypographyLineClamp } from '@opswat/react-ui'
import { useSupportCaseCsatUpdateMutation } from 'myopswat-web/src/api/support'

import CSATForm from '../../Form/CSATForm'

interface IProps {
  caseInfo: any
  openDialog: boolean
  handleCloseDialog: () => void
  handleSearchOnSubmit: () => void
}

const DialogCSAT: FC<IProps> = ({ caseInfo, openDialog, handleCloseDialog, handleSearchOnSubmit }) => {
  const [supportCaseCsatUpdate, { isLoading: isUpdatingCaseCsat }] = useSupportCaseCsatUpdateMutation()

  const defaultValues = useMemo(
    () => ({
      caseId: '',
      csScore: 0,
      productScore: 0,
      csatComment: '',
      tags: []
    }),
    []
  )

  const validationSchema = yup.object().shape({
    csScore: yup.number().required().integer().min(1),
    productScore: yup.number().required().integer().min(1)
  })

  const {
    watch,
    setValue,
    handleSubmit,
    trigger,
    formState: { isValid }
  } = useForm<any>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  const handleSubmitCaseCsat = useCallback(
    async (values: any) => {
      const csatComment =
        values.tags.length > 0
          ? `${values.csatComment ?? ''} --- ${values.tags.filter((item: string) => item).join(', ')}`
          : values.csatComment
      const request = { ...values, csatComment }
      delete request['tags']
      await supportCaseCsatUpdate({
        csatUpdatePayload: request
      })
        .unwrap()
        .then((response: any) => {
          if (response?.success) {
            handleCloseDialog()
            handleSearchOnSubmit()
            enqueueSnackbar('Your feedback has been submitted successfully.', {
              variant: 'success'
            })
          } else {
            enqueueSnackbar('Submitting feedback has failed. Please give the system a moment then try again.', {
              variant: 'error'
            })
          }
        })
        .catch(() => {
          enqueueSnackbar('Submitting feedback has failed. Please give the system a moment then try again.', {
            variant: 'error'
          })
        })
    },
    [watch, caseInfo]
  )

  useEffect(() => {
    Object.keys(defaultValues).forEach((field: string) => {
      const value = caseInfo[field === 'caseId' ? 'id' : field]
      setValue(field, field.includes('Score') ? Number(value) : value)
    })
    const tagsComment: string[] = caseInfo?.csatComment?.split('---') ?? []
    if (tagsComment.length > 1) {
      setValue(
        'tags',
        tagsComment
          .at(-1)
          ?.split(', ')
          .map(item => item.trim()) ?? []
      )
      setValue('csatComment', tagsComment.slice(0, -1).join('').trim())
    } else {
      setValue('tags', [])
    }
    trigger()
  }, [caseInfo])

  return (
    <DialogAdvanced
      open={openDialog}
      title={
        <TypographyLineClamp line={1} sx={{ fontSize: '16px', lineHeight: '20px', fontWeight: 500 }}>
          {`Feedback for case #${caseInfo?.caseNumber}: ${caseInfo?.subject}`}
        </TypographyLineClamp>
      }
      content={<CSATForm watch={watch} setValue={setValue} trigger={trigger} />}
      actions={
        <Grid container rowSpacing={1.5}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'text',
                  color: 'inherit',
                  onClick: handleCloseDialog
                }}
                propsLoading={{ color: 'inherit' }}
                isLoading={false}
              >
                Cancel
              </ButtonLoading>
            </Grid>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  disabled: isUpdatingCaseCsat || !isValid,
                  onClick: handleSubmit(handleSubmitCaseCsat),
                  disableElevation: true
                }}
                isLoading={isUpdatingCaseCsat}
                propsLoading={{ color: 'inherit' }}
              >
                Submit
              </ButtonLoading>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogProps={{
        sx: {
          '& .MuiPaper-root': {
            maxWidth: '500px'
          }
        }
      }}
      dialogTitleProps={{
        sx: {
          borderBottom: '1px solid #D3D9E6',
          marginInline: '24px',
          paddingY: '20px',
          paddingX: '0px'
        }
      }}
      dialogContentProps={{
        sx: {
          padding: '0px 24px'
        }
      }}
    />
  )
}

export default DialogCSAT
