import { ExternalLinkIcon } from '@opswat/react-icon'
import { Box, Button, Divider, Typography } from '@opswat/react-ui'

interface IProps {
  title: React.ReactNode
  link: string
}

const SubmitCaseSuggestion: React.FC<IProps> = ({ title, link }) => {
  return (
    <Button
      sx={{
        backgroundColor: '#F4F4F5',
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '12px',
        textTransform: 'none',
        textAlign: 'left',
        '&:hover': {
          backgroundColor: '#E9EAEB'
        }
      }}
      onClick={() => {
        window.open(link, '_blank')
      }}
    >
      <ExternalLinkIcon />
      <Divider
        orientation="vertical"
        sx={{
          height: '36px',
          borderColor: '#D2D4D6'
        }}
      />
      <Box>
        <Typography variant="body1" color="#1B273C">
          {title}
        </Typography>
        <Typography variant="caption" color="#485161">
          {link}
        </Typography>
      </Box>
    </Button>
  )
}

export default SubmitCaseSuggestion
