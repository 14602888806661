import { useState } from 'react'

import { Box } from '@opswat/react-ui'
import SupportServiceCaseTable from '../AllCases/components/CasesTableV2'

export const Cases = () => {
  const [key, setKey] = useState(crypto.randomUUID())

  const triggerReload = () => {
    setKey(crypto.randomUUID())
  }

  return (
    <Box
      key={key}
      sx={{
        border: '1px solid #E9EAEB',
        padding: '16px 16px 0 16px',
        minHeight: '400px'
      }}
    >
      <SupportServiceCaseTable isMiniTable triggerReload={triggerReload} />
    </Box>
  )
}
