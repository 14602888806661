import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { ButtonLoading, Dialog, DialogActions, DialogContent, DialogTitle } from '@opswat/react-ui'
import {
  usePortalOrganizationTransferSuperAdminMutation
} from 'myopswat-web/src/api/organization'
import useApiErrorHook from 'myopswat-web/src/hooks/useApiErrorHook'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { HighLightEmail } from '../styled'
interface IProps {
  orgUser: any
}

const SetSuperAdminModal = NiceModal.create(({ orgUser }: IProps) => {
  const { t } = useTranslation()
  const modal = useModal()
  const [transferSuperAdminOfOrg, { isLoading }] = usePortalOrganizationTransferSuperAdminMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { showErrorMessage } = useApiErrorHook({ errorMap: {} })
  const onRemove = () => {
    transferSuperAdminOfOrg({
      id: orgUser.id
    })
      .unwrap()
      .then(res => {
        if (res.success) {
          window.location.reload()
        } else {
          showErrorMessage(res.errors)
        }

        modal.resolve()
        modal.hide()
      })
      .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
  }

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove()
      }}
      maxWidth={'sm'}
    >
      <DialogTitle component={'h3'}>Set User As Super Admin</DialogTitle>
      <DialogContent>
        Do you want to set <HighLightEmail>{orgUser.email}</HighLightEmail> as super admin of the organization?
      </DialogContent>
      <DialogActions sx={{ p: '1rem' }}>
        <ButtonLoading
          propsButton={{
            variant: 'text',
            color: 'inherit',
            onClick: () => modal.hide(),
            disabled: isLoading
          }}
        >
          Cancel
        </ButtonLoading>
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            onClick: onRemove,
            disabled: isLoading
          }}
          isLoading={isLoading}
        >
          Yes
        </ButtonLoading>
      </DialogActions>
    </Dialog>
  )
})

export default SetSuperAdminModal
