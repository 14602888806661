import _get from 'lodash/get'

import { useContext } from 'react'
import { useWatch } from 'react-hook-form'

import { Grid } from '@opswat/react-ui'

import SubmitCasePlatform from '../components/SubmitCasePlatform'
import { SupportSubmitCaseContext } from '../interface'

const SubmitCasePlatformStep: React.FC<any> = () => {
  const { control, productList, handleSelectPlatform } = useContext(SupportSubmitCaseContext)

  const productId = useWatch({ control, name: 'productId' })
  const platform = useWatch({ control, name: 'platform' })

  return (
    <Grid
      container
      columns={{
        xs: 2,
        sm: 3,
        md: 4,
        lg: 6
      }}
      spacing={2}
    >
      {_get(
        productList.filter((item: any) => item.id === _get(productId, 'value', productId)),
        [0, 'supportCasePlatforms'],
        []
      ).map((item: any) => (
        <Grid item xs={1} key={item}>
          <SubmitCasePlatform
            id={item}
            name={item}
            isSelected={platform === item}
            handleSelectPlatform={(id: string) => {
              handleSelectPlatform(id)
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default SubmitCasePlatformStep
