import { FC } from 'react'

import { ControllerRenderProps } from 'react-hook-form'

import { TextField } from '@opswat/react-ui'

import { NPS_MAX_LENGTH } from 'myopswat-web/src/components/Dialog/DialogNPSSurvey/interface'

interface IProps extends ControllerRenderProps {
  placeholder?: any
  helperText?: any
}

const NPSText: FC<IProps> = ({ placeholder, helperText, ...rest }: IProps) => {
  const maxLengthError = rest.value && rest.value.length > NPS_MAX_LENGTH

  return (
    <TextField
      {...rest}
      multiline
      rows={4}
      placeholder={placeholder ?? "To protect your privacy, please don't include any sensitive information."}
      helperText={helperText}
      onChange={e => {
        if (!maxLengthError) {
          rest.onChange(e.target.value.substring(0, 500))
        }
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          padding: 1,
          '& textarea': {
            '&::-webkit-scrollbar': {
              width: '5px',
              height: '7px'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '20px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#BCBFC3',
              backgroundColor: '#BCBFC3'
            }
          }
        },
        '& .MuiFormHelperText-root': {
          margin: '6px 0px 0px 0px',
          color: '#707682'
        }
      }}
    />
  )
}

export default NPSText
