import React, { FC, useMemo } from 'react'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _map from 'lodash/map'

import { KEYS } from '@myopswat/common'
import { getTimeZoneLabel } from '@opswat/react-core'
import { Grid, TemplateSection, TextField, TextGrid, Typography } from '@opswat/react-ui'
import { DefaultOrg } from './DefaultOrg'

interface IProps {
  profileData: any
  sysItemCountriesData: any
}

const GeneralInfoView: FC<IProps> = ({ profileData, sysItemCountriesData }) => {
  const country = useMemo(() => {
    const result = _find(
      sysItemCountriesData,
      (item: any) => _get(item, 'code') === _get(profileData, 'address.country')
    )
    return result
  }, [profileData, sysItemCountriesData])

  const leftInfoArray = useMemo(() => {
    return [
      {
        label: 'First Name*',
        value: _get(profileData, 'firstName') === KEYS.FIRST_NAME_DEFAULT ? '' : _get(profileData, 'firstName')
      },
      {
        label: 'Last Name*',
        value: _get(profileData, 'lastName') === KEYS.LAST_NAME_DEFAULT ? '' : _get(profileData, 'lastName')
      },
      {
        label: 'Title*',
        value: _get(profileData, 'title') || ''
      },
      {
        label: 'Company*',
        value: _get(profileData, 'companyName') || ''
      },
      {
        label: 'Phone Number*',
        value: _get(profileData, 'address.phone') || ''
      },
      {
        label: 'Time Zone*',
        value: getTimeZoneLabel(_get(profileData, 'timezone', ''))
      }
    ]
  }, [profileData])

  const rightInfoArray = useMemo(() => {
    return [
      {
        label: 'Address1*',
        value: _get(profileData, 'address.streetAddress1') || ''
      },
      {
        label: 'Address2',
        value: _get(profileData, 'address.streetAddress2') || ''
      },
      {
        label: 'City*',
        value: _get(profileData, 'address.city') || ''
      },
      {
        label: 'Country*',
        value: _get(country, 'name') || ''
      },
      ...((_get(country, 'code', '') === 'US' && [
        {
          label: 'State',
          value: _get(profileData, 'address.state', '')
        }
      ]) ||
        []),
      {
        label: 'Postal Code*',
        value: _get(profileData, 'address.postalCode') || ''
      }
    ]
  }, [profileData, country])

  const usingOrg = useMemo(() => {
    return _get(profileData, 'usingOrganizationId', '')
  }, [profileData])

  return (
    <TemplateSection>
      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            <TextGrid
              label={<Typography variant="subtitle2">Email</Typography>}
              value={<Typography>{_get(profileData, 'email', '')}</Typography>}
            />

            {_map(leftInfoArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                  value={<TextField disabled size="small" fullWidth value={_get(item, 'value')} />}
                />
              </React.Fragment>
            ))}
          </TemplateSection>
        </Grid>

        <Grid item xs={12} sm={6} md={5} lg={4} sx={{ ml: { xs: 0, md: 4 }, mt: { xs: 2, sm: 0 } }}>
          <TemplateSection>
            {_map(rightInfoArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                  value={<TextField disabled size="small" fullWidth value={_get(item, 'value')} />}
                />
              </React.Fragment>
            ))}
          </TemplateSection>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TextGrid
            label={<Typography variant="subtitle2">Note</Typography>}
            value={
              <>
                <TextField
                  disabled
                  minRows={6}
                  multiline
                  fullWidth
                  size="small"
                  value={_get(profileData, 'note') || ''}
                />
                <Typography color="textSecondary" variant="body2" sx={{ paddingTop: 2 }}>
                  Please do not add any personal information or Confidential information here.
                </Typography>
              </>
            }
            containerProps={{ alignItems: 'flex-start' }}
          />
        </Grid>
      </Grid>

      <DefaultOrg value={usingOrg} />
    </TemplateSection>
  )
}

export default GeneralInfoView
