import { useSnackbar } from 'notistack'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import _debounce from 'lodash/debounce'
import _get from 'lodash/get'

import { BLOB_FILE_TYPES, handleCreateFile } from '@myopswat/common'
import { ExportFileIcon, SolidDownIcon } from '@opswat/react-icon'
import {
  Box,
  Button,
  CircularLoading,
  Divider,
  DropdownFilter,
  Grid,
  IconButton,
  Skeleton,
  TextFieldSearch,
  Tooltip,
  Typography,
  TypographyLineClamp
} from '@opswat/react-ui'

import { useLazySupportCasesExcelExportQuery } from 'myopswat-web/src/api/support'
import { ISupportCaseV2Filters } from 'myopswat-web/src/api/support/types'
import { ExtendView } from '../../cases/ExtendView'
import { CaseStatus } from '../constant'
import MultiValueFilters from './MultiValueFilters'

interface IProps {
  filters: ISupportCaseV2Filters
  setCaseFilters: Dispatch<SetStateAction<ISupportCaseV2Filters>>
  supportProducts?: any[]
  hasOrg?: boolean
  orgId?: string
  isLoading?: boolean
  isDisplayExtendCaseView?: boolean
  orgCaseAccessInfo: any[]
  refreshData: () => Promise<void>
}

const CasesFilters: FC<IProps> = ({
  setCaseFilters,
  filters,
  supportProducts,
  hasOrg,
  orgId,
  isLoading,
  isDisplayExtendCaseView,
  orgCaseAccessInfo,
  refreshData
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const [
    getExcelExport,
    { data: casesExcelData, isFetching: isFetchingCasesExcelData, isError: isErrorFetchingCasesExcelData }
  ] = useLazySupportCasesExcelExportQuery()

  const [keyword, setKeyword] = useState<string>('')
  const [innerFilters, setInnerFilters] = useState<ISupportCaseV2Filters>(filters)
  const [isProcessingExcelFile, setIsProcessingExcelFile] = useState<boolean>(false)

  const filteredProducts = useMemo(() => {
    if (!supportProducts) return []

    const _products = supportProducts.map(product => ({
      id: product.id,
      name: product.name
    }))

    // Add 'Other' product as a filter option
    _products.push({
      id: 'Other',
      name: 'Other'
    })

    return _products
  }, [supportProducts])

  const caseStatusOptions = Object.values(CaseStatus).map(status => ({
    id: status,
    name: status
  }))

  const filterCount = useCallback(
    (field: keyof ISupportCaseV2Filters) => {
      if (!Object.keys(filters).includes(field)) {
        return 0
      }

      return _get(filters, `${field}.length`, 0)
    },
    [filters]
  )

  const setFilterValue = (field: keyof ISupportCaseV2Filters) => (value: string | null | string[]) => {
    if (value !== null && (value !== '' || value.length !== 0)) {
      setInnerFilters((prev: ISupportCaseV2Filters) => {
        return {
          ...prev,
          [field]: value
        }
      })
    } else {
      setInnerFilters((prev: ISupportCaseV2Filters) => {
        const newFilters = { ...prev }

        if (field in newFilters) delete newFilters[field]

        return newFilters
      })
    }
  }

  const handleApplyFilter = () => {
    setCaseFilters(innerFilters)
  }

  const renderFilterText = useCallback(
    (key: keyof ISupportCaseV2Filters, label: string) => {
      const values = _get(filters, key)

      if (!values || _get(values, 'length', 0) === 0) return ''

      return `${label} = ${values}`
    },
    [filters]
  )

  const renderAllFilterText = useCallback(() => {
    const productFilter = renderFilterText('productName', 'Products')
    const statusFilter = renderFilterText('status', 'Status')

    return [productFilter, statusFilter].filter((item: any) => item).join('; ')
  }, [filteredProducts, renderFilterText])

  const handleExportCasesToExcel = useCallback(() => {
    getExcelExport({ filters })
    setIsProcessingExcelFile(true)
  }, [filters])

  useEffect(() => {
    if (isProcessingExcelFile && _get(casesExcelData, 'content') && !isFetchingCasesExcelData) {
      enqueueSnackbar('Successfully download excel file.', {
        variant: 'success'
      })

      handleCreateFile(
        _get(casesExcelData, 'filename', 'My Opswat Support Service'),
        'xlsx',
        _get(casesExcelData, 'content', ''),
        {
          type: BLOB_FILE_TYPES.EXCEL
        }
      )
      setIsProcessingExcelFile(false)
    } else if (isProcessingExcelFile && isFetchingCasesExcelData && !isFetchingCasesExcelData) {
      enqueueSnackbar('Failed to download excel file.', {
        variant: 'error'
      })
      setIsProcessingExcelFile(false)
    }
  }, [isProcessingExcelFile, isErrorFetchingCasesExcelData, casesExcelData, isFetchingCasesExcelData])

  useEffect(() => {
    setInnerFilters(filters)
    setKeyword(_get(filters, 'q', ''))
  }, [filters])

  useEffect(() => {
    if (keyword || keyword === '') {
      const handleSearch = _debounce(() => {
        setCaseFilters((prev: ISupportCaseV2Filters) => {
          if (keyword === '') {
            if ('q' in prev) {
              const _prev = { ...prev }

              delete _prev['q']

              return _prev
            }

            return prev
          }

          return {
            ...prev,
            q: keyword
          }
        })
      }, 500)

      handleSearch()

      return () => {
        handleSearch.cancel()
      }
    }
  }, [keyword])

  if (isLoading) {
    return (
      <Box sx={{ padding: 2, height: '100%' }}>
        <Skeleton animation="wave" variant="rectangular" height="100%" />
      </Box>
    )
  }

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={4}>
          <TextFieldSearch
            value={keyword}
            placeholder={'Search by case number ...'}
            onChange={(e: any) => {
              if (e?.target) {
                setKeyword(e.target.value)
              }
            }}
            onClearText={() => setKeyword('')}
            sx={{ display: 'flex', justifyContent: 'center' }}
          />
        </Grid>

        <Grid item xs alignItems="center" justifyContent={'right'} container gap="4px">
          {/* Product Dropdown Filters */}
          <DropdownFilter
            resetOnSubmit={false}
            propsPopper={{
              placement: 'bottom-start'
            }}
            propsPaper={{
              style: {
                width: '600px'
              }
            }}
            button={
              <Button
                variant="text"
                endIcon={<SolidDownIcon color={filterCount('productName') > 0 ? '#154FBA' : undefined} />}
                sx={{
                  color: filterCount('productName') > 0 ? '#154FBA' : 'inherit'
                }}
              >
                {filterCount('productName') > 0 ? `Product (${filterCount('productName')})` : 'Product'}
              </Button>
            }
            content={
              <Grid container spacing={2}>
                <MultiValueFilters
                  label="Product"
                  placeholder="Search product ..."
                  valueOptions={filteredProducts || []}
                  value={_get(innerFilters, 'productName')}
                  setValue={setFilterValue('productName')}
                  allowSearch
                />
              </Grid>
            }
            onResetFilter={() => {
              setFilterValue('productName')(null)
            }}
            onResetChange={() => {
              setFilterValue('productName')(_get(filters, 'productName', null))
            }}
            onApply={() => handleApplyFilter()}
          />

          <Divider
            sx={{
              height: '20px',
              width: '1px',
              backgroundColor: '#E9ECF2',
              margin: '4px'
            }}
          />

          {/* Status Dropdown Filters */}
          <DropdownFilter
            resetOnSubmit={false}
            propsPopper={{
              placement: 'bottom-start'
            }}
            propsPaper={{
              style: {
                width: '600px'
              }
            }}
            button={
              <Button
                variant="text"
                endIcon={<SolidDownIcon color={filterCount('status') > 0 ? '#154FBA' : undefined} />}
                sx={{
                  color: filterCount('status') > 0 ? '#154FBA' : 'inherit'
                }}
              >
                {filterCount('status') > 0 ? `Status (${filterCount('status')})` : 'Status'}
              </Button>
            }
            content={
              <Grid container spacing={2}>
                <MultiValueFilters
                  label="Status"
                  valueOptions={caseStatusOptions}
                  value={_get(innerFilters, 'status')}
                  setValue={setFilterValue('status')}
                />
              </Grid>
            }
            onResetFilter={() => {
              setFilterValue('status')(null)
            }}
            onResetChange={() => {
              setFilterValue('status')(_get(filters, 'status', null))
            }}
            onApply={() => handleApplyFilter()}
          />

          <Divider
            sx={{
              height: '20px',
              width: '1px',
              backgroundColor: '#E9ECF2',
              margin: '4px'
            }}
          />

          {/* Export Button */}
          <Tooltip
            title="Export to Excel"
            placement="top"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  color: '#1B273C',
                  backgroundColor: '#E9EAEB',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px'
                }
              },
              arrow: {
                sx: {
                  color: '#E9EAEB'
                }
              }
            }}
          >
            {isFetchingCasesExcelData ? (
              <Box p={'14px'} marginTop={-0.25} marginRight={0.27}>
                <CircularLoading type="small" color={'primary'} fillColor="black" />
              </Box>
            ) : (
              <IconButton size="small" onClick={() => handleExportCasesToExcel()} disabled={isFetchingCasesExcelData}>
                <ExportFileIcon />
              </IconButton>
            )}
          </Tooltip>

          {/* Extend Case View Button */}
          {isDisplayExtendCaseView && (
            <ExtendView orgCaseAccessInfo={orgCaseAccessInfo} hasOrg={hasOrg} orgId={orgId} refreshData={refreshData} />
          )}
        </Grid>
      </Grid>

      {(keyword || renderAllFilterText()) && (
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {keyword && (
            <Typography variant="body1">
              Search:{' '}
              <Typography component="span" variant="body2">
                {keyword}
              </Typography>
            </Typography>
          )}
          {renderAllFilterText() && (
            <TypographyLineClamp
              line={1}
              variant="body1"
              tooltipValue={renderAllFilterText()}
              tooltipPlacement="top"
              tooltipProps={{
                tooltip: {
                  sx: {
                    color: '#1B273C',
                    backgroundColor: '#E9EAEB',
                    whiteSpace: 'pre-line'
                  }
                },
                arrow: {
                  sx: {
                    color: '#E9EAEB'
                  }
                }
              }}
            >
              <span>
                Filter:{' '}
                <Typography component="span" variant="body2">
                  {renderAllFilterText()}
                </Typography>
              </span>
            </TypographyLineClamp>
          )}
        </Grid>
      )}
    </>
  )
}

export default CasesFilters
