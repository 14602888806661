import _get from 'lodash/get'

import { FC, useCallback } from 'react'

import { Box, Button, DialogAdvanced, Grid, Typography } from '@opswat/react-ui'

import { useUpdateShowDialogMutation } from 'myopswat-web/src/api/account'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import { selectDialogs, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { supportServicesPageURL } from 'myopswat-web/src/routes'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

const DialogNoOgranizationOCM: FC<any> = () => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)

  const [updateHasShownDialog] = useUpdateShowDialogMutation()

  const handleUpdateHasShownDialog = useCallback(async (callbackAction: () => void) => {
    await updateHasShownDialog('ocm_no_org_message').then(callbackAction)
  }, [])

  const handleCloseDialog = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS_WEB.OCM_NO_ORGANIZATION]: false
      })
    )
  }

  return (
    <DialogAdvanced
      open={_get(dialogType, DIALOGS_WEB.OCM_NO_ORGANIZATION, false)}
      title="Access Central Management"
      content={
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 0.75
            }}
          >
            <Typography variant="body2">
              You are not currently associated with any organization. To continue accessing Central Management, you can:
            </Typography>
            <Box sx={{ paddingLeft: 3, display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Typography variant="body2" color="#1B273C" fontWeight={500}>
                Continue as a personal account
              </Typography>
              <Typography variant="subtitle2" lineHeight="20px" color="#485161">
                With this option, you will not be able to invite other users to access your account.
              </Typography>
            </Box>
            <Typography variant="subtitle2" lineHeight="20px" color="#1B273C" paddingLeft={3}>
              OR
            </Typography>
            <Box sx={{ paddingLeft: 3, display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Typography variant="body2" color="#1B273C" fontWeight={500}>
                Contact your organization's administrators
              </Typography>
              <Typography variant="subtitle2" lineHeight="20px" color="#485161">
                Request to be added to your organization's account. If your organization is not registered with us,
                please contact the OPSWAT Support Team to create one.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Button
                disableElevation
                color="inherit"
                variant="text"
                onClick={() => {
                  handleUpdateHasShownDialog(handleCloseDialog)
                }}
              >
                Cancel
              </Button>
              <Box display="flex" justifyContent="flex-end" gap={2}>
                <Button
                  disableElevation
                  color="inherit"
                  variant="text"
                  onClick={() => {
                    handleUpdateHasShownDialog(() => {
                      window.location.href = supportServicesPageURL
                    })
                  }}
                >
                  Contact OPSWAT Support
                </Button>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleUpdateHasShownDialog(() => {
                      window.location.href = process.env.REACT_APP_OPSWAT_OC_URL || ''
                    })
                  }}
                  sx={{
                    textTransform: 'none'
                  }}
                >
                  Continue as a Personal Account
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      }
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}

export default DialogNoOgranizationOCM
