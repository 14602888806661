import { FC } from 'react'

import { Box, Typography } from '@opswat/react-ui'

interface IProps {
  title: React.ReactNode
  actions?: React.ReactNode
}

const CustomerContainerTitle: FC<IProps> = ({ title, actions }) => {
  return (
    <Box
      sx={{
        padding: '10px 15px',
        backgroundColor: '#EFF4FF',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Typography variant="body1" color="#616875">
        {title}
      </Typography>
      {actions}
    </Box>
  )
}

export default CustomerContainerTitle
