import { FC, useMemo } from 'react'

import { Box, Grid, Typography, TypographyDivider } from '@opswat/react-ui'

import CustomerAttentionNeededCharts from './attentionNeededCharts'
import CustomerOverviewCharts from './overviewCharts'

const CustomerChartSection: FC<any> = () => {
  const OverviewMemo = useMemo(() => {
    return <CustomerOverviewCharts />
  }, [])

  const AttentionNeededMemo = useMemo(() => {
    return <CustomerAttentionNeededCharts />
  }, [])

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} sx={{ padding: '15px', border: '1px solid #BCBFC3', gap: '20px' }}>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <TypographyDivider
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, height: '20px' }}>
                <Typography variant="body1" marginTop="2px">
                  Overview
                </Typography>
              </Box>
            }
            propsTypo={{
              component: 'label',
              variant: 'body1'
            }}
          />
          {OverviewMemo}
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <TypographyDivider
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, height: '20px' }}>
                <Typography variant="body1" marginTop="2px">
                  Attention Needed
                </Typography>
              </Box>
            }
            propsTypo={{
              component: 'label',
              variant: 'body1'
            }}
          />
          {AttentionNeededMemo}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CustomerChartSection
