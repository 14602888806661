import { FC, useEffect } from 'react'

import _isEmpty from 'lodash/isEmpty'

import { Box, Grid, TemplateSection, TypographyDivider } from '@opswat/react-ui'

import { useTypedSelector } from 'myopswat-web/src/store'

import { useLazySysItemCountriesQuery } from 'myopswat-web/src/api/system'
import { selectOrganizations } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { selectEditMode } from 'myopswat-web/src/containers/MyInformationContainer/myInformationContainerSlice'
import { myInformationPersonalInfoPageURL } from 'myopswat-web/src/routes'
import { useLocation } from 'react-router-dom'
import GeneralInfoEdit from './GeneralInfoEdit'
import GeneralInfoLoading from './GeneralInfoLoading'
import GeneralInfoView from './GeneralInfoView'

const General: FC<unknown> = () => {
  const location = useLocation()

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data) || {}
  const organizationsData = useTypedSelector(selectOrganizations)
  const editMode = useTypedSelector(selectEditMode)

  const [getSysItemCountries, { data: sysItemCountriesData }] = useLazySysItemCountriesQuery()

  useEffect(() => {
    if (location.pathname === myInformationPersonalInfoPageURL) {
      getSysItemCountries()
    }
  }, [])

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {_isEmpty(profileData) ? (
        <TemplateSection>
          <Grid container>
            <Grid item xs={12} md={10} lg={8}>
              <TypographyDivider label="Details" propsTypo={{ variant: 'body1' }} />
            </Grid>
          </Grid>

          <GeneralInfoLoading />
        </TemplateSection>
      ) : (
        <TemplateSection>
          <Grid container>
            <Grid item xs={12} md={10} lg={8}>
              <TypographyDivider label="Details" propsTypo={{ variant: 'body1' }} />
            </Grid>
          </Grid>

          {editMode === 'info' ? (
            <GeneralInfoEdit
              profileData={profileData}
              sysItemCountriesData={sysItemCountriesData}
              organizationsData={organizationsData}
            />
          ) : (
            <GeneralInfoView profileData={profileData} sysItemCountriesData={sysItemCountriesData} />
          )}
        </TemplateSection>
      )}
    </Box>
  )
}

export default General
