import _get from 'lodash/get'

import { FC, useEffect, useMemo } from 'react'

import { Grid, Typography } from '@opswat/react-ui'

import { useLazyOrganizationTreeQuery } from 'myopswat-web/src/api/license'
import useGetPortalPermissions from 'myopswat-web/src/hooks/useGetPortalPermissions'
import useHasPermissions from 'myopswat-web/src/hooks/useHasPermissions'

import MyLicensesLoading from './components/MyLicensesLoading'
import SingleOrgLicenseView from './views/SingleOrgView'
import SubOrgViewProvider from './views/SubOrgView/context'

interface IProps {
  setCsvFilter: (data: any) => void
  activateSuccessful: boolean
}

const MyLicensesPage: FC<IProps> = ({ setCsvFilter, activateSuccessful }) => {
  const { permissions } = useGetPortalPermissions()

  const viewLicensePermission = useHasPermissions({
    targetPerms: ['view_my_licenses'],
    userPerms: permissions
  })

  const fullLicensePermission = useHasPermissions({
    targetPerms: ['full_my_licenses'],
    userPerms: permissions
  })

  const viewSubOrgPermission = useHasPermissions({
    targetPerms: ['view_my_suborg_license'],
    userPerms: permissions
  })

  const fullSubOrgPermission = useHasPermissions({
    targetPerms: ['full_my_suborg_license'],
    userPerms: permissions
  })

  const licensePermissions = useMemo(
    () => ({
      viewLicensePermission,
      fullLicensePermission,
      viewSubOrgPermission,
      fullSubOrgPermission
    }),
    [viewLicensePermission, fullLicensePermission, viewSubOrgPermission, fullSubOrgPermission]
  )

  const [getOrganizationTree, { data: organizationTreeData, isFetching: isFetchingOrganizationTree }] =
    useLazyOrganizationTreeQuery()

  // if there are sub-orgs of this current org
  // and the user has view or full sub-org permission
  const canViewSubOrg = useMemo(() => {
    const displayMode = _get(organizationTreeData, 'licenseDisplayMode', 'flat')
    return displayMode === 'tree' && (viewSubOrgPermission || fullSubOrgPermission)
  }, [organizationTreeData, viewSubOrgPermission, fullSubOrgPermission])

  useEffect(() => {
    getOrganizationTree()
  }, [])

  return (
    <Grid container spacing={2.5} sx={{ marginTop: '0px' }}>
      <Grid item xs={12}>
        <Typography variant="body2" component="label">
          This page summarizes all license purchases and evaluations of your organization.
        </Typography>
      </Grid>

      {isFetchingOrganizationTree && (
        <Grid item xs={12}>
          <MyLicensesLoading />
        </Grid>
      )}

      {!isFetchingOrganizationTree && (
        <Grid item container xs={12}>
          {canViewSubOrg ? (
            <SubOrgViewProvider
              permissions={licensePermissions}
              organizationTreeData={organizationTreeData}
              setCsvFilter={setCsvFilter}
              activateSuccessful={activateSuccessful}
            />
          ) : (
            <SingleOrgLicenseView setCsvFilter={setCsvFilter} permissions={licensePermissions} />
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default MyLicensesPage
