import { OtherResourcesIcon } from '@opswat/react-icon'
import { Box, DialogAdvanced, Typography } from '@opswat/react-ui'

import { useDialog } from 'myopswat-web/src/hooks/useDialog'

import { CommonLinearBox } from '../components/CommonLinearBox'
import { ResourcesDialog } from './ResourcesDialog'

export const OtherResources = () => {
  const { isOpen, toggleDialog } = useDialog()

  return (
    <>
      <CommonLinearBox>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            height: '100%',
            gap: 2,
            cursor: 'pointer'
          }}
          onClick={toggleDialog}
        >
          <Typography
            variant="body1"
            sx={{
              color: '#242424',
              flex: '1 1 auto'
            }}
          >
            Other External Resources
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 1,
              flex: '1 1 auto'
            }}
          >
            <OtherResourcesIcon />
          </Box>
        </Box>
      </CommonLinearBox>

      <DialogAdvanced
        open={isOpen}
        title={'Other External Resources'}
        onClose={toggleDialog}
        content={<ResourcesDialog />}
      />
    </>
  )
}
