import { handleConvertStringToBoolean } from '@myopswat/common'

export const ORGANIZATION_ADMIN_ROLE = '257d94a3-2aaf-4e89-8bf7-a118a7f6c0fd'

export enum PORTAL_ROLES_DEFAULT {
  SUPER_ADMIN = "Super Admin",
  ADMIN = "Admin",
  USER = "User",
}

export enum REDIRECT_MESSAGE_CODE {
  ACTIVE_USER_SUCCESS = 'S001',
  ACTIVE_USER_FAIL = 'E001',
  ACCEPT_INVITE_SUCCESS = 'S002'
}

export enum DIALOG_MESSAGES {
  organization_admin_permission = 'You must have admin permission of organization to perform this action.'
}

export enum AUTHENTICATION_CODES {
  jwt_signature_expired = 'jwt_signature_expired',
  account_not_authenticated = 'account_not_authenticated',
  invalid_token = 'invalid_token',
  session_error = 'session_error'
}

export enum SYSTEM_VALUE_KEYS {
  SUPPORT_SERVICES_PHONE = 'support_services_phone',
  SUPPORT_SUBMIT_CASE_PRODUCTS = 'support_submit_case_products'
}

// Pagination
export const PAGE_DEFAULT = 0 // fetch data at page 1 but @MuiPagination has zero base index
export const PAGE_SIZE_DEFAULT = 10
export const PAGE_SIZES_DEFAULT = [5, 10, 25, 100]

export const MAXIMUM_UPLOAD_FILE_SIZE = 2 * 1073741824 // 2 GB
export const MAXIMUM_UPLOAD_FILE_SIZE_SUBMIT_CASE = 2 * 1073741824 // 2 GB
export const MAXIMUM_UPLOAD_FILE_NUMBER_SUBMIT_CASE = 5
export const MAXIMUM_UPLOAD_IMAGE_SIZE = 10485760 // 10 MB

export const isEnabledPOCFeatures = handleConvertStringToBoolean(process.env.REACT_APP_ENABLED_POC_FEATURES)
export const isEnabledBanner = handleConvertStringToBoolean(process.env.REACT_APP_ENABLED_BANNER)
export const isFalseSubmissionDisabled = handleConvertStringToBoolean(process.env.REACT_APP_FALSE_SUBMISSION_DISABLED)
export const CHAT_BOT_INJECT_URL = 'https://cdn.botpress.cloud/webchat/v1/inject.js'
export const CHAT_BOT_CONFIG_URL =
  'https://mediafiles.botpress.cloud/f989dc68-6d29-41be-aa9b-92ab5885956e/webchat/config.js'
export const CHAT_BOT_CONFIG = {
  composerPlaceholder: 'Talk to OPSWAT AI Virtual Assistant',
  botId: 'f989dc68-6d29-41be-aa9b-92ab5885956e',
  hostUrl: 'https://cdn.botpress.cloud/webchat/v1',
  messagingUrl: 'https://messaging.botpress.cloud',
  clientId: 'f989dc68-6d29-41be-aa9b-92ab5885956e',
  webhookId: '6f76d8c9-65f4-42e7-9933-95cd4a4da3f7',
  lazySocket: true,
  themeName: 'galaxy',
  botName: 'OPSWAT AI Bot',
  avatarUrl: 'https://i.ibb.co/V3qWPfD/opswat-logo.png',
  stylesheet: 'https://webchat-styler-css.botpress.app/prod/code/4eb363c3-2e75-412c-986a-ea9d45bff795/v76305/style.css',
  frontendVersion: 'v1',
  useSessionStorage: true,
  enableConversationDeletion: true,
  enablePersistHistory: false,
  theme: 'galaxy',
  themeColor: '#2563eb',
  allowedOrigins: []
}
export const CHAT_BOT_INJECT_URL_V2 = 'https://cdn.botpress.cloud/webchat/v2.2/inject.js'
export const CHAT_BOT_INIT_URL = process.env.REACT_APP_BOTPRESS_URL || ''
export const CHAT_BOT_INJECT_URL_CUSTOM = 'https://static.opswatacademy.com/assets/va/customScript.js'
export const CHAT_BOT_HEADER_URL_CUSTOM = 'https://static.opswatacademy.com/assets/va/opswat-white.png'
export const CHAT_BOT_STYLES_URL_CUSTOM = 'https://static.opswatacademy.com/assets/va/main-style.css'
export const CHAT_BOT_CONFIG_URL_V2 =
  'https://mediafiles.botpress.cloud/c3632c64-3329-4a27-afed-77600eec32d3/webchat/v2/theme.json'
export const CHAT_BOT_CONFIG_V2 = {
  clientId: 'b1396b37-e226-4401-b944-1b73649c180f',
  botId: 'c3632c64-3329-4a27-afed-77600eec32d3',
  style: 'https://static.opswatacademy.com/assets/va/style.css',
  configuration: {
    botAvatar: 'https://i.ibb.co/V3qWPfD/opswat-logo.png',
    botDescription: '',
    botName: ' ',
    composerPlaceholder: 'Chat with me'
  }
}

export const OPSWAT_EMAIL_DOMAIN = 'opswat.com'
export const MATOMO_URL = 'https://cdn.matomo.cloud/opswat.matomo.cloud/container_pfTEYDTb.js'

export enum USER_ACTIVITY {
  redirect = 'redirect'
}
