import React, { FC, useEffect } from 'react'

import _debounce from 'lodash/debounce'

import {
  DropdownFilter,
  Button,
  Grid,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
  TextFieldSearch
} from '@opswat/react-ui'

import { SolidDownIcon, FilterIcon } from '@opswat/react-icon'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IDownloadedProductsFilter } from 'myopswat-web/src/api/product/types'

interface IProps {
  children?: React.ReactNode
  setDownloadedFilter: React.Dispatch<React.SetStateAction<IDownloadedProductsFilter>>
  downloadedFilter: IDownloadedProductsFilter
}

enum TimeOptions {
  LAST_24_HOURS = 'last-24h',
  LAST_7_DAYS = 'last-7d',
  LAST_30_DAYS = 'last-30d',
  LAST_12_MONTHS = 'last-12m'
}

const TIME_OPTIONS = [
  { label: 'Last 24 hours', value: TimeOptions.LAST_24_HOURS },
  { label: 'Last 7 days', value: TimeOptions.LAST_7_DAYS },
  { label: 'Last 30 days', value: TimeOptions.LAST_30_DAYS },
  { label: 'Last 12 months', value: TimeOptions.LAST_12_MONTHS }
]
const BoxDownloadedFilter: FC<IProps> = ({ setDownloadedFilter, downloadedFilter }) => {
  const { t: translate } = useTranslation()

  const { handleSubmit, control, watch, reset, setValue, getValues } = useForm<IDownloadedProductsFilter>({
    defaultValues: downloadedFilter,
    values: downloadedFilter
  })

  const onSuccess = (data: IDownloadedProductsFilter) => {
    setDownloadedFilter(data)
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  useEffect(() => {
    const handleSearch = _debounce(() => {
      setDownloadedFilter({
        ...getValues(),
        q: watch('q')
      })
    }, 300)

    handleSearch()
    return () => {
      handleSearch.cancel()
    }
  }, [watch('q')])

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={(cProps: any) => (
            <TextFieldSearch
              placeholder={'Search by parent product name'}
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onClearText={() => cProps.field.onChange('')}
              onKeyUp={(e: any) => {
                if (e.target.value === '') {
                  setDownloadedFilter({
                    ...getValues(),
                    q: ''
                  })
                }
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs="auto">
        <DropdownFilter
          button={
            <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
              Filter
            </Button>
          }
          content={
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Time</Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="time"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      {TIME_OPTIONS.map(option => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio size="small" />}
                          label={<Typography variant="subtitle2">{option.label}</Typography>}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </Grid>
            </Grid>
          }
          onResetFilter={() => {
            setValue('time', '')
          }}
          onResetChange={() => reset()}
          onApply={() => {
            handleSubmit(onSuccess, onFail)()
          }}
        />
      </Grid>
    </Grid>
  )
}

export default BoxDownloadedFilter
