import { FC } from 'react'

import { Button, Typography } from '@opswat/react-ui'

interface IProps {
  title: string
  value: number
  color?: string
  sx?: any
  onClick?: (label: string, value: any) => any
}

const CustomerStatisticsItem: FC<IProps> = ({ title, value, color, sx, onClick }) => {
  return (
    <Button
      fullWidth
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: {
          xs: 'center',
          lg: 'flex-start'
        },
        cursor: value > 0 ? 'pointer' : 'default',
        ...sx
      }}
      disabled={value <= 0}
      onClick={() => {
        onClick?.(title, value)
      }}
    >
      <Typography fontSize={'32px'} lineHeight={'38px'} fontWeight={500} color={color ?? '#1B273C'}>
        {value}
      </Typography>
      <Typography variant="body1" color="#1B273C" textAlign="left">
        {title}
      </Typography>
    </Button>
  )
}

export default CustomerStatisticsItem
