import { FC, useMemo } from 'react'
import { Helmet } from 'react-helmet'

import _get from 'lodash/get'
import _intersection from 'lodash/intersection'
import _map from 'lodash/map'
import _orderBy from 'lodash/orderBy'
import _size from 'lodash/size'

import { handleReorderArray } from '@myopswat/common'
import { ChevronLeftIcon, ChevronRightIcon } from '@opswat/react-icon'
import { Box, SwiperSlider, TemplateSection, TypographyDivider } from '@opswat/react-ui'

import { IDownloadedProductInput } from 'myopswat-web/src/api/product/types'
import { ONPREMISE_OEM_CLOUDBASE_TAGS } from 'myopswat-web/src/constants/product-type'
import { homePageURL } from 'myopswat-web/src/routes'

import BoxBlog from 'myopswat-web/src/components/Box/BoxBlog'
import DetailInfoSkeleton from 'myopswat-web/src/components/Skeleton/DetailInfoSkeleton'
import {
  ENDPOINT_SECURITY_SDK_ORDER_ARRAY,
  OPSWAT_NETWALL_ORDER_ARRAY,
  PRODUCT_IDS
} from 'myopswat-web/src/constants/product-ids'
import {
  saveProductChangeDetail,
  selectProductDetail,
  selectProductUtilityDetail
} from 'myopswat-web/src/containers/MyDetailContainer/myDetailContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import BoxDetailInfo from './BoxDetailInfo'
import BoxUtilityProduct from './BoxUtilityProduct'

interface IProps {
  productDetailData: any
  isFetchingProductDetail: boolean
  handleDownloadedProductAdd: (data: IDownloadedProductInput) => void
  productRelatedEntriesData: any
  isFetchingProductRelatedEntries: boolean
  handleEntrySeen: (data: string) => void
  handleEntryUnseen: (data: string) => void
  handleEntryIgnore: (data: string) => void
  licensedProductsData: any
  isFetchingLicensedProducts: boolean
  icapIntegrationsData: any
  handleProductInterestedAdd: (data: string) => void
  handleProductInterestedRemove: (data: string) => void
  handleReturnTag: (data: any) => string[]
}

const MyDetailPage: FC<IProps> = ({
  productDetailData,
  handleDownloadedProductAdd,
  isFetchingProductDetail,
  productRelatedEntriesData,
  isFetchingProductRelatedEntries,
  handleEntrySeen,
  handleEntryUnseen,
  handleEntryIgnore,
  licensedProductsData,
  isFetchingLicensedProducts,
  icapIntegrationsData,
  handleProductInterestedAdd,
  handleProductInterestedRemove,
  handleReturnTag
}) => {
  const dispatch = useAppDispatch()
  const productDetail = useTypedSelector(selectProductDetail)
  const utilityList = useTypedSelector(selectProductUtilityDetail)

  const utilitySortList = useMemo(() => {
    if (productDetail?.id === PRODUCT_IDS.ENDPOINT_SECURITY_SDK)
      return handleReorderArray(utilityList, ENDPOINT_SECURITY_SDK_ORDER_ARRAY)

    if (productDetail?.id === PRODUCT_IDS.OPSWAT_NETWALL)
      return handleReorderArray(utilityList, OPSWAT_NETWALL_ORDER_ARRAY)

    return utilityList
  }, [utilityList])

  const utilityProductBreadcrumbs =
    _get(productDetailData, 'slug') !== _get(productDetail, 'slug')
      ? [
          {
            label: _get(productDetailData, 'name', '--')
          }
        ]
      : []

  const breadcrumbsHomeArray = [
    {
      label: 'Home',
      path: homePageURL
    },
    {
      label: isFetchingProductDetail ? '--' : _get(productDetail, 'name', '--'),
      propsTypos: {
        onClick: () => dispatch(saveProductChangeDetail(productDetail))
      }
    },
    ...utilityProductBreadcrumbs
  ]

  const loadingArray = [1, 2, 3, 4]

  const tagType = handleReturnTag(_get(productDetailData, 'tags'))

  return (
    <TemplateSection spacing={4}>
      {utilityProductBreadcrumbs.length ? (
        <Helmet encodeSpecialCharacters={true} titleTemplate="%s - My OPSWAT" defer={false}>
          <title itemProp="name" lang="en">
            {/* productDetail -> parent product */}
            {/* productDetailData -> utility product */}
            {`${_get(productDetailData, 'name', '--')} - ${_get(productDetail, `name`, '--')}`}
          </title>
        </Helmet>
      ) : (
        <></>
      )}
      <Box>
        <TypographyDivider breadcrumbsArray={breadcrumbsHomeArray} />
      </Box>

      <Box sx={{ minHeight: '45vh' }}>
        {isFetchingProductDetail ? (
          <DetailInfoSkeleton />
        ) : (
          <TemplateSection spacing={4}>
            <BoxDetailInfo
              productDetailData={productDetailData}
              handleDownloadedProductAdd={handleDownloadedProductAdd}
              tagType={tagType}
              licensedProductsData={licensedProductsData}
              isFetchingLicensedProducts={isFetchingLicensedProducts}
              icapIntegrationsData={icapIntegrationsData}
              handleProductInterestedAdd={handleProductInterestedAdd}
              handleProductInterestedRemove={handleProductInterestedRemove}
              solutionsData={_map(_get(productDetail, 'solutions', []), item => _get(item, 'name'))}
            />

            {!isFetchingProductDetail && _size(utilitySortList) > 0 && (
              <Box>
                <SwiperSlider
                  title="Modules & Utilities"
                  contents={utilitySortList}
                  ContentComponent={BoxUtilityProduct}
                  propsSwiperSlide={{
                    style: { boxShadow: '0px 2px 8px rgba(61, 74, 104, 0.16)', scale: '0.95' }
                  }}
                  propsComponent={{
                    handleEntrySeen,
                    handleEntryUnseen,
                    handleEntryIgnore
                  }}
                  propsLeftButton={{ leftIcon: <ChevronLeftIcon /> }}
                  propsRightButton={{ rightIcon: <ChevronRightIcon /> }}
                />
              </Box>
            )}

            {!isFetchingProductRelatedEntries &&
              _intersection(tagType, ONPREMISE_OEM_CLOUDBASE_TAGS).length > 0 &&
              _size(productRelatedEntriesData) > 0 && (
                <Box>
                  <SwiperSlider
                    title="Related News"
                    contents={_orderBy(productRelatedEntriesData, ['seen'], ['asc'])}
                    ContentComponent={BoxBlog}
                    propsSwiperSlide={{
                      style: { boxShadow: '0px 2px 8px rgba(61, 74, 104, 0.16)', scale: '0.95' }
                    }}
                    propsComponent={{
                      handleEntrySeen,
                      handleEntryUnseen,
                      handleEntryIgnore
                    }}
                    propsLeftButton={{ leftIcon: <ChevronLeftIcon /> }}
                    propsRightButton={{ rightIcon: <ChevronRightIcon /> }}
                  />
                </Box>
              )}
          </TemplateSection>
        )}
      </Box>
    </TemplateSection>
  )
}

export default MyDetailPage
