
import { useContext } from 'react'
import { useWatch } from 'react-hook-form'

import { Grid } from '@opswat/react-ui'

import SubmitCaseIssueType from '../components/SubmitCaseIssueType'

import { SUBMIT_CASE_ISSUE_TYPE } from '../constants'
import { SupportSubmitCaseContext } from '../interface'

const SubmitCaseIssueTypeStep: React.FC<any> = () => {
  const { control, handleSelectIssueType } = useContext(SupportSubmitCaseContext)

  const issueType = useWatch({ control, name: 'issueType' })

  return (
    <Grid
      container
      columns={{
        xs: 2,
        sm: 3,
        md: 4,
        lg: 6
      }}
      spacing={2}
    >
      {Object.values(SUBMIT_CASE_ISSUE_TYPE).map((item: any) => (
        <Grid item xs={1} key={item}>
          <SubmitCaseIssueType
            id={item}
            name={item}
            isSelected={issueType === item}
            handleSelectIssueType={(id: string) => {
              handleSelectIssueType(id)
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default SubmitCaseIssueTypeStep
