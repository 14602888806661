import { gql } from 'graphql-request'
import { apiGraphql } from 'myopswat-web/src/api/base-query'
import { IPortalPermission } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    portalPermissions: builder.query<IPortalPermission[], null>({
      query: () => ({
        document: gql`
          query {
            portalPermissions {
              id
              name
              codename
              priority
            }
          }
        `
      })
    })
  })
})

export const {
  useLazyPortalPermissionsQuery
} = api