import {
  CertificateIcon,
  CircleWarningIcon,
  FolderConfigurationIcon,
  HelpDeskIcon,
  SettingsIcon,
  ShapesIcon,
  ToolIcon,
  UserGroupIcon
} from '@opswat/react-icon'
import { Button, Typography } from '@opswat/react-ui'

import { SUBMIT_CASE_ISSUE_TYPE } from '../constants'

interface IProps {
  id: string
  name: string
  isSelected: boolean
  handleSelectIssueType: (id: string) => void
}

const SubmitCaseIssueType: React.FC<IProps> = ({ id, name, isSelected, handleSelectIssueType }) => {
  const handleRenderPlatformIcon = (id: string) => {
    switch (id) {
      case SUBMIT_CASE_ISSUE_TYPE.CONFIGURATION:
        return <SettingsIcon size={16} color={isSelected ? '#FFFFFF' : '#000000'} />
      case SUBMIT_CASE_ISSUE_TYPE.INSTALLATION_UPGRADE:
        return <ToolIcon size={16} color={isSelected ? '#FFFFFF' : '#000000'} />
      case SUBMIT_CASE_ISSUE_TYPE.UNEXPECTED_BEHAVIOR:
        return <CircleWarningIcon size={16} color={isSelected ? '#FFFFFF' : '#000000'} />
      case SUBMIT_CASE_ISSUE_TYPE.INTEGRATIONS:
        return <FolderConfigurationIcon size={16} color={isSelected ? '#FFFFFF' : '#000000'} />
      case SUBMIT_CASE_ISSUE_TYPE.QUESTION:
        return <HelpDeskIcon size={16} color={isSelected ? '#FFFFFF' : '#000000'} />
      case SUBMIT_CASE_ISSUE_TYPE.PERFORMANCE:
        return <UserGroupIcon size={16} color={isSelected ? '#FFFFFF' : '#000000'} />
      case SUBMIT_CASE_ISSUE_TYPE.LICENSE_ACTIVATION:
        return <CertificateIcon size={16} color={isSelected ? '#FFFFFF' : '#000000'} />
      case SUBMIT_CASE_ISSUE_TYPE.FEATURE_REQUEST:
        return <FolderConfigurationIcon size={16} color={isSelected ? '#FFFFFF' : '#000000'} />
      case SUBMIT_CASE_ISSUE_TYPE.OTHER:
        return <ShapesIcon size={16} color={isSelected ? '#FFFFFF' : '#000000'} />
      default:
        return <></>
    }
  }

  return (
    <Button
      fullWidth
      sx={{
        height: '100%',
        backgroundColor: isSelected ? '#1D6BFC' : '#E9EAEB',
        borderRadius: '5px',
        padding: '24px 12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        '&:hover': {
          backgroundColor: isSelected ? '#195DDB' : '#D2D4D6'
        }
      }}
      onClick={() => handleSelectIssueType(id)}
    >
      {handleRenderPlatformIcon(id)}
      <Typography
        variant="subtitle2"
        height="20px"
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: isSelected ? '#FFFFFF' : '#000000'
        }}
      >
        {name}
      </Typography>
    </Button>
  )
}

export default SubmitCaseIssueType
