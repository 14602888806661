import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { ButtonLoading, Dialog, DialogActions, DialogContent, DialogTitle } from '@opswat/react-ui'
import { usePortalOrganizationRemoveUserMutation } from 'myopswat-web/src/api/organization'
import useApiErrorHook from 'myopswat-web/src/hooks/useApiErrorHook'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { REMOVE_USER_ERROR_MESSAGES } from '../constants'
import { HighLightEmail } from '../styled'
interface IProps {
  orgUser: any
}

const RemoveUserModal = NiceModal.create(({ orgUser }: IProps) => {
  const { t } = useTranslation()
  const modal = useModal()
  const [organizationRemoveUser, { isLoading }] = usePortalOrganizationRemoveUserMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { showErrorMessage } = useApiErrorHook({ errorMap: REMOVE_USER_ERROR_MESSAGES })
  const onRemove = () => {
    organizationRemoveUser({
      id: orgUser.id
    })
      .unwrap()
      .then(res => {
        if (res.success) {
          enqueueSnackbar(t('removeUserSuccess'), {
            variant: 'success'
          })
          modal.resolve()
          modal.hide()
        } else {
          showErrorMessage(res.errors)
        }
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' })
      })
  }

  const getTitle = (isActive: boolean) => {
    return isActive ? 'Remove user from organization' : `Remove user's invitation`
  }

  const getContent = (isActive: boolean) => {
    return isActive ? (
      <>
        Do you want to remove <HighLightEmail>{orgUser.email}</HighLightEmail> from organization?
      </>
    ) : (
      <>
        Do you want to remove <HighLightEmail>{orgUser.email}</HighLightEmail> invitation?
      </>
    )
  }

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove()
      }}
      maxWidth={'sm'}
    >
      <DialogTitle component={'h3'}>{getTitle(orgUser.isActive)}</DialogTitle>
      <DialogContent>{getContent(orgUser.isActive)}</DialogContent>
      <DialogActions sx={{ p: '1rem' }}>
        <ButtonLoading
          propsButton={{
            variant: 'text',
            color: 'inherit',
            onClick: () => modal.hide(),
            disabled: isLoading
          }}
        >
          Cancel
        </ButtonLoading>
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            onClick: onRemove,
            disabled: isLoading
          }}
          isLoading={isLoading}
        >
          Yes
        </ButtonLoading>
      </DialogActions>
    </Dialog>
  )
})

export default RemoveUserModal
