import { FC } from 'react'

import _get from 'lodash/get'

import { ButtonLoading, Grid, TextField, Typography } from '@opswat/react-ui'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { REGEXS, TEXT_LENGTHS } from '@myopswat/common'

interface IProps {
  productId: string | undefined
  licenseKey: string | undefined
  isLoadingLicenseNoteAdd: boolean
  handleLicenseNoteAdd: (data: any) => void
  setOpenDialog: (data: boolean) => void
  content: string
}

const BoxCommonDialogNote: FC<IProps> = ({
  productId,
  licenseKey,
  setOpenDialog,
  content,
  handleLicenseNoteAdd,
  isLoadingLicenseNoteAdd
}) => {
  const defaultValues = {
    content: content || ''
  }

  const schema = yup.object().shape({
    content: yup
      .string()
      .trim()
      .required('Note cannot be blank')
      .max(TEXT_LENGTHS.TEXT_LENGTH_256, 'Note is limited to 256 characters')
      .test('no-html', 'Please do not enter HTML formatted text.', value => {
        if (value) {
          return !REGEXS.HTML_REGEX.test(value)
        }
        return true
      })
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSuccess = (data: any) => {
    handleLicenseNoteAdd({
      productId: productId || '',
      licenseKey: licenseKey || '',
      content: _get(data, 'content', '')
    })
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          {...register('content')}
          minRows={4}
          maxRows={12}
          multiline
          fullWidth
          sx={{
            '& textarea': {
              resize: 'vertical',
              overflowX: 'hidden'
            }
          }}
          placeholder="Type your note here..."
          size="small"
          error={!!_get(errors, 'content.message', '')}
          helperText={_get(errors, 'content.message', '')}
          required
          onKeyUp={(e: any) => {
            if (_get(e, 'keyCode') === 13) {
              handleSubmit(onSuccess, onFail)()
            }
          }}
          onBlur={() => {
            if (!watch('content')) {
              setError('content', { type: 'required', message: 'Note cannot be blank' })
            } else {
              setError('content', {})
            }
          }}
        />

        <Typography color="textSecondary" variant="body2" sx={{ paddingTop: 2 }}>
          Please do not add any personal information or Confidential information here.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => setOpenDialog(false)
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit(onSuccess, onFail),
                disabled: isLoadingLicenseNoteAdd,
                fullWidth: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoadingLicenseNoteAdd}
            >
              Save
            </ButtonLoading>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BoxCommonDialogNote
