import _get from 'lodash/get'

import { useContext, useMemo } from 'react'
import { useFormState, useWatch } from 'react-hook-form'

import { Box, Button, Grid, Typography } from '@opswat/react-ui'

import SubmitCaseStep from '../components/SubmitCaseStep'
import SubmitCaseIssueTypeStep from '../steps/issueTypeStep'
import SubmitCaseLicenseKeyStep from '../steps/licenseKeyStep'
import SubmitCaseOrganizationStep from '../steps/organizationStep'
import SubmitCasePlatformStep from '../steps/platformStep'
import SubmitCaseProductStep from '../steps/productStep'
import SubmitCaseSeverityStep from '../steps/severityStep'

import { SUBMIT_CASE_OPTION, SUBMIT_CASE_PLATFORM } from '../constants'
import { SupportSubmitCaseContext } from '../interface'

const SubmitCaseFormView = () => {
  const {
    control,
    organizationList,
    completedFormSteps,
    handleSubmit,
    handleCancelSubmitCase,
    handleNextView,
    handleSubmitCase,
    handleGetKBSuggestions,
    hasFullMyCustomerCasesPerm
  } = useContext(SupportSubmitCaseContext)

  const platform = useWatch({ control, name: 'platform' })
  const productId = useWatch({ control, name: 'productId' })
  const subject = useWatch({ control, name: 'subject' })

  const { isValid } = useFormState({ control })

  const showPlatformStep = useMemo(() => {
    let product = productId
    if (typeof productId === 'object') {
      product = productId?.value ?? ''
    }

    return (
      completedFormSteps.has(1) &&
      product &&
      platform !== SUBMIT_CASE_PLATFORM.NOT_APPLICABLE &&
      product !== SUBMIT_CASE_OPTION.OTHER
    )
  }, [completedFormSteps, platform, productId])

  const handleGetSuggestions = () => {
    handleNextView()
    let product = productId
    if (typeof productId === 'object') {
      product = productId.value
    }
    if (product === SUBMIT_CASE_OPTION.OTHER) {
      handleSubmitCase()
    } else {
      handleGetKBSuggestions(subject, product)
        .then((response: any) => {
          if (_get(response, 'results', []).length === 0) {
            handleSubmitCase()
          }
        })
        .catch(error => {
          console.log('log:  error', error)
        })
    }
  }

  const handleNextStep = () => {
    handleSubmit(handleGetSuggestions)()
  }

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      {organizationList.length > 1 && hasFullMyCustomerCasesPerm && (
        <SubmitCaseStep required title="I am logging a ticket on behalf of:">
          <SubmitCaseOrganizationStep />
        </SubmitCaseStep>
      )}
      {completedFormSteps.has(0) && (
        <SubmitCaseStep required title={'Product'}>
          <SubmitCaseProductStep />
        </SubmitCaseStep>
      )}
      {showPlatformStep && (
        <SubmitCaseStep required title="Platform">
          <SubmitCasePlatformStep />
        </SubmitCaseStep>
      )}
      {completedFormSteps.has(2) && (
        <SubmitCaseStep required title="Issue Type">
          <SubmitCaseIssueTypeStep />
        </SubmitCaseStep>
      )}
      {completedFormSteps.has(3) && (
        <SubmitCaseStep required title="Severity">
          <SubmitCaseSeverityStep />
        </SubmitCaseStep>
      )}
      {completedFormSteps.has(4) && (
        <SubmitCaseStep>
          <SubmitCaseLicenseKeyStep />
        </SubmitCaseStep>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Button disableElevation variant="outlined" color="inherit" onClick={handleCancelSubmitCase}>
          Cancel
        </Button>
        {completedFormSteps.size === 5 && (
          <Typography fontSize="11px" color="#485161" fontStyle="italic" fontWeight={400}>
            If you need to upload a support package, you can do so in the next step.
          </Typography>
        )}
        <Button
          disableElevation
          variant="contained"
          color="primary"
          disabled={!isValid || completedFormSteps.size < 5}
          onClick={handleNextStep}
        >
          Continue to Submit Case
        </Button>
      </Box>
    </Grid>
  )
}

export default SubmitCaseFormView
