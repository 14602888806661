import {
  CertificateEmeraldBadgeIcon,
  CertificateGoldBadgeIcon,
  CertificatePlatinumBadgeIcon,
  CertificateSilverBadgeIcon,
  CertificateStandardBadgeIcon,
  CertificateUSPlatinumBadgeIcon,
  EmailIcon,
  SupportTeamIcon
} from '@opswat/react-icon'
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Typography,
  TypographyLineClamp
} from '@opswat/react-ui'
import _get from 'lodash/get'
import { FC, useCallback, useMemo } from 'react'
import { CommonLinearBox } from '../components/CommonLinearBox'
import { SUPPORT_LEVEL } from '../contants'

interface IProps {
  profileData: any
  organizationSupportInfo: any
  isLoading?: boolean
}

export const SupportLevel: FC<IProps> = ({ isLoading, profileData, organizationSupportInfo }) => {
  const { currentOrgId, currentOrganizationName } = useMemo(() => {
    return {
      currentOrgId: _get(profileData, 'currentOrganizationId', ''),
      currentOrganizationName: _get(profileData, 'currentOrganizationName', '')
    }
  }, [profileData])

  const { supportLevel, supportAccountNumber, supportUsers } = useMemo(() => {
    return {
      supportLevel: _get(organizationSupportInfo, 'supportInfo.support_level', ''),
      supportAccountNumber: _get(organizationSupportInfo, 'supportInfo.support_account_number', ''),
      supportUsers: _get(organizationSupportInfo, 'supportUsers', [])
    }
  }, [organizationSupportInfo])

  const renderOpswatTeam = useCallback(() => {
    const supportUsersLength = supportUsers.length
    if (supportUsersLength === 0) return
    return (
      <Paper variant="outlined" sx={{ display: 'flex', flexDirection: 'column' }}>
        <CommonLinearBox customSx={{ border: 'none' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <Typography variant="body1" color="#242424">
              Your OPSWAT Team
            </Typography>
            <SupportTeamIcon size={32} />
          </Box>
        </CommonLinearBox>

        {supportUsers.map((u: any, index: number) => {
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: {
                    xs: '0px 16px',
                    lg: '0px 20px 0px 24px'
                  },
                  height: 66
                }}
              >
                <Box display="flex">
                  <Avatar sx={{ bgcolor: '#1D6BFC' }} aria-label="recipe">
                    {u.name[0]}
                  </Avatar>
                  <Box sx={{ ml: 1 }}>
                    <TypographyLineClamp tooltipValue={u.name} line={1}>
                      {u.name}
                    </TypographyLineClamp>
                    <TypographyLineClamp variant="body2" tooltipValue={u.title} line={1}>
                      {u.title}
                    </TypographyLineClamp>
                  </Box>
                </Box>

                <IconButton onClick={() => sendEmail(u.email)}>
                  <EmailIcon />
                </IconButton>
              </Box>
              {index < supportUsersLength - 1 && <Divider variant="middle" />}
            </>
          )
        })}
      </Paper>
    )
  }, [supportUsers])

  const renderGetStart = useCallback(() => {
    return (
      <Paper variant="outlined">
        <CommonLinearBox customSx={{ border: 'none' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <Typography variant="body1" color="#242424" fontSize='16px'>
              Get Started
            </Typography>
            <SupportTeamIcon size={32} />
          </Box>
        </CommonLinearBox>

        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            padding: {
              xs: '16px 16px 16px 16px',
              lg: '16px 20px 16px 24px'
            }
          }}
        >
          <Typography variant="body1" fontSize='16px'>You are not yet invited to any organization within My OPSWAT</Typography>
          <Typography variant="body2" fontSize='12px'>
            To fully experience support features within My OPSWAT, you'd need to belong to an organization. Please reach
            out to your My OPSWAT Portal admin to send you an invitation. If you don't know who your org admin is or
            need further assistance, please call Phone Support for help.
          </Typography>
        </Box>
      </Paper>
    )
  }, [])

  const getBadgeByLevel = useCallback(
    (level?: any) => {
      if (isLoading)
        return {
          color: '#FFF',
          component: <Skeleton animation="wave" variant="rectangular" width="60px" height="38px" />
        }
      switch (level) {
        case SUPPORT_LEVEL.STANDARD:
          return { color: '#E0DBC080', component: <CertificateStandardBadgeIcon width="60px" height="38px" /> }
        case SUPPORT_LEVEL.SILVER:
          return { color: '#A4A8AE80', component: <CertificateSilverBadgeIcon width="60px" height="38px" /> }
        case SUPPORT_LEVEL.GOLD:
          return { color: '#BA8B0980', component: <CertificateGoldBadgeIcon width="60px" height="38px" /> }
        case SUPPORT_LEVEL.PLATINUM:
          return { color: '#90A3C680', component: <CertificatePlatinumBadgeIcon width="60px" height="38px" /> }
        case SUPPORT_LEVEL.US_PLATINUM:
          return { color: '#90A3C680', component: <CertificateUSPlatinumBadgeIcon width="60px" height="43px" /> }
        case SUPPORT_LEVEL.EMERALD:
          return { color: '#15A87C80', component: <CertificateEmeraldBadgeIcon width="60px" height="38px" /> }
        default:
          return { color: '#E0DBC080', component: <CertificateStandardBadgeIcon width="60px" height="38px" /> }
      }
    },
    [isLoading]
  )

  const sendEmail = useCallback((email?: string) => {
    if (!email) return
    window.location.href = `mailto:${email}`
  }, [])

  const hasSupportLevel = useMemo(() => {
    return Object.values(SUPPORT_LEVEL).includes(supportLevel)
  }, [supportLevel])

  const renderBadgeCard = useCallback(() => {
    const _supportLevel = hasSupportLevel ? supportLevel : SUPPORT_LEVEL.STANDARD
    return (
      <Box
        sx={{
          display: 'flex',
          border: '1px solid #E9EAEB',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: {
            xs: '0px 16px',
            lg: '0px 20px 0px 24px'
          },
          height: 66,
          background: `linear-gradient(45deg, #FFFFFF 90%, ${_get(getBadgeByLevel(_supportLevel), 'color')})`
        }}
      >
        <Box maxWidth="60%">
          <TypographyLineClamp line={1} tooltipValue={currentOrganizationName} variant="body1" color="#242424" fontSize='14px'>
            {currentOrganizationName}
          </TypographyLineClamp>
          {supportAccountNumber && (
            <Typography variant="subtitle2" color="#242424" fontSize='12px'>
              Support Account: {supportAccountNumber}
            </Typography>
          )}
        </Box>

        {_get(getBadgeByLevel(_supportLevel), 'component')}
      </Box>
    )
  }, [hasSupportLevel, supportLevel, supportAccountNumber, currentOrganizationName, getBadgeByLevel])

  const render = () => {
    if (currentOrgId) {
      return [
        <Grid item xs={12}>
          {renderBadgeCard()}
        </Grid>,
        hasSupportLevel && (
          <Grid item xs={12}>
            {renderOpswatTeam()}
          </Grid>
        )
      ]
    } else if (!currentOrgId) {
      return (
        <Grid item xs={12}>
          {renderGetStart()}
        </Grid>
      )
    }
  }

  return (
    <Grid container spacing={2}>
      {render()}
    </Grid>
  )
}
