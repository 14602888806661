import { Box, Skeleton } from '@opswat/react-ui'
import React, { ReactNode } from 'react'

interface CommonLinearBoxProps {
  children: ReactNode
  isLoading?: boolean
  customSx?: any
}

export const CommonLinearBox: React.FC<CommonLinearBoxProps> = ({ children, isLoading, customSx }) => {
  if (isLoading) {
    return <Skeleton animation="wave" variant="rectangular" width="100%" height="66px" />
  }

  return (
    <Box
      sx={{
        height: '66px',
        padding: {
          xs: '0px 16px',
          lg: '0px 20px 0px 24px'
        },
        border: '1px solid #E9EAEB',
        background: 'linear-gradient(263.88deg, #E1E9FE 0%, #FFFFFF 80.38%)',
        ...customSx
      }}
    >
      {children}
    </Box>
  )
}
