import _get from 'lodash/get'

import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormDialog } from '@opswat/react-ui'
import { usePortalOrganizationInviteUserMutation } from 'myopswat-web/src/api/organization'
import { OrganizationInviteUserForm } from 'myopswat-web/src/api/organization/types'
import useApiErrorHook from 'myopswat-web/src/hooks/useApiErrorHook'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { INVITE_USER_ERROR_MESSAGES } from '../constants'
import InviteUserForm from './InviteUserForm'
import { inviteUserSchema } from './InviteUserSchema'
interface IProps {
  organization: any
  roleMap: any
  groups: { [key: string]: string }
}

const defaultInviteUser = {
  email: '',
  role: null,
  groups: []
}

const InviteUserModal = NiceModal.create(({ organization, roleMap, groups }: IProps) => {
  const { t: translate } = useTranslation()
  const [portalOrganizationInviteUser, { isLoading: isInviting }] = usePortalOrganizationInviteUserMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { showErrorMessage } = useApiErrorHook({ errorMap: INVITE_USER_ERROR_MESSAGES })
  const modal = useModal()
  const formRef = useForm<OrganizationInviteUserForm>({
    defaultValues: defaultInviteUser,
    mode: 'onSubmit',
    resolver: yupResolver(inviteUserSchema)
  })
  const { reset, handleSubmit, formState } = formRef

  const { isDirty } = formState

  const onSubmit: SubmitHandler<OrganizationInviteUserForm> = async (formData: OrganizationInviteUserForm) => {
    try {
      const user = {
        email: formData.email,
        ssoRoles: [_get(roleMap, `${formData.role}.ssoId`)],
        role: formData.role
      }
      const res = await portalOrganizationInviteUser({
        user: user
      }).unwrap()
      if (res.success) {
        enqueueSnackbar(translate('inviteUserSuccess'), { variant: 'success' })
        handleResetForm()
        modal.resolve()
        modal.hide()
      } else {
        showErrorMessage(res.errors)
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }
  const onError = (error: any) => {
    console.error('error: ', error)
  }

  const handleResetForm = () => {
    reset(defaultInviteUser)
  }

  const onCancel = () => {
    modal.hide()
    handleResetForm()
  }

  const isLoading = useMemo(() => {
    return isInviting
  }, [isInviting])

  return (
    <FormDialog
      isLoading={isLoading}
      isDisableSubmit={!isDirty}
      title="Invite Users"
      open={modal.visible}
      onClose={() => modal.hide()}
      onExited={() => modal.remove()}
      onCancel={onCancel}
      onConfirm={handleSubmit(onSubmit, onError)}
      confirmBtnLabel="Submit"
      minHeight={300}
      maxWidth={'sm'}
      content={<InviteUserForm roleMap={roleMap} groups={groups} formRef={formRef} />}
    />
  )
})

export default InviteUserModal
