import { FC } from 'react'

import { Box, Grid, Typography } from '@opswat/react-ui'

import CircularLoader from '../../../../assets/images/circular-loader.svg'

const CustomerLicenseLoading: FC<any> = () => {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
          py: 4,
          height: '50vh'
        }}
      >
        <Typography variant="subtitle2">Building your reports...</Typography>
        <Typography variant="subtitle2">This could take a few minutes. Please do not reload this page.</Typography>
        <Box sx={{ marginTop: 2 }}>
          <img src={CircularLoader} width={50} height={50} className="loading-spinner" alt="license-loading-spinner" />
        </Box>
      </Box>
    </Grid>
  )
}

export default CustomerLicenseLoading
