import { useMemo, useState } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { Checkbox, Divider, FormControl, FormControlLabel, Grid, TextFieldSearch, Typography } from '@opswat/react-ui'

interface IProps {
  label: string
  placeholder?: string
  valueOptions: any[]
  value?: string[]
  setValue: (ids: string[]) => void
  allowSearch?: boolean
}

const MultiValueFilters = ({ valueOptions, value, setValue, label, placeholder, allowSearch }: IProps) => {
  const [valueKeyword, setValueKeyword] = useState<string>('')

  const filteredOptions = useMemo(() => {
    return valueOptions.filter((item: any) => {
      return item.name.toLowerCase().includes(valueKeyword.toLowerCase())
    })
  }, [valueOptions, valueKeyword])

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} spacing={1} alignItems="center">
        <Grid item xs={1.5}>
          <Typography variant="subtitle1">{label}</Typography>
        </Grid>

        {allowSearch && (
          <Grid item xs={10.5} gap={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <TextFieldSearch
              value={valueKeyword}
              placeholder={placeholder ?? 'Type to search ...'}
              onChange={(e: any) => {
                if (_get(e, 'target')) {
                  setValueKeyword(e.target.value)
                }
              }}
              onClearText={() => setValueKeyword('')}
              sx={{
                minHeight: 'auto'
              }}
            />
          </Grid>
        )}
      </Grid>

      <Grid xs={12} container paddingTop={filteredOptions.length > 0 ? 1 : 2} padding={1}>
        <FormControl sx={{ width: '100%' }}>
          <Grid xs={12} item container spacing={1.5}>
            {_map(filteredOptions, (item: any, idx: any) => {
              return (
                <Grid item xs={6} key={idx.toString()}>
                  <FormControlLabel
                    key={idx.toString()}
                    control={
                      <Checkbox
                        checked={value?.includes(item.name)}
                        onChange={(event: any) => {
                          const _value = value || []
                          if (event.target.checked) {
                            setValue([..._value, item.name])
                          } else {
                            setValue(_value.filter(v => v !== item.name))
                          }
                        }}
                      />
                    }
                    label={<Typography variant="subtitle2">{item.name}</Typography>}
                  />
                </Grid>
              )
            })}
          </Grid>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#D2D4D6' }} />
      </Grid>
    </Grid>
  )
}

export default MultiValueFilters
