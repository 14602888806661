import { DialogAdvanced } from '@opswat/react-ui'
import _get from 'lodash/get'
import React from 'react'
import ReferralSourceForm from '../Form/ReferralSourceForm'
import { useTypedSelector } from '../../store'
import { selectDialogs } from '../../containers/LayoutContainer/layoutContainerSlice'
import { DIALOGS_WEB } from '../../constants/dialogs'
import { DIALOGS } from '@myopswat/common'

const DialogReferralSource = () => {
  const dialogType = useTypedSelector(selectDialogs)
  const openPersonalizeDialog = _get(dialogType, DIALOGS.PERSONAL_INFORMATION, false)
  const openNPSDialog = _get(dialogType, DIALOGS_WEB.NPS_SURVEY, false)
  return (
    <DialogAdvanced
      open={_get(dialogType, DIALOGS_WEB.REFERRAL_SOURCE, false) && !openPersonalizeDialog && !openNPSDialog}
      title={'How did you hear about OPSWAT?'}
      content={<ReferralSourceForm />}
      dialogProps={{
        sx: {
          '& .MuiPaper-root': {
            height: 'auto',
            maxWidth: '440px'
          }
        }
      }}
    />
  )
}

export default DialogReferralSource
