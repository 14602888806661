import { useContext } from 'react'

import SubmitCaseFormView from './views/formView'
import SubmitCaseLoadingView from './views/loadingView'
import SubmitCaseSuccessView from './views/successView'
import SubmitCaseSuggestionView from './views/suggestionView'

import { SupportSubmitCaseContext } from './interface'

const SupportSubmitCasePage = () => {
  const { currentView } = useContext(SupportSubmitCaseContext)

  switch (currentView) {
    case 0:
      return <SubmitCaseFormView />
    case 1:
      return <SubmitCaseSuggestionView />
    case 2:
      return <SubmitCaseLoadingView />
    case 3:
      return <SubmitCaseSuccessView />
    default:
      return <></>
  }
}

export default SupportSubmitCasePage
