import { useTypedSelector } from '../store'
import { useMemo } from 'react'
import _get from 'lodash/get'

const useGetPortalPermissions = () => {
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const permissions = useMemo(() => {
    // Get user permission from Profile
    const userPortalPermissions: string[] = _get(profileData, 'portalPermissions', [])
    return userPortalPermissions
  }, [profileData])

  return {
    permissions
  }
}

export { useGetPortalPermissions as default }
