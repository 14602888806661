import _differenceInDays from 'date-fns/differenceInDays'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isInteger from 'lodash/isInteger'
import _isNil from 'lodash/isNil'
import _startCase from 'lodash/startCase'

import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { formatDatetime } from '@opswat/react-core'
import { Grid, Typography, TypographyDivider, TypographyLineClamp } from '@opswat/react-ui'

import { useLazyBasicProductsQuery } from 'myopswat-web/src/api/product'
import { PRODUCT_IDS } from 'myopswat-web/src/constants/product-ids'
import { selectOrganizations } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { homePageURL } from 'myopswat-web/src/routes'
import { useTypedSelector } from 'myopswat-web/src/store'

import CloudServiceItem from '../../../components/CloudServiceItem'
import CloudServiceLabel from '../../../components/CloudServiceLabel'

interface ProductNameType {
  id: string
  name: string
}

const CloudServicesSection: FC<any> = () => {
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data) || {}
  const organizations = useTypedSelector(selectOrganizations)

  const [isLoading, setIsLoading] = useState(true)
  const [getBasicProducts, { data: productNameData }] = useLazyBasicProductsQuery()

  const maItem = productNameData?.find((item: ProductNameType) => item?.id === PRODUCT_IDS.MD_IT_ACCESS)
  const mdcItem = productNameData?.find((item: ProductNameType) => item?.id === PRODUCT_IDS.MD_CLOUD)

  const isDisplayMdc = useMemo(() => {
    const accountInfo = _get(profileData, 'accountInfo')
    if (!accountInfo) return false

    if (
      (_get(accountInfo, 'mdcLicenseManageBy') === 'organization' && !_isEmpty(_get(accountInfo, 'mdc'))) ||
      _get(accountInfo, 'mdcLicenseManageBy') === 'user'
    ) {
      return true
    }
    return false
  }, [profileData])

  const currentOrganizationName = useMemo(() => {
    if (_isEmpty(organizations)) return ''

    const org: any = _find(organizations, (item: any) => item?.id === _get(profileData, 'currentOrganizationId'))
    if (!org) return ''

    return org.name
  }, [organizations, profileData])

  const handleCheckStatus = useCallback((date: any) => {
    if (_isNil(date)) return '--'

    if (date >= 90)
      return (
        <Typography component="span" fontSize="12px" lineHeight="20px" fontWeight={400} color="success.dark">
          Active
        </Typography>
      )
    if (date < 90 && date >= 30)
      return (
        <Typography component="span" fontSize="12px" lineHeight="20px" fontWeight={400} color="warning.dark">
          Expiring
        </Typography>
      )
    if (date < 30 && date >= 0)
      return (
        <Typography component="span" fontSize="12px" lineHeight="20px" fontWeight={400} color="error.dark">
          Expiring
        </Typography>
      )
    if (date < 0)
      return (
        <Typography component="span" fontSize="12px" lineHeight="20px" fontWeight={400} color="error.dark">
          Expired
        </Typography>
      )

    return '--'
  }, [])

  const handleShortenNumber = useCallback((value: number) => {
    if (!value) {
      return value
    }
    const number = Number(value)
    const SYMBOLS = ['', 'K', 'M', 'G', 'T', 'P', 'E']
    const tier = (Math.log10(Math.abs(number)) / 3) | 0
    if (tier == 0) {
      return number
    }

    const suffix = SYMBOLS[tier]
    const scale = Math.pow(10, tier * 3)
    const scaled = number / scale
    return scaled.toFixed(_isInteger(scaled) ? 0 : 1) + suffix
  }, [])

  const getLicenseData = useCallback((data: any, key: string) => {
    const value = _get(data, key)
    if ((value instanceof String && _isEmpty(value)) || value === null) return '--'
    return handleShortenNumber(value)
  }, [])

  useEffect(() => {
    if (profileData) {
      setIsLoading(false)
    }
  }, [profileData])

  useEffect(() => {
    getBasicProducts({
      q: '',
      tags: []
    })
  }, [isLoading])

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <TypographyDivider
          label="Cloud Services"
          propsTypo={{
            component: 'label',
            variant: 'body1'
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CloudServiceItem
          title={mdcItem?.name ?? 'MetaDefender Cloud'}
          link={`${homePageURL}/metadefender-cloud`}
          emptyInfo={
            !isDisplayMdc && (
              <Typography fontSize="12px" lineHeight="20px" fontWeight={400}>
                {`Your license is managed by ${
                  currentOrganizationName || 'your organization'
                }. You must have Full Access permission on the My Licenses to view this information. Please contact your Organization Admin for support.`}
              </Typography>
            )
          }
          leftInfo={
            <>
              <CloudServiceLabel
                title={'Prevention'}
                value={getLicenseData(profileData, 'accountInfo.mdc.mdcPreventionApi')}
                unit={{
                  singular: 'request/day',
                  plural: 'requests/day'
                }}
              />
              <CloudServiceLabel
                title={'Reputation'}
                value={getLicenseData(profileData, 'accountInfo.mdc.mdcReputationApi')}
                unit={{
                  singular: 'request/day',
                  plural: 'requests/day'
                }}
              />
              <CloudServiceLabel
                title={'Sandbox'}
                value={getLicenseData(profileData, 'accountInfo.mdc.sandboxApiLimit')}
                unit={{
                  singular: 'request/day',
                  plural: 'requests/day'
                }}
              />
              <CloudServiceLabel
                title={'Analysis'}
                value={getLicenseData(profileData, 'accountInfo.mdc.mdcAnalysisLimit')}
                unit={{
                  singular: 'request/day',
                  plural: 'requests/day'
                }}
              />
              <CloudServiceLabel
                title={'Feed'}
                value={getLicenseData(profileData, 'accountInfo.mdc.mdcFeedLimit')}
                unit={{
                  singular: 'hash/day',
                  plural: 'hashes/day'
                }}
              />
              <CloudServiceLabel
                title={'Throttle'}
                value={getLicenseData(profileData, 'accountInfo.mdc.mdCloudThrottle')}
              />
            </>
          }
          rightInfo={
            <>
              <Typography component="p" fontSize="12px" lineHeight="20px" fontWeight={500}>
                API Key
              </Typography>
              {isDisplayMdc ? (
                <Typography fontSize="12px" lineHeight="20px" fontWeight={400} sx={{ wordBreak: 'break-all' }}>
                  {_get(profileData, 'accountInfo.mdc.mdcApiKey', '--')}
                </Typography>
              ) : (
                <TypographyLineClamp
                  line={2}
                  tooltipPlacement="top"
                  tooltipValue={`Your license is managed by ${currentOrganizationName}`}
                  tooltipProps={{
                    tooltip: {
                      sx: {
                        color: '#1B273C',
                        backgroundColor: '#E9EAEB',
                        whiteSpace: 'pre-line'
                      }
                    },
                    arrow: {
                      sx: {
                        color: '#E9EAEB'
                      }
                    }
                  }}
                  fontSize="12px"
                  lineHeight="20px"
                  fontWeight={400}
                >
                  Your license is managed by {currentOrganizationName}
                </TypographyLineClamp>
              )}
              <CloudServiceLabel
                isNotNumeric
                title={'Type'}
                value=""
                unit={
                  _get(profileData, 'accountInfo.mdc.mdCloudLicenseType')
                    ? _startCase(_get(profileData, 'accountInfo.mdc.mdCloudLicenseType'))
                    : '--'
                }
              />
              <CloudServiceLabel
                isNotNumeric
                title={'Expiration Date'}
                value=""
                unit={formatDatetime(_get(profileData, 'accountInfo.mdc.mdcExpiration'), 'MMM d, yyyy')}
              />
              <CloudServiceLabel
                isNotNumeric
                title={'Status'}
                value=""
                unit={handleCheckStatus(
                  _differenceInDays(new Date(_get(profileData, 'accountInfo.mdc.mdcExpiration', '')), new Date())
                )}
              />
            </>
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CloudServiceItem
          title={maItem?.name ?? 'MetaDefender IT Access'}
          link={`${homePageURL}/metadefender-it-access`}
          leftInfo={
            <CloudServiceLabel
              title={'Number of Devices'}
              value={_get(profileData, 'accountInfo.ma.maLicMaxDevices') || '--'}
            />
          }
          rightInfo={
            <>
              <Typography component="p" fontSize="12px" lineHeight="20px" fontWeight={500}>
                API Key
              </Typography>
              <Typography fontSize="12px" lineHeight="20px" fontWeight={400} sx={{ wordBreak: 'break-all' }}>
                {_get(profileData, 'accountInfo.ma.maApikey', '--')}
              </Typography>
              <CloudServiceLabel title="Type" value="" hidden />
              <CloudServiceLabel
                title={'Expiration Date'}
                value={_get(profileData, 'accountInfo.ma.maExpirationDate') || '--'}
              />
              <CloudServiceLabel
                isNotNumeric
                title={'Status'}
                value={handleCheckStatus(
                  _differenceInDays(new Date(_get(profileData, 'accountInfo.ma.maExpirationDate', '')), new Date())
                )}
              />
            </>
          }
        />
      </Grid>
    </Grid>
  )
}

export default CloudServicesSection
