import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ISupportCaseCreateV2Input } from 'myopswat-web/src/api/support/submit-case/types'

import type { RootState } from '../../store'

type SupportServicesState = {
  currentChatbotTopic: string
  submitCaseSuccessful: boolean
  submitCaseData: ISupportCaseCreateV2Input
  isDirtySubmitCase: boolean
}

const initialState: SupportServicesState = {
  currentChatbotTopic: '',
  submitCaseSuccessful: false,
  submitCaseData: {} as ISupportCaseCreateV2Input,
  isDirtySubmitCase: false
}

const supportServicesSlice = createSlice({
  name: 'supportServices',
  initialState,
  reducers: {
    setCurrentChatbotTopic: (state, action: PayloadAction<string>) => {
      state.currentChatbotTopic = action.payload || ''
    },
    setSubmitCaseSuccessful: (state, action: PayloadAction<boolean>) => {
      state.submitCaseSuccessful = action.payload || false
    },
    setSubmitCaseData: (state, action: PayloadAction<ISupportCaseCreateV2Input>) => {
      state.submitCaseData = action.payload || {}
    },
    setIsDirtySubmitCase: (state, action: PayloadAction<boolean>) => {
      state.isDirtySubmitCase = action.payload || false
    }
  }
})

export const { setCurrentChatbotTopic, setSubmitCaseSuccessful, setSubmitCaseData, setIsDirtySubmitCase } =
  supportServicesSlice.actions

export default supportServicesSlice.reducer

export const selectCurrentChatbotTopic = (state: RootState) => state.supportServices.currentChatbotTopic
export const selectSubmitCaseSuccessful = (state: RootState) => state.supportServices.submitCaseSuccessful
export const selectSubmitCaseData = (state: RootState) => state.supportServices.submitCaseData
export const selectIsDirtySubmitCase = (state: RootState) => state.supportServices.isDirtySubmitCase
