import { FC } from 'react'
import { Link } from 'react-router-dom'

import { TextLinkIcon } from '@opswat/react-icon'
import { Box, Grid, Typography } from '@opswat/react-ui'

interface IProps {
  title: string
  link: string
  leftInfo: React.ReactNode
  rightInfo: React.ReactNode
  emptyInfo?: React.ReactNode
}

const CloudServiceItem: FC<IProps> = ({ title, link, leftInfo, rightInfo, emptyInfo }) => {
  return (
    <Box
      sx={{
        boxShadow: '0 2px 8px 0 rgba(10, 36, 86, 0.15)',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          padding: '10px 20px',
          background:
            'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(253,253,253,1) 25%, rgba(244,246,250,1) 70%, rgba(231,235,245,1) 100%)',
          borderBottom: '1px solid #E9EAEB',
          height: {
            xs: 'auto',
            md: '40px'
          }
        }}
      >
        <Typography
          component={Link}
          to={link}
          sx={{ display: 'flex', alignItems: 'center', gap: 0.5, textDecoration: 'none', width: 'fit-content' }}
        >
          <Typography component="span" variant="body1">
            {title}
          </Typography>
          <TextLinkIcon size={16} />
        </Typography>
      </Box>
      <Grid container sx={{ padding: '20px', flex: 1 }} columnSpacing={2.5}>
        {emptyInfo ? (
          <Grid item container xs={12}>
            {emptyInfo}
          </Grid>
        ) : (
          <>
            <Grid item container xs={12} sm={6} rowSpacing={0.5}>
              {leftInfo}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              {rightInfo}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default CloudServiceItem
