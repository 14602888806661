import { CSSProperties, FC } from 'react'

import { Tooltip, Typography, TypographyLineClamp } from '@opswat/react-ui'

interface IProps {
  mainLabel: string
  subLabel: string
  sx?: CSSProperties
}

const TooltipSubOrgLabel: FC<IProps> = ({ mainLabel, subLabel, sx }) => {
  return mainLabel ? (
    <Tooltip
      arrow
      placement="top"
      title={`${mainLabel} (${subLabel})`}
      componentsProps={{
        tooltip: {
          sx: {
            color: '#1B273C',
            backgroundColor: '#E9EAEB',
            whiteSpace: 'pre-line'
          }
        },
        arrow: {
          sx: {
            color: '#E9EAEB'
          }
        }
      }}
    >
      <Typography
        style={{
          wordBreak: 'break-all',
          display: '-webkit-box',
          WebkitLineClamp: 1,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...sx
        }}
      >
        {mainLabel}
        <span>{` (${subLabel?.substring(0, 5)}...)`}</span>
      </Typography>
    </Tooltip>
  ) : (
    <TypographyLineClamp
      line={1}
      variant="body2"
      tooltipValue={subLabel}
      tooltipPlacement="top"
      tooltipProps={{
        tooltip: {
          sx: {
            color: '#1B273C',
            backgroundColor: '#E9EAEB',
            whiteSpace: 'pre-line'
          }
        },
        arrow: {
          sx: {
            color: '#E9EAEB'
          }
        }
      }}
      sx={sx}
    >
      {subLabel}
    </TypographyLineClamp>
  )
}

export default TooltipSubOrgLabel
