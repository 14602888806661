import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    addUserActivity: builder.mutation<any, any>({
      query: input => ({
        document: gql`
          mutation ($input: UserActivityCreateInput!) {
            addUserActivity(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    })
  })
})

export const { useAddUserActivityMutation } = api
