import { FC, useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import _map from 'lodash/map'

import { SolidDownIcon } from '@opswat/react-icon'
import { Button, CheckboxWithLabel, Divider, DropdownFilter, Grid, Typography } from '@opswat/react-ui'

import { LICENSE_STATUS_OPTIONS } from '../../../MyLicensesPage/constants'

interface IProps {
  currentFilters: any
  setCurrentFilters: (data: any) => void
}

const CustomerCloudServicesFilter: FC<IProps> = ({ currentFilters, setCurrentFilters }) => {
  const { control, handleSubmit, reset, setValue } = useForm<any>({
    defaultValues: {
      licenseStatuses: []
    }
  })

  const handleSetCurrentFilters = useCallback((filters: any) => {
    Object.entries(filters).forEach(([key, value]: any) => {
      setValue(key, value)
    })
  }, [])

  const handleMapCheckbox = useCallback((checked: boolean, currentValue: any[], item: any) => {
    return checked ? [...currentValue, item.value] : currentValue.filter((i: any) => i !== item.value)
  }, [])

  const onSuccess = (data: any) => {
    setCurrentFilters(data)
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  const renderStatusCheckbox = useCallback(
    (item: any, idx: number) => {
      return (
        <Grid item xs={4} key={idx.toString()}>
          <Controller
            name="licenseStatuses"
            control={control}
            render={({ field: { value, onChange } }: any) => (
              <CheckboxWithLabel
                label={
                  <Typography variant="subtitle2" marginTop="2px">
                    {item.label}
                  </Typography>
                }
                checkboxProps={{
                  checked: value.includes(item.value),
                  onChange: (e: any) => {
                    onChange(handleMapCheckbox(e.target.checked, value, item))
                  }
                }}
                restProps={{
                  sx: {
                    marginLeft: '-6px'
                  }
                }}
              />
            )}
          />
        </Grid>
      )
    },
    [handleMapCheckbox]
  )

  useEffect(() => {
    handleSetCurrentFilters(currentFilters)
  }, [currentFilters])

  return (
    <DropdownFilter
      resetOnSubmit={false}
      propsPopper={{
        placement: 'bottom-start'
      }}
      propsPaper={{
        style: {
          width: '600px'
        }
      }}
      button={
        <Button color="inherit" variant="text" endIcon={<SolidDownIcon />}>
          Filter
        </Button>
      }
      content={
        <Grid container spacing={2}>
          {/* status filter */}
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Status</Typography>
            </Grid>
            {_map(LICENSE_STATUS_OPTIONS, (item: any, idx: any) => renderStatusCheckbox(item, idx))}
            <Grid item xs={12}>
              <Divider sx={{ borderColor: '#D2D4D6' }} />
            </Grid>
          </Grid>
        </Grid>
      }
      onResetFilter={() => {
        reset({
          licenseStatuses: []
        })
      }}
      onResetChange={() => {
        handleSetCurrentFilters(currentFilters)
      }}
      onApply={() => {
        handleSubmit(onSuccess, onFail)()
      }}
    />
  )
}

export default CustomerCloudServicesFilter
