import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { ExternalLinkIcon, StarIcon } from '@opswat/react-icon'
import {
  Box,
  Button,
  Grid,
  TabAdvanced,
  TableAdvanced,
  Typography,
  TypographyLineClamp,
  TypographyLink
} from '@opswat/react-ui'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _range from 'lodash/range'
import _some from 'lodash/some'

import {
  useLazyOrganizationCasesV2Query,
  useLazyOrganizationSupportCaseProductsQuery,
  useLazySupportExtendCaseViewInfoQuery
} from 'myopswat-web/src/api/support'
import { ISupportCaseV2Filters } from 'myopswat-web/src/api/support/types'
import CircularLoader from 'myopswat-web/src/assets/images/circular-loader.svg'
import DialogCSAT from 'myopswat-web/src/components/Dialog/DialogCSAT'
import { PAGE_DEFAULT, PAGE_SIZES_DEFAULT } from 'myopswat-web/src/constants'
import { useDialog } from 'myopswat-web/src/hooks/useDialog'
import { supportServicesPageAllCasesURL } from 'myopswat-web/src/routes'
import { useTypedSelector } from 'myopswat-web/src/store'
import { useNavigate } from 'react-router-dom'
import useHasPermissions from '../../../../hooks/useHasPermissions'
import CasesFilters from '../components/CasesFilters'
import { CASE_TABLE_TABS, SupportCaseTypes } from '../constant'

interface IProps {
  hasPreferredPartnerAsCustomer?: boolean
  isMiniTable?: boolean
  triggerReload?: () => void
}

const SupportServiceCaseTable: React.FC<IProps> = ({ isMiniTable, triggerReload }) => {
  const navigate = useNavigate()
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const firstCheck = useRef(true)
  const [hasCustomerCase, setHasCustomerCase] = useState(false)
  const permissions = _get(profileData, 'portalPermissions', [])
  const hasFullMyCustomerCasesPerm = useHasPermissions({
    targetPerms: ['full_my_customer_cases'],
    userPerms: permissions
  })

  const { isOpen, openDialog, closeDialog } = useDialog()
  const [selectedCase, setSelectedCase] = useState<any>({})
  const [pageTitle, setPageTitle] = useState<string>('')

  const userId = useMemo(() => _get(profileData, 'id') ?? '', [profileData])
  const userContactId = useMemo(() => _get(profileData, 'sfdcContactId') ?? '', [profileData])
  const hasOrganization = useMemo(() => {
    if (profileData)
      return !_isEmpty(_get(profileData, 'currentOrganizationId') || _get(profileData, 'usingOrganizationId'))
    return false
  }, [profileData])

  const [getSupportCases, { data: casesData, isFetching }] = useLazyOrganizationCasesV2Query()
  const [getSupportProducts, { data: supportProducts }] = useLazyOrganizationSupportCaseProductsQuery()
  const [getOrganizationAccessCaseInfo, { data: orgCaseAccessInfo = [], isLoading: isOrgAccessLoading }] =
    useLazySupportExtendCaseViewInfoQuery()

  useEffect(() => {
    handleRefreshOrgAccessInfo()
  }, [])

  useEffect(() => {
    const currentOrganizationId = _get(profileData, 'currentOrganizationId') ?? ''
    getSupportProducts(currentOrganizationId)
  }, [profileData])

  const [activeTab, setActiveTab] = useState(0)

  const handleRefreshOrgAccessInfo = useCallback(async () => {
    await getOrganizationAccessCaseInfo({})
  }, [])

  const extendCaseView = useMemo(() => {
    return _some(orgCaseAccessInfo, item => item.contactId === userContactId && item.orgCaseAccess === true)
  }, [orgCaseAccessInfo, userContactId])

  const [filters, setFilters] = useState<ISupportCaseV2Filters>({
    caseType: _get(CASE_TABLE_TABS, `${activeTab}.value`) ?? SupportCaseTypes.MY_CASES,
    checkCustomerCases: true
  })

  const DEFAULT_PAGE_INFO = useMemo(() => {
    return {
      page: 0,
      pageSize: isMiniTable ? 5 : 25
    }
  }, [isMiniTable])

  const [pageInfo, setPageInfo] = useState<{ page: number; pageSize: number }>(DEFAULT_PAGE_INFO)

  const caseType = useMemo(() => _get(filters, 'caseType'), [filters])
  const tabArrays = useMemo(() => {
    return CASE_TABLE_TABS.filter(tab => {
      // Organization Case list will be displayed for the user if the user has extended case view permission and user is in an organization
      if (tab.value === SupportCaseTypes.ORG_CASES && (!extendCaseView || !hasOrganization)) {
        return false
      }

      // The Customer Case list will be displayed if my Organization has at least one linked customer with a “Preferred Partner” relationship
      if (
        tab.value === SupportCaseTypes.CUSTOMER_CASES &&
        (!hasFullMyCustomerCasesPerm || !hasOrganization || !hasCustomerCase)
      ) {
        return false
      }

      return true
    })
  }, [extendCaseView, hasOrganization, hasCustomerCase])

  const handleGetSupportCases = async () => {
    try {
      if (!_get(filters, 'caseType')) return

      await getSupportCases({
        pageInfo,
        filters: { ...filters, checkCustomerCases: firstCheck.current }
      }).unwrap()

      firstCheck.current = false
    } catch (err) {
      console.error(err)
    }
  }

  const handlePagination = useCallback(
    (pageNumber: number, pageSize: number) => {
      if (pageSize !== pageInfo.pageSize) {
        pageNumber = PAGE_DEFAULT
      }
      setPageInfo({ page: pageNumber, pageSize })
    },
    [pageInfo]
  )

  const containerRef = useRef<any>()

  const getColumnStyles = useCallback((widthPercent: number) => {
    const currentWidth = containerRef.current?.offsetWidth
    return {
      minWidth: `${(widthPercent / 100) * currentWidth}px`,
      width: `${(widthPercent / 100) * currentWidth}px`
    }
  }, [])

  const getColumnValueAsLink = useCallback(
    (data: any, field: string) => (
      <TypographyLink
        color="primary"
        fontWeight="400"
        underline="hover"
        href={`${process.env.REACT_APP_OPSWAT_GO_URL}/s/case/${data.id}`}
      >
        {_get(data, field, '--')}
      </TypographyLink>
    ),
    []
  )

  const getColumnValue = useCallback((data: any, field: string) => {
    const value = _get(data, field) || '--'
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        tooltipPlacement="top"
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const getDateColumn = useCallback((data: any, field: string) => {
    const value = _get(data, field)
    if (!value) return <Typography>{'--'}</Typography>

    const dateValue = value ? formatDatetime(value, DATE_FORMATS.DATE) : '--'
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={dateValue}>
        {dateValue}
      </TypographyLineClamp>
    )
  }, [])

  const getRatingColumn = useCallback(
    (data: any) => {
      const handleClick = () => {
        setSelectedCase(data)
        openDialog()
      }

      const isMyCase = _get(data, 'contactId') === userContactId || activeTab === 0
      const status = _get(data, 'status')
      if (status !== 'Closed') {
        return <Typography textAlign="right">{'--'}</Typography>
      }

      const ratingValue = _get(data, 'satisfactoryNumber')
      if (ratingValue === null) {
        return isMyCase ? (
          <Typography textAlign="right" sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleClick}>
            {'Rate'}
          </Typography>
        ) : (
          <Typography textAlign="right">{'--'}</Typography>
        )
      }

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '23px',
            cursor: isMyCase ? 'pointer' : 'default'
          }}
          onClick={isMyCase ? handleClick : undefined}
        >
          <StarIcon color="#FDBD0D" fill="#FDBD0D" />
          <Typography textAlign="center" sx={{ textDecoration: isMyCase ? 'underline' : 'unset', minWidth: '24px' }}>
            {ratingValue}
          </Typography>
        </Box>
      )
    },
    [userId, activeTab, userContactId]
  )

  const getMyCasesColumns = useCallback(() => {
    if (!isMiniTable) {
      return [
        {
          header: 'Case No.',
          body: (data: any) => getColumnValueAsLink(data, 'caseNumber'),
          style: getColumnStyles(8)
        },
        {
          header: 'Product',
          body: (data: any) => getColumnValue(data, 'subCategory'),
          style: getColumnStyles(17)
        },
        {
          header: 'Subject',
          body: (data: any) => getColumnValue(data, 'subject'),
          style: getColumnStyles(25)
        },
        {
          header: 'Severity Level',
          body: (data: any) => getColumnValue(data, 'severity'),
          style: getColumnStyles(17)
        },
        {
          header: 'Created Date',
          body: (data: any) => getDateColumn(data, 'createdDate'),
          style: getColumnStyles(8)
        },
        {
          header: 'Last Updated',
          body: (data: any) => getDateColumn(data, 'lastModifiedDate'),
          style: getColumnStyles(8)
        },
        {
          header: 'Status',
          body: (data: any) => getColumnValue(data, 'status'),
          style: getColumnStyles(10)
        },
        {
          header: 'Satisfaction',
          body: getRatingColumn,
          style: { minWidth: 'auto', width: '100%', textAlign: 'right' }
        }
      ]
    }

    return [
      {
        header: 'Case No.',
        body: (data: any) => getColumnValueAsLink(data, 'caseNumber'),
        style: getColumnStyles(10)
      },
      {
        header: 'Product',
        body: (data: any) => getColumnValue(data, 'subCategory'),
        style: getColumnStyles(18)
      },
      {
        header: 'Subject',
        body: (data: any) => getColumnValue(data, 'subject'),
        style: getColumnStyles(35)
      },
      {
        header: 'Last Updated',
        body: (data: any) => getDateColumn(data, 'lastModifiedDate'),
        style: getColumnStyles(12)
      },
      {
        header: 'Status',
        body: (data: any) => getColumnValue(data, 'status'),
        style: getColumnStyles(15)
      },
      {
        header: 'Satisfaction',
        body: getRatingColumn,
        style: { minWidth: 'auto', width: '100%', textAlign: 'right' }
      }
    ]
  }, [isMiniTable])

  const getOtherCasesColumns = useCallback(() => {
    const additionalCol = {
      header: caseType === SupportCaseTypes.ORG_CASES ? 'Contact Name' : 'Customer',
      body: (data: any) =>
        getColumnValue(data, caseType === SupportCaseTypes.ORG_CASES ? 'contactName' : 'endCustomerName')
    }

    if (!isMiniTable) {
      return [
        {
          header: 'Case No.',
          body: (data: any) => getColumnValueAsLink(data, 'caseNumber'),
          style: getColumnStyles(8)
        },
        {
          header: additionalCol.header,
          body: additionalCol.body,
          style: getColumnStyles(10)
        },
        {
          header: 'Product',
          body: (data: any) => getColumnValue(data, 'subCategory'),
          style: getColumnStyles(15)
        },
        {
          header: 'Subject',
          body: (data: any) => getColumnValue(data, 'subject'),
          style: getColumnStyles(20)
        },
        {
          header: 'Severity Level',
          body: (data: any) => getColumnValue(data, 'severity'),
          style: getColumnStyles(15)
        },
        {
          header: 'Created Date',
          body: (data: any) => getDateColumn(data, 'createdDate'),
          style: getColumnStyles(8)
        },
        {
          header: 'Last Updated',
          body: (data: any) => getDateColumn(data, 'lastModifiedDate'),
          style: getColumnStyles(8)
        },
        {
          header: 'Status',
          body: (data: any) => getColumnValue(data, 'status'),
          style: getColumnStyles(10)
        },
        {
          header: 'Satisfaction',
          body: getRatingColumn,
          style: { minWidth: 'auto', width: '100%', textAlign: 'right' }
        }
      ]
    }

    return [
      {
        header: 'Case No.',
        body: (data: any) => getColumnValueAsLink(data, 'caseNumber'),
        style: getColumnStyles(8)
      },
      {
        header: additionalCol.header,
        body: additionalCol.body,
        style: getColumnStyles(15)
      },
      {
        header: 'Product',
        body: (data: any) => getColumnValue(data, 'subCategory'),
        style: getColumnStyles(15)
      },
      {
        header: 'Subject',
        body: (data: any) => getColumnValue(data, 'subject'),
        style: getColumnStyles(25)
      },
      {
        header: 'Last Updated',
        body: (data: any) => getDateColumn(data, 'lastModifiedDate'),
        style: getColumnStyles(12)
      },
      {
        header: 'Status',
        body: (data: any) => getColumnValue(data, 'status'),
        style: getColumnStyles(15)
      },
      {
        header: 'Satisfaction',
        body: getRatingColumn,
        style: { minWidth: 'auto', width: '100%', textAlign: 'right' }
      }
    ]
  }, [isMiniTable, caseType])

  const tableColumns = useMemo(() => {
    switch (caseType) {
      case SupportCaseTypes.MY_CASES:
        return getMyCasesColumns()
      case SupportCaseTypes.ORG_CASES:
        return getOtherCasesColumns()
      case SupportCaseTypes.CUSTOMER_CASES:
        return getOtherCasesColumns()
      default:
        return []
    }
  }, [caseType, isFetching, getColumnStyles])

  useEffect(() => {
    // Filters will be cleared when the tab is changed
    setFilters({
      caseType: _get(tabArrays, `${activeTab}.value`) ?? SupportCaseTypes.MY_CASES,
      checkCustomerCases: true
    })

    setPageInfo(DEFAULT_PAGE_INFO)
    firstCheck.current = true

    if (!isMiniTable) {
      setPageTitle(_get(tabArrays, `${activeTab}.pageTitle`, CASE_TABLE_TABS[0].pageTitle))
    }
  }, [activeTab])

  useEffect(() => {
    if (firstCheck.current) setHasCustomerCase(_get(casesData, 'hasCustomerCases'))
  }, [casesData, firstCheck.current])

  useEffect(() => {
    handleGetSupportCases()
  }, [pageInfo])

  useEffect(() => {
    if (pageInfo.page !== 0) setPageInfo({ ...pageInfo, page: 0 })
    else handleGetSupportCases()
  }, [filters])

  return (
    <Box ref={containerRef}>
      {/* Page title  */}
      {pageTitle && (
        <Helmet encodeSpecialCharacters={true} titleTemplate="%s - My OPSWAT" defer={false}>
          <title itemProp="name" lang="en">
            {pageTitle}
          </title>
        </Helmet>
      )}

      {/* Page content */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* Tab Navigation */}
          <TabAdvanced
            defaultTab={activeTab}
            borderBottom={'1px solid #E9EAEB'}
            tabs={tabArrays}
            emitActiveTabValue={setActiveTab}
          />

          {/* Filters */}
          {userContactId && (
            <CasesFilters
              supportProducts={supportProducts}
              filters={filters}
              setCaseFilters={setFilters}
              hasOrg={hasOrganization}
              orgId={_get(profileData, 'currentOrganizationId')}
              orgCaseAccessInfo={orgCaseAccessInfo}
              isLoading={Boolean(isOrgAccessLoading)}
              refreshData={async () => {
                triggerReload?.()
                handleRefreshOrgAccessInfo()
              }}
              isDisplayExtendCaseView={isMiniTable}
            />
          )}
        </Grid>

        {/* Cases Table */}
        <Grid item xs={12}>
          {userContactId ? (
            <TableAdvanced
              isPagination
              disableBackground
              columns={tableColumns}
              isLoading={Boolean(isFetching || isOrgAccessLoading)}
              data={_get(casesData, 'data', [])}
              total={_get(casesData, 'total', 0)}
              page={pageInfo.page}
              pageSize={pageInfo.pageSize}
              pageSizes={isMiniTable ? [5] : PAGE_SIZES_DEFAULT}
              onPageChange={handlePagination}
              noDataTextProps={{ sx: { my: 0 } }}
              boxMinHeight={isMiniTable ? '200px' : undefined}
              boxMaxHeight={isMiniTable ? '400px' : undefined}
              loadingArray={isMiniTable ? _range(4) : _range(15)}
              customActionComponent={
                isMiniTable ? (
                  <Button
                    sx={{ color: '#000', gap: 0.5, height: 'fit-content' }}
                    onClick={() => navigate(supportServicesPageAllCasesURL)}
                  >
                    View all
                    <ExternalLinkIcon />
                  </Button>
                ) : undefined
              }
            />
          ) : (
            <Box minHeight="400px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Box sx={{ marginY: 2 }}>
                <img
                  src={CircularLoader}
                  width={50}
                  height={50}
                  className="loading-spinner"
                  alt="license-loading-spinner"
                />
              </Box>
              <Typography variant="body2">Your data is being initialized and will take around 4-5 hours.</Typography>
              <Typography variant="body2">Please try signing in later to check if it's ready.</Typography>
              <Typography variant="body2">Thank you for your patience!</Typography>
            </Box>
          )}
        </Grid>
      </Grid>

      <DialogCSAT
        caseInfo={selectedCase}
        openDialog={isOpen}
        handleCloseDialog={closeDialog}
        handleSearchOnSubmit={() => {
          handleGetSupportCases()
        }}
      />
    </Box>
  )
}

export default SupportServiceCaseTable
