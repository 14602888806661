import { Box, Typography } from '@opswat/react-ui'

interface IProps {
  title?: React.ReactNode
  required?: boolean
  children: React.ReactNode
}

const SubmitCaseStep: React.FC<IProps> = ({ title, required, children }) => {
  return (
    <Box
      sx={{
        border: '1px solid #E9EAEB',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      {title && (
        <Typography variant="body1" color="#1B273C">
          {title}
          {required && (
            <Typography component="span" color="error">
              {' *'}
            </Typography>
          )}
        </Typography>
      )}
      {children}
    </Box>
  )
}

export default SubmitCaseStep
