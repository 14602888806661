import { TreeNode } from '../pages/MyInformationPage/General/RecursiveTree'

export const buildTree = <T extends Record<string, any>>(
  items: T[],
  parentKey: keyof T,
  idKey: keyof T = 'id',
  nameKey: keyof T = 'name'
): TreeNode[] => {
  const itemMap = new Map<string, TreeNode>()
  const rootNodes: TreeNode[] = []

  items.forEach(item => {
    const id = String(item[idKey])
    const name = String(item[nameKey])

    if (!id || !name) {
      throw new Error(`Item is missing required fields: "${String(idKey)}" or "${String(nameKey)}".`)
    }

    const node: TreeNode = {
      id,
      name,
      children: []
    }

    itemMap.set(id, node)

    const parentIds = item[parentKey] as unknown as string[]
    if (!parentIds || parentIds.length === 0) {
      rootNodes.push(node)
    }
  })

  items.forEach(item => {
    const id = String(item[idKey])
    const parentIds = item[parentKey] as unknown as string[]

    if (parentIds && parentIds.length > 0) {
      parentIds.forEach((parentId: string) => {
        const parentNode = itemMap.get(parentId)

        if (parentNode) {
          const childNode = itemMap.get(id)
          if (childNode) {
            parentNode.children!.push(childNode)
          }
        } else {
          const childNode = itemMap.get(id)
          if (childNode) {
            rootNodes.push(childNode)
          }
        }
      })
    }
  })

  return rootNodes
}
