import React, { FC, useState } from 'react'

import { SolidDownIcon, SolidRightIcon } from '@opswat/react-icon'
import { Box, Collapse } from '@opswat/react-ui'
import BoxProps from '@opswat/react-ui/BoxProps'

interface IProps {
  title: React.ReactNode
  content: React.ReactNode
  boxProps?: BoxProps
  defaultOpen?: boolean
}

const BoxCollapse: FC<IProps> = ({ title, content, boxProps, defaultOpen = true }) => {
  const [open, setOpen] = useState(defaultOpen)

  return (
    <>
      <Box display="flex" alignItems="center" {...boxProps}>
        <Box
          sx={{
            '&:hover': {
              cursor: 'pointer'
            },
            display: 'flex',
            marginLeft: '-4px',
            width: '24px'
          }}
          onClick={() => setOpen(!open)}
        >
          {open ? <SolidDownIcon size={18} /> : <SolidRightIcon size={18} />}
        </Box>
        {title}
      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {content}
      </Collapse>
    </>
  )
}

export default BoxCollapse
