import React, { FC } from 'react'
import { TableCell, Typography } from '@opswat/react-ui'
import { formatDatetime } from '@opswat/react-core'
import { DATE_FORMATS } from '@myopswat/common'
import _get from 'lodash/get'
import { useTypedSelector } from 'myopswat-web/src/store'

interface DateCellProps {
  data: any
}

export const DateCell: FC<DateCellProps> = ({ data }) => {
  const profile = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  return (
    <TableCell sx={{ p: 0.25, width: 180, textAlign: 'center' }}>
      <Typography variant="body2">
        {formatDatetime(_get(data, 'latestDownloadedAt'), DATE_FORMATS.DATE_TIME, _get(profile, 'timezone')) || '--'}
      </Typography>
    </TableCell>
  )
}
