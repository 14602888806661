import _get from 'lodash/get'

import { FC } from 'react'

import { Button, DialogAdvanced, Grid, Typography } from '@opswat/react-ui'

import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import { selectDialogs, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

interface IProps {
  handleResetSelection: () => void
}

const DialogInvalidCustomer: FC<IProps> = ({ handleResetSelection }) => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)

  const handleCloseDialog = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS_WEB.INVALID_CUSTOMER_ORGANIZATION]: false
      })
    )
    handleResetSelection()
  }

  return (
    <DialogAdvanced
      open={_get(dialogType, DIALOGS_WEB.INVALID_CUSTOMER_ORGANIZATION, false)}
      title="Invalid Customer Organization"
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">
              Some of your currently selected customer organizations are invalid.
              <br />
              Please click on the &quot;Confirm&quot; button to reset your selections.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              sx={{
                textTransform: 'none'
              }}
              onClick={handleCloseDialog}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      }
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}

export default DialogInvalidCustomer
