import React, { FC, useState, useMemo, useCallback } from 'react'
import { TableRow, useTheme } from '@opswat/react-ui'
import { NameCell, DownloadCountCell, DateCell, ActionCell } from './cell'
import _sortBy from 'lodash/sortBy';

interface IProps {
  data: any
  setSelectItem: (data: any) => void
  setIsOpenView: (data: boolean) => void
  setIsOpenPVersion: (data: boolean) => void
  level?: number
  index?: number
  initialPadding?: number
}

const BoxDownloadedBody: FC<IProps> = ({
  data,
  setSelectItem,
  setIsOpenPVersion,
  setIsOpenView,
  level = 0,
  index = 0,
  initialPadding = 0
}) => {
  const theme = useTheme()
  const [openCollapse, setOpenCollapse] = useState(false)
  const isCollapsible = useMemo(() => Array.isArray(data.children) && data.children.length > 0, [data])

  const sortedChildren = useMemo(() => {
    return isCollapsible ? _sortBy(data.children, 'name') : [];
  }, [data.children, isCollapsible]);

  const handleToggleCollapse = useCallback(() => {
    setOpenCollapse(prevState => !prevState)
  }, [])

  const handleClickDownloadCount = useCallback(() => {
    setSelectItem(data)
    setIsOpenView(true)
  }, [data, setSelectItem, setIsOpenView])

  const handleOpenPVersion = useCallback(() => {
    setSelectItem(data)
    setIsOpenPVersion(true)
  }, [data, setSelectItem, setIsOpenPVersion])

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: index % 2 === 0 ? theme.palette.grey[50] : 'white',
          '&:last-child td, &:last-child th': { border: 0 }
        }}
      >
        <NameCell
          data={data}
          level={level}
          isCollapsible={isCollapsible}
          openCollapse={openCollapse}
          onToggleCollapse={handleToggleCollapse}
        />
        <DownloadCountCell data={data} onSelect={handleClickDownloadCount} />
        <DateCell data={data} />
        <ActionCell level={level} onClick={handleOpenPVersion} />
      </TableRow>

      {isCollapsible && openCollapse && (
        <>
          {sortedChildren.map((child: any) => (
            <BoxDownloadedBody
              key={child.id}
              data={child}
              setSelectItem={setSelectItem}
              setIsOpenPVersion={setIsOpenPVersion}
              setIsOpenView={setIsOpenView}
              level={level + 1}
              index={index}
              initialPadding={initialPadding}
            />
          ))}
        </>
      )}
    </>
  )
}

export default BoxDownloadedBody
