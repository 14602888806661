import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'
import {
  IAccountResendInput,
  IAccountSettingsUpdateInput,
  IChangePasswordInput,
  IFeedbackInput,
  ILoginInput,
  IProfileInput,
  IVerifyTokenInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    accountLogin: builder.mutation<any, ILoginInput>({
      query: ({ email, password, captchaToken, totp, recovery }) => ({
        document: gql`
          mutation accountLogin($input: AccountLoginInput) {
            accountLogin(input: $input) {
              success
              errors
              enableMfa
              accessToken
              ocmToken
              refreshToken
              csrfToken
              ssoMeta
            }
          }
        `,
        variables: {
          input: {
            email: email,
            password: password,
            totp: totp,
            recovery: recovery,
            captchaToken: captchaToken
          }
        }
      })
    }),
    // accountRegister: builder.mutation<any, IRegisterInput>({
    //   query: ({
    //     email,
    //     password,
    //     firstName,
    //     lastName,
    //     companyName,
    //     country,
    //     captchaToken,
    //     customHost,
    //     agreed,
    //     consented
    //   }) => ({
    //     document: gql`
    //       mutation accountRegister($input: AccountRegisterInput!) {
    //         accountRegister(input: $input) {
    //           success
    //           errors
    //         }
    //       }
    //     `,
    //     variables: {
    //       input: {
    //         email: email,
    //         password: password,
    //         firstName: _trim(firstName),
    //         lastName: _trim(lastName),
    //         companyName: companyName,
    //         address: {
    //           country
    //         },
    //         captchaToken: captchaToken,
    //         customHost: customHost,
    //         agreed: agreed,
    //         consented: consented
    //       }
    //     }
    //   })
    // }),
    tokenVerify: builder.mutation<any, IVerifyTokenInput>({
      query: ({ token }) => ({
        document: gql`
          mutation {
            tokenVerify(token: "${token}") {
              success
              errors
              isValid
            }
          }
        `
      })
    }),
    // accountResetPassword: builder.mutation<any, IResetPasswordInput>({
    //   query: ({ email, customHost }) => ({
    //     document: gql`
    //       mutation {
    //         accountResetPassword(input: { email: "${email}", customHost: "${customHost}" }) {
    //           success
    //           errors
    //         }
    //       }
    //     `
    //   })
    // }),
    profile: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            profile {
              id
              firstName
              lastName
              title
              email
              userTypes
              companyName
              timezone
              note
              customerSupportId
              address {
                id
                streetAddress1
                streetAddress2
                city
                state
                phone
                country
                postalCode
              }
              usingOrganizationId
              currentOrganizationId
              organizationForceMfa
              accountInfo
              showPersonalize
              enabledAi
              showWarningSnackbar
              isIdaasConsent
              showSocSurvey
              survey
              staticSurvey
              isCustomer
              platform
              productManagement
              currentOrganizationName
              currentOrganizationAccountId
              sfdcContactId
              chatbotEnable
              mdEmailSecurityUrl
              showReferralSource
              sfdcData
              systemNotification
              portalRoleIds
              portalPermissions
              maUrls
              hasPrefPartnerAsCustomer
            }
          }
        `
      })
    }),
    accountChangePassword: builder.mutation<any, IChangePasswordInput>({
      query: ({ email, password, newPassword }) => ({
        document: gql`
          mutation {
            accountChangePassword(
              input: {
                email: "${email}"
                password: "${password}"
                newPassword: "${newPassword}"
              }
            ) {
              success
              errors
            }
          }
        `
      })
    }),
    profileUpdate: builder.mutation<any, IProfileInput>({
      query: ({
        firstName,
        lastName,
        title,
        companyName,
        country,
        state,
        streetAddress1,
        streetAddress2,
        city,
        phone,
        postalCode,
        timezone,
        note,
        usingOrganizationId
      }) => ({
        document: gql`
          mutation profileUpdate($input: ProfileUpdateInput!) {
            profileUpdate(input: $input) {
              success
              errors
              action
            }
          }
        `,
        variables: {
          input: {
            firstName,
            lastName,
            title,
            companyName,
            address: {
              country,
              state,
              streetAddress1,
              streetAddress2,
              city,
              phone,
              postalCode
            },
            timezone,
            note,
            usingOrganizationId
          }
        }
      })
    }),
    accountLogout: builder.mutation<any, void>({
      query: () => ({
        document: gql`
          mutation {
            accountLogout {
              success
              errors
            }
          }
        `
      })
    }),
    userSettings: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            userSettings {
              subscribedEmail {
                update
                newsletter
                blog
                salesOutreach
                productRelease
                sendAllPurchasedProducts
                selectedProductIds
                unsubscribedAll
                consented
              }
              isShowEmailSubscriptions
              isCustomer
            }
          }
        `
      })
    }),
    accountSettingsUpdate: builder.mutation<any, IAccountSettingsUpdateInput>({
      query: ({ subscribedEmail, enabledAi, showWarningSnackbarAt, isIdaasConsent, showSocSurvey, solutionIds }) => ({
        document: gql`
          mutation accountSettingsUpdate($input: AccountSettingInput!) {
            accountSettingsUpdate(input: $input) {
              success
              errors
              action
            }
          }
        `,
        variables: {
          input: {
            subscribedEmail,
            enabledAi,
            showWarningSnackbarAt,
            isIdaasConsent,
            showSocSurvey,
            solutionIds
          }
        }
      })
    }),
    // accountResetPasswordSubmit: builder.mutation<any, IResetPasswordSubmitInput>({
    //   query: ({ email, code, password }) => ({
    //     document: gql`
    //       mutation {
    //         accountResetPasswordSubmit(
    //           input: {
    //             email: "${email}"
    //             code: "${code}"
    //             password: "${password}"
    //           }
    //         ) {
    //           success
    //           errors
    //         }
    //       }
    //     `
    //   })
    // }),
    // accountActivate: builder.mutation<any, IAccountActivateInput>({
    //   query: ({ email, code }) => ({
    //     document: gql`
    //       mutation {
    //         accountActivate(
    //           input: {
    //             email: "${email}"
    //             code: "${code}"
    //           }
    //         ) {
    //           success
    //           errors
    //           redirectUrl
    //         }
    //       }
    //     `
    //   })
    // }),
    accountResend: builder.mutation<any, IAccountResendInput>({
      query: ({ email, customHost }) => ({
        document: gql`
          mutation {
            accountResend(
              input: {
                email: "${email}"
                customHost: "${customHost}"
              }
            ) {
              success
              errors
            }
          }
        `
      })
    }),
    feedbackCreate: builder.mutation<any, IFeedbackInput>({
      query: ({ feedback, recommendProductScore, recommendCompanyScore }) => ({
        document: gql`
          mutation feedbackCreate($input: FeedbackInput!) {
            feedbackCreate(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: {
            feedback: feedback,
            recommendProductScore: recommendProductScore,
            recommendCompanyScore: recommendCompanyScore
          }
        }
      })
    }),
    usersNotInOrganization: builder.query<any, string>({
      query: id => ({
        document: gql`
          query  {
            usersNotInOrganization(id: "${id}") {
              id
              email
            }
          }
        `
      })
    }),
    checkShowDialog: builder.query<any, string>({
      query: dialogType => ({
        document: gql`
          query ($dialogType: String) {
            checkShowDialog(dialogType: $dialogType)
          }
        `,
        variables: {
          dialogType
        }
      })
    }),
    updateShowDialog: builder.mutation<any, string>({
      query: dialogType => ({
        document: gql`
          mutation userUpdateShowDialog($input: UserUpdateShowDialogInput!) {
            userUpdateShowDialog(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            dialogType
          }
        }
      })
    })
  })
})

export const {
  useAccountLoginMutation,
  // useAccountRegisterMutation,
  useProfileQuery,
  useLazyProfileQuery,
  useTokenVerifyMutation,
  useAccountChangePasswordMutation,
  // useAccountResetPasswordMutation,
  useProfileUpdateMutation,
  useAccountLogoutMutation,
  useUserSettingsQuery,
  useLazyUserSettingsQuery,
  useAccountSettingsUpdateMutation,
  // useAccountResetPasswordSubmitMutation,
  // useAccountActivateMutation,
  useAccountResendMutation,
  useFeedbackCreateMutation,
  useUsersNotInOrganizationQuery,
  useLazyCheckShowDialogQuery,
  useUpdateShowDialogMutation
} = api
