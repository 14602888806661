import { FC } from 'react'

import { Box } from '@opswat/react-ui'

import TestIframePage from 'myopswat-web/src/pages/TestIframePage'

const TestIframeContainer: FC<unknown> = () => {
  return (
    <Box sx={{ px: 3, py: 2 }}>
      <TestIframePage />
    </Box>
  )
}

export default TestIframeContainer
