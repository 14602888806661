import { useCallback } from 'react'
import { useAddUserActivityMutation } from '../api/user-activity'

const useUserActivityHook = () => {
  const [addUserActivity] = useAddUserActivityMutation()

  const createUserActivity = useCallback(
    async (action: string, data: any) => {
      const requestBody = {
        action: action,
        data: data
      }
      await addUserActivity(requestBody)
    },
    [addUserActivity]
  )

  return {
    createUserActivity
  }
}

export { useUserActivityHook as default }
