import _get from 'lodash/get'

import { useContext } from 'react'
import { Controller, useFormState } from 'react-hook-form'

import { Autocomplete, Skeleton, TextField } from '@opswat/react-ui'

import { SupportSubmitCaseContext } from '../interface'

const SubmitCaseOrganizationStep: React.FC<any> = () => {
  const { control, organizationList, isLoadingOrganizationList } = useContext(SupportSubmitCaseContext)

  const { errors } = useFormState({ control })

  return isLoadingOrganizationList ? (
    <Skeleton animation="wave" variant="rectangular" width="100%" height="37px" />
  ) : (
    <Controller
      name="preferredPartnerId"
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          disableClearable
          placeholder="Select Organization"
          disabled={isLoadingOrganizationList}
          options={organizationList.map((item: any, index: number) => ({
            label: index === 0 ? `My Organization (${item.name})` : item.name,
            value: item.id
          }))}
          isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
          renderInput={(params: any) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              placeholder="Select Organization"
              error={!!_get(errors, 'preferredPartnerId', '')}
              helperText={_get(errors, 'preferredPartnerId', '')}
              sx={{
                minHeight: 'auto'
              }}
            />
          )}
          onChange={(_: any, newValue: any) => {
            field.onChange(newValue)
          }}
        />
      )}
    />
  )
}

export default SubmitCaseOrganizationStep
