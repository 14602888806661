import { Button, Checkbox, DropdownFilter, FormControlLabel, Grid, TextFieldSearch, Typography } from '@opswat/react-ui'
import _debounce from 'lodash/debounce'
import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react'

import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'

import { OrganizationUserFilterInput } from 'myopswat-web/src/api/organization/types'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { STATUS_OPTIONS } from './constants'

interface IProps {
  roles: any[]
  query: OrganizationUserFilterInput
  onSearch: (searchData: OrganizationUserFilterInput) => void
  onInviteUser: () => void
  disabled: boolean
}

const UserFilter: FC<IProps> = ({ roles, query, onSearch, onInviteUser, disabled }) => {
  const { t: translate } = useTranslation()
  const {
    control,
    reset,
    getValues,
    watch,
    formState: { dirtyFields }
  } = useForm<OrganizationUserFilterInput>({
    mode: 'onSubmit',
    defaultValues: query,
    values: query
  })

  const selectedStatus = watch("status")
  const q = watch("q")
  const portalRoles = watch("portalRoles")

  const handleSelectStatus = (status: any, checked: boolean) => {
    let newStatus
    if (checked) {
      newStatus = [...selectedStatus, status]
    } else {
      newStatus = selectedStatus.filter(s => s !== status)
    }
    return newStatus
  }

  const handleSelectRole = (role: any, checked: boolean) => {
    let newRoles
    if (checked) {
      newRoles = [...portalRoles, role]
    } else {
      newRoles = portalRoles.filter(s => s !== role)
    }
    return newRoles
  }

  const isChangedTextSearch = useMemo(() => {
    return dirtyFields.q
  }, [dirtyFields.q])

  const handleSearch = useCallback(() => {
    const searchData = {
      ...getValues(),
      portalRoles: portalRoles,
      q: q
    }
    onSearch(searchData)
  }, [q, portalRoles])

  useEffect(() => {
    if (isChangedTextSearch) {
      const _search = _debounce(handleSearch, 300)

      _search()

      return () => {
        _search.cancel()
      }
    }
  }, [isChangedTextSearch, handleSearch])

  const handleApplyFilter = useCallback(() => {
    handleSearch()
  }, [handleSearch])

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={(cProps: any) => (
            <TextFieldSearch
              placeholder={translate('filterUserName') || ''}
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onClearText={() => cProps.field.onChange('')}
              onKeyUp={(e: any) => {
                if (e.target.value === '') {
                  onSearch({
                    ...getValues(),
                    q: ''
                  })
                }
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs="auto">
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <DropdownFilter
              button={
                <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
                  Filter
                </Button>
              }
              content={
                <Grid container>
                  <Grid item xs={6}>
                    <Controller
                      name={'status'}
                      control={control}
                      render={({ field }) => (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">Status</Typography>
                          </Grid>
                          {STATUS_OPTIONS.map((option, index) => {
                            return (
                              <Grid item xs={12} key={index.toString()}>
                                <FormControlLabel
                                  key={`user-filter-status-${index}`}
                                  control={
                                    <Checkbox
                                      checked={selectedStatus?.includes(option.value as never)}
                                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                        field.onChange(handleSelectStatus(option.value, event.target.checked))
                                      }
                                    />
                                  }
                                  label={<Typography variant="subtitle2">{option.label}</Typography>}
                                />
                              </Grid>
                            )
                          })}
                        </Grid>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={'portalRoles'}
                      control={control}
                      render={({ field }) => (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">Roles</Typography>
                          </Grid>
                          {roles.map((option: any, index: number) => {
                            return (
                              <Grid item xs={12} key={index.toString()}>
                                <FormControlLabel
                                  key={`user-filter-role-${index}`}
                                  control={
                                    <Checkbox
                                      checked={portalRoles?.includes(option.id as never)}
                                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                        field.onChange(handleSelectRole(option.id, event.target.checked))
                                      }
                                    />
                                  }
                                  label={<Typography variant="subtitle2">{option.name}</Typography>}
                                />
                              </Grid>
                            )
                          })}
                        </Grid>
                      )}
                    />
                  </Grid>
                </Grid>
              }
              onResetFilter={() => {
                reset({
                  q: q,
                  status: [],
                  portalRoles: [],
                  excludeUserIds: []
                })
              }}
              onResetChange={() => reset({...query, q})}
              onApply={handleApplyFilter}
            />
          </Grid>

          <Grid item xs="auto">
            <Button onClick={onInviteUser} variant="contained" color="primary" disabled={disabled}>
              Invite User
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UserFilter
