import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormDialog } from '@opswat/react-ui'
import { OrganizationAddCriticalAlertUserForm } from 'myopswat-web/src/api/organization/types'
import { useAddCriticalAlertUserMutation } from 'myopswat-web/src/api/support'
import useApiErrorHook from 'myopswat-web/src/hooks/useApiErrorHook'
import { enqueueSnackbar } from 'notistack'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AddUserForm from './AddUserForm'
import { addUserSchema } from './schema'
import _toLower from 'lodash/toLower'

const defaultValues = {
  email: ''
}

interface IProps {
  organization: any
}

const AddUserModal = NiceModal.create(({ organization }: IProps) => {
  const { t: translate } = useTranslation()
  const modal = useModal()
  const formRef = useForm<OrganizationAddCriticalAlertUserForm>({
    defaultValues: defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(addUserSchema)
  })
  const { reset, handleSubmit, formState } = formRef
  const { isDirty } = formState
  const [addCriticalAlertUser, { isLoading }] = useAddCriticalAlertUserMutation()
  const { showErrorMessage } = useApiErrorHook({ errorMap: {} })
  const handleResetForm = () => {
    reset(defaultValues)
  }

  const onSubmit: SubmitHandler<OrganizationAddCriticalAlertUserForm> = async (
    formData: OrganizationAddCriticalAlertUserForm
  ) => {
    try {
      const payload = {
        email: formData.email,
        orgId: organization.id
      }
      const res = await addCriticalAlertUser(payload).unwrap()
      if (res.success) {
        enqueueSnackbar(translate('addCriticalAlertSuccess'), { variant: 'success' })
        handleResetForm()
        modal.resolve()
        modal.hide()
      } else {
        showErrorMessage(res.errors)
      }
    } catch (error: any) {
      // Ignore permission denied error as it is handled by A Modal at base-query/graphql
      if (error.statusCode === 403 && _toLower(error.code) === 'permission_denied') {
        return
      }

      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const onCancel = () => {
    modal.hide()
    handleResetForm()
  }

  return (
    <FormDialog
      isLoading={isLoading}
      isDisableSubmit={!isDirty}
      title="Add User"
      open={modal.visible}
      onClose={() => modal.hide()}
      onExited={() => modal.remove()}
      onCancel={onCancel}
      onConfirm={handleSubmit(onSubmit)}
      confirmBtnLabel="Submit"
      minHeight={300}
      maxWidth={'sm'}
      content={<AddUserForm formRef={formRef} />}
    />
  )
})

export default AddUserModal
