import { FC, useMemo } from 'react'

import { Box, TemplateSection } from '@opswat/react-ui'
import { useSysItemCountriesQuery } from 'myopswat-web/src/api/system'
import BasicSection from './Basic'
import BasicLoading from './Basic/BasicLoading'

interface IProps {
  organization: any
  isLoading: boolean
  afterSave: () => void
  permissions: any
}

const General: FC<IProps> = ({ isLoading, organization, afterSave, permissions }: IProps) => {
  const { data: countries, isLoading: isFetchingCountries } = useSysItemCountriesQuery(undefined)

  const isFetching = useMemo(() => {
    return isLoading || isFetchingCountries
  }, [isLoading, isFetchingCountries])

  return (
    <TemplateSection spacing={2}>
      {isFetching ? (
        <BasicLoading />
      ) : (
        <Box>
          <BasicSection organization={organization} countries={countries} afterSave={afterSave} permissions={permissions} />
        </Box>
      )}
    </TemplateSection>
  )
}

export default General
