import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import _map from 'lodash/map'

import {
  AnalysisIcon,
  CertificateIcon,
  LifeSaverIcon,
  IntegrityFailuresIcon,
  ClipboardTextIcon,
  HelpDeskIcon,
  FAQCommunityIcon,
  LicenseV2Icon
} from '@opswat/react-icon'
import { Grid, Typography } from '@opswat/react-ui'

import { reportFalseDetectionSubmitPageURL } from 'myopswat-web/src/routes'

import BoxResource from './BoxResource'

export const ResourcesDialog: React.FC = () => {
  const navigate = useNavigate()

  const resourcesInfo = useMemo(() => {
    return [
      {
        icon: <LifeSaverIcon size={18} />,
        label: 'Request Support (original page)',
        link: process.env.REACT_APP_OPSWAT_GO_PORTAL_URL
      },
      {
        icon: <AnalysisIcon size={18} />,
        label: 'OPSWAT System Status',
        link: process.env.REACT_APP_OPSWAT_TRUST_URL
      },
      {
        icon: <IntegrityFailuresIcon size={18} />,
        label: 'Report False Detection',
        link: `${window.location.origin}${reportFalseDetectionSubmitPageURL}`,
        action: (e: React.MouseEvent<Element, MouseEvent>) => {
          navigate(reportFalseDetectionSubmitPageURL)
          e.preventDefault()
        }
      },
      {
        icon: <CertificateIcon size={18} />,
        label: 'OPSWAT Academy',
        link: process.env.REACT_APP_ACADEMY_URL
      },
      {
        icon: <FAQCommunityIcon size={18} />,
        label: 'Community Forum',
        link: `${process.env.REACT_APP_OPSWAT_GO_URL}/s`
      },
      {
        icon: <ClipboardTextIcon size={18} />,
        label: 'Product Documentation',
        link: process.env.REACT_APP_OPSWAT_DOCS_URL
      },
      {
        icon: <LicenseV2Icon size={18} />,
        label: 'Support Plans',
        link: `${process.env.REACT_APP_OPSWAT_URL}/support#software-support-plans`
      },
      {
        icon: <HelpDeskIcon size={18} />,
        label: 'Help with this page',
        link: `${process.env.REACT_APP_OPSWAT_DOCS_URL}/my/support-services`
      }
    ]
  }, [])

  return (
    <>
      <Typography variant="caption">
        Each resource link opens in a new tab, providing quick access to essential external tools and support for your
        OPSWAT experience.
      </Typography>
      <Grid container spacing={1} pt={2}>
        {_map(resourcesInfo, (item, idx) => (
          <Grid item xs={6} key={idx.toString()} onClick={item.action}>
            <BoxResource {...item} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
