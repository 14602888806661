export enum CaseStatus {
  New = 'New',
  WaitingOnMe = 'Waiting on Me',
  Closed = 'Closed',
  WaitingOnOPSWAT = 'Waiting on OPSWAT',
  Open = 'Open',
  OnHold = 'On Hold'
}

export enum SupportCaseTypes {
  MY_CASES = 'myCases',
  ORG_CASES = 'orgCases',
  CUSTOMER_CASES = 'customerCases'
}

export const CASE_TABLE_TABS = [
  {
    label: 'My Cases',
    value: SupportCaseTypes.MY_CASES,
    pageTitle: 'View My Cases (Support)'
  },
  {
    label: 'Organization Cases',
    value: SupportCaseTypes.ORG_CASES,
    pageTitle: 'View Organization Cases (Support)'
  },
  {
    label: 'Customer Cases',
    value: SupportCaseTypes.CUSTOMER_CASES,
    pageTitle: 'View Customer Cases (Support)'
  }
]
