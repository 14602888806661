import { useMemo, useState } from 'react'

type UsePaginationResult<T> = {
  currentPage: number
  totalPages: number
  paginatedData: T[]
  setPage: (page: number) => void
  nextPage: () => void
  prevPage: () => void
  itemsPerPage: number
  setItemsPerPage: (items: number) => void
  totalItems: number
}

export const usePagination = <T>(data: T[], initialItemsPerPage = 10): UsePaginationResult<T> => {
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage)

  const totalItems = useMemo(() => data.length, [data])

  const totalPages = useMemo(() => Math.ceil(totalItems / itemsPerPage), [totalItems, itemsPerPage])

  const paginatedData = useMemo(
    () => data.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage),
    [data, currentPage, itemsPerPage]
  )

  const setPage = (page: number) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page)
    }
  }

  const nextPage = () => setPage(currentPage + 1)
  const prevPage = () => setPage(currentPage - 1)

  return {
    currentPage,
    totalPages,
    paginatedData,
    setPage,
    nextPage,
    prevPage,
    itemsPerPage,
    setItemsPerPage,
    totalItems
  }
}
