import React from 'react'

import MyOrganizationPage from 'myopswat-web/src/pages/MyOrganizationPage'
import useGetPortalPermissions from '../../hooks/useGetPortalPermissions'

const MyOrganizationContainer = () => {
  const { permissions } = useGetPortalPermissions()
  return <MyOrganizationPage permissions={permissions} />
}

export default MyOrganizationContainer
