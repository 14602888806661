import React, { FC } from 'react'

import { Box, Button, Grid, Typography, TypographyDivider } from '@opswat/react-ui'

const PermissionDeniedForm: FC<unknown> = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TypographyDivider label="Permissions Denied"/>
        <Typography variant="subtitle2" color="textSecondary" pt={3}>
          You don’t have permission to perform this action.
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" pt={1}>
          Your permission may be modified by an administrator. Please contact your administrators for help.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" variant="contained" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PermissionDeniedForm
