import { PlatformKubernetesIcon, PlatformLinuxIcon, PlatformMacOSIcon, PlatformWindowsIcon } from '@opswat/react-icon'
import { Button, Typography } from '@opswat/react-ui'

import { SUBMIT_CASE_PLATFORM } from '../constants'

interface IProps {
  id: string
  name: string
  isSelected: boolean
  handleSelectPlatform: (id: string) => void
}

const SubmitCasePlatform: React.FC<IProps> = ({ id, name, isSelected, handleSelectPlatform }) => {
  const handleRenderPlatformIcon = (id: string) => {
    switch (id) {
      case SUBMIT_CASE_PLATFORM.WINDOWS:
        return <PlatformWindowsIcon size={16} color={isSelected ? '#FFFFFF' : '#000000'} />
      case SUBMIT_CASE_PLATFORM.LINUX:
        return <PlatformLinuxIcon size={16} color={isSelected ? '#FFFFFF' : '#000000'} />
      case SUBMIT_CASE_PLATFORM.MACOS:
        return <PlatformMacOSIcon size={16} color={isSelected ? '#FFFFFF' : '#000000'} />
      case SUBMIT_CASE_PLATFORM.KUBERNETES:
        return (
          <PlatformKubernetesIcon
            size={16}
            color={isSelected ? '#FFFFFF' : '#000000'}
            backgroundColor={isSelected ? '#1D6BFC' : '#E9EAEB'}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <Button
      fullWidth
      sx={{
        height: '100%',
        backgroundColor: isSelected ? '#1D6BFC' : '#E9EAEB',
        borderRadius: '5px',
        padding: '24px 12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        '&:hover': {
          backgroundColor: isSelected ? '#195DDB' : '#D2D4D6'
        }
      }}
      onClick={() => handleSelectPlatform(id)}
    >
      {handleRenderPlatformIcon(id)}
      <Typography
        variant="subtitle2"
        height="20px"
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: isSelected ? '#FFFFFF' : '#000000'
        }}
      >
        {name}
      </Typography>
    </Button>
  )
}

export default SubmitCasePlatform
