import { FC, useEffect } from 'react'

import _includes from 'lodash/includes'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import PublicLayoutPage from 'myopswat-web/src/pages/PublicLayoutPage'
import {
  oldPaymentPageUrl,
  organizationAcceptInvitationURL,
  paymentPageURL,
  testIframePageURL,
  unsubscribedPageURL
} from 'myopswat-web/src/routes'

const PublicLayoutContainer: FC<unknown> = () => {
  const location = useLocation()

  const navigate = useNavigate()

  const handleRenderPage = () => {
    if (location && _includes(location.pathname, organizationAcceptInvitationURL)) return true
    if (location && _includes(location.pathname, unsubscribedPageURL)) return true
    if (location && _includes(location.pathname, testIframePageURL)) return true

    return false
  }

  useEffect(() => {
    if (location.pathname && _includes(location.pathname, oldPaymentPageUrl)) {
      navigate(paymentPageURL)
    }
  }, [])

  if (handleRenderPage()) return <Outlet />

  return <PublicLayoutPage />
}

export default PublicLayoutContainer
