import _get from 'lodash/get'
import _merge from 'lodash/merge'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../../store'
import { ILoginInput } from 'myopswat-web/src/api/account/types'
import { DIALOGS } from '@myopswat/common'

type LayoutState = {
  account: ILoginInput
  popupMessage: string
  dialogs: any
  isFirstLogin: boolean
  organizations: any
  isSkipPersonal: boolean
  isCloseNPSSurvey: boolean
}

const initialState: LayoutState = {
  account: {
    email: '',
    password: '',
    captchaToken: '',
    totp: '',
    recovery: ''
  },
  popupMessage: '',
  dialogs: {},
  isFirstLogin: false,
  organizations: [],
  isSkipPersonal: false,
  isCloseNPSSurvey: false
}

const layout = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    saveAccount: (state, action: PayloadAction<ILoginInput>) => {
      state.account = action.payload
    },
    setDialogMessage: (state, action: PayloadAction<string>) => {
      state.popupMessage = action.payload
    },
    toggleDialogs: (state, action: PayloadAction<object>) => {
      if (_get(action.payload, DIALOGS.CLEAR, false)) {
        state.dialogs = {}
      } else {
        state.dialogs = _merge(state.dialogs, action.payload)
      }
    },
    saveFirstLogin: (state, action: PayloadAction<boolean>) => {
      state.isFirstLogin = action.payload
    },
    saveOrganizations: (state, action: PayloadAction<any>) => {
      state.organizations = action.payload
    },
    saveSkipProfile: (state, action: PayloadAction<boolean>) => {
      state.isSkipPersonal = action.payload
    },
    saveCloseNPSSurvey: (state, action: PayloadAction<boolean>) => {
      state.isCloseNPSSurvey = action.payload
    }
  }
})

export const {
  saveAccount,
  setDialogMessage,
  toggleDialogs,
  saveFirstLogin,
  saveOrganizations,
  saveSkipProfile,
  saveCloseNPSSurvey
} = layout.actions

export default layout.reducer

export const selectLayoutAccount = (state: RootState) => state.layout.account
export const selectPopupMessage = (state: RootState) => state.layout.popupMessage
export const selectDialogs = (state: RootState) => state.layout.dialogs
export const selectIsFirstLogin = (state: RootState) => state.layout.isFirstLogin
export const selectOrganizations = (state: RootState) => state.layout.organizations
export const selectIsSkipPersonal = (state: RootState) => state.layout.isSkipPersonal
export const selectIsCloseNPSSurvey = (state: RootState) => state.layout.isCloseNPSSurvey
