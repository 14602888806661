import { FC, useCallback, useEffect, useRef, useState } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { enqueueSnackbar } from 'notistack'
import { Control, UseFormSetValue, useWatch } from 'react-hook-form'

import { AttachPaperclip, CloseIcon } from '@opswat/react-icon'
import {
  Box,
  Button,
  ButtonLoading,
  DialogAdvanced,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  List,
  TextField,
  Typography
} from '@opswat/react-ui'

import { useDeleteMFTFileMutation, useUploadMFTFileSingleMutation } from 'myopswat-web/src/api/analyzer/fileUpload'
import { useSupportCaseUpdateMutation } from 'myopswat-web/src/api/support'
import { ISupportCaseCreateV2Input } from 'myopswat-web/src/api/support/submit-case/types'
import { CaseFileItem } from 'myopswat-web/src/components/Dialog/DialogSubmitCase/components'
import { FileErrors, FileItem, FileStatus } from 'myopswat-web/src/components/Dialog/DialogSubmitCase/interface'
import {
  MAXIMUM_UPLOAD_FILE_NUMBER_SUBMIT_CASE,
  MAXIMUM_UPLOAD_FILE_SIZE_SUBMIT_CASE
} from 'myopswat-web/src/constants'

interface IProps {
  openDialog: boolean
  caseId: string
  caseNumber: string
  control: Control<ISupportCaseCreateV2Input>
  setValue: UseFormSetValue<ISupportCaseCreateV2Input>
  handleCloseDialog: () => void
  setHasUploadedPackage: (value: boolean) => void
}

const DialogUploadSupportPackage: FC<IProps> = ({
  openDialog,
  caseId,
  caseNumber,
  control,
  setValue,
  handleCloseDialog,
  setHasUploadedPackage
}) => {
  const inputFile: any = useRef(null)

  const attachments = useWatch({
    control,
    name: 'attachments'
  })

  const [fileItems, setFileItems] = useState<FileItem[]>(attachments ?? [])
  const [currentFile, setCurrentFile] = useState<File>()
  const [errors, setErrors] = useState<string>('')

  const [uploadFileSingle] = useUploadMFTFileSingleMutation()
  const [deleteFile] = useDeleteMFTFileMutation()
  const [supportCaseUpdate, { isLoading: isUpdatingCase }] = useSupportCaseUpdateMutation()

  const onFilesChange = async (event: any) => {
    const fileList: FileList = event.target.files
    if (fileList && fileList.length > 0) {
      const file: File = fileList[0]
      const errorMessage = handleValidateFile(fileList[0])
      if (errorMessage) {
        setErrors(errorMessage)
        setCurrentFile(undefined)
      } else {
        setErrors('')
        setCurrentFile(file)
      }
    }
  }

  const handleUploadFileResult = async (response: any, displayId: string) => {
    const status = _get(response, ['status', 'status'])
    const fileId = _get(response, ['status', 'file_id'])
    const fileSize = _get(response, ['status', 'file_size'])
    const groupId = _get(response, ['group_id'])
    setFileItems(prev =>
      prev.map((item: FileItem) =>
        item.uuid === displayId
          ? {
              ...item,
              fileId,
              fileSize,
              groupId,
              status: status === 'success' ? FileStatus.SUCCESS : FileStatus.ERROR
            }
          : item
      )
    )
  }

  const handleUploadFileError = (error: any, displayId: string) => {
    setFileItems(prev =>
      prev.map((item: FileItem) => (item.uuid === displayId ? { ...item, status: FileStatus.ERROR } : item))
    )
    if (error.status === 429) {
      // Too Many Requests status code
      enqueueSnackbar('Please give the system a moment then try again.', {
        variant: 'error'
      })
    } else {
      enqueueSnackbar(
        _get(
          error,
          ['data', 'error', 'message'],
          'Submitting your file has failed. Please give the system a moment then try again.'
        ),
        {
          variant: 'error'
        }
      )
    }
  }

  const handleUploadFile = async (file: File) => {
    const displayId = crypto.randomUUID()
    setFileItems((prev: any) => [{ file, uuid: displayId, status: FileStatus.IN_PROGRESS }, ...prev])
    setCurrentFile(undefined)

    const formData = new FormData()
    formData.append('file', file)
    await uploadFileSingle(formData)
      .unwrap()
      .then((response: any) => {
        handleUploadFileResult(response, displayId)
      })
      .catch((error: any) => {
        handleUploadFileError(error, displayId)
      })
  }

  const handleRemoveFileItems = (fileId: string) => {
    setFileItems(prev => prev.filter((item: FileItem) => fileId !== item.fileId))
  }

  const handleRemoveFile = async (fileItem: FileItem) => {
    const fileId = fileItem.fileId
    if (fileId) {
      await deleteFile({ file_id: fileItem.fileId, group_id: fileItem.groupId })
        .unwrap()
        .then(() => {
          handleRemoveFileItems(fileId)
        })
    } else {
      setFileItems(prev => prev.filter((item: FileItem) => fileItem.uuid !== item.uuid))
    }
  }

  const handleValidateFile = (file: File) => {
    if (file.size > MAXIMUM_UPLOAD_FILE_SIZE_SUBMIT_CASE) {
      return FileErrors.MAX_FILE_SIZE
    } else if (fileItems.length === MAXIMUM_UPLOAD_FILE_NUMBER_SUBMIT_CASE) {
      return FileErrors.MAX_FILE_NUM
    } else {
      return ''
    }
  }

  const handleRenderFileItem = (item: FileItem) => (
    <CaseFileItem
      key={item.uuid}
      fileItem={item}
      handleRemoveFile={() => {
        handleRemoveFile(item)
      }}
    />
  )

  const handleSubmitAttachFile = useCallback(async () => {
    await supportCaseUpdate({
      caseId,
      files:
        attachments?.map((item: FileItem) => ({
          name: item.file.name,
          size: `${item.fileSize}`,
          url: `https://uploadfiles.opswat.com/file/${item.fileId}`
        })) ?? []
    })
      .unwrap()
      .then((caseResponse: any) => {
        if (caseResponse.success) {
          handleCloseDialog()
          setHasUploadedPackage(true)
        } else {
          enqueueSnackbar('An error has occurred. Please try again and contact support if the error persists.', {
            variant: 'error'
          })
        }
      })
  }, [attachments])

  useEffect(() => {
    if (fileItems.length < 5 && errors === FileErrors.MAX_FILE_NUM) {
      setErrors('')
    }
  }, [fileItems.length])

  useEffect(() => {
    setValue('attachments', fileItems)
  }, [fileItems])

  return (
    <DialogAdvanced
      open={openDialog}
      title={
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h3">Upload Support Package for Case #{caseNumber}</Typography>
          <IconButton onClick={handleCloseDialog} sx={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
      }
      content={
        <Grid container columnSpacing={2} sx={{ mt: '0px' }}>
          <Grid item xs={12} sx={{ position: 'sticky', top: 0, pb: '16px', backgroundColor: '#FFFFFF', zIndex: 3 }}>
            <Typography variant="subtitle2" marginBottom={1.5} lineHeight="21px" color="#485161">
              Support package containing logs and configuration details for troubleshooting or providing to the support
              team. <br /> Note: This file may contain sensitive information, please keep it confidential.
            </Typography>
            <Typography variant="subtitle1" height="20px" fontSize="12px" mb={0.5}>
              Upload File
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '5px',
                mb: 1
              }}
            >
              <TextField
                fullWidth
                size="small"
                placeholder="Select"
                value={_get(currentFile, 'name') ?? ''}
                error={!!errors}
                onClick={() => inputFile?.current?.click()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '4px' }}>
                          <AttachPaperclip />
                        </Box>
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{
                  '& :hover': {
                    cursor: 'pointer'
                  },
                  '& .MuiOutlinedInput-root': { paddingRight: 0 },
                  '& .MuiFormHelperText-root': { margin: 0, marginTop: 1 },
                  minHeight: '0px'
                }}
              />
              <Button
                disableElevation
                variant="contained"
                color="primary"
                disabled={!currentFile}
                onClick={() => currentFile && handleUploadFile(currentFile)}
              >
                Upload
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px'
              }}
            >
              <FormHelperText
                sx={{
                  color: '#707682',
                  fontSize: '11px',
                  fontStyle: 'italic',
                  lineHeight: '16px',
                  fontWeight: 400
                }}
              >
                Maximum number of uploaded files: {attachments?.length}/5
              </FormHelperText>
              <FormHelperText
                sx={{
                  color: '#707682',
                  fontSize: '11px',
                  fontStyle: 'italic',
                  lineHeight: '16px',
                  fontWeight: 400
                }}
              >
                Maximum upload file size: 2GB
              </FormHelperText>
            </Box>
            {!!errors && (
              <FormHelperText
                sx={{
                  fontSize: '11px',
                  fontStyle: 'italic',
                  lineHeight: '16px',
                  fontWeight: 400,
                  color: '#D00300'
                }}
              >
                {errors}
              </FormHelperText>
            )}
            <input
              type="file"
              id="submit-case-file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={onFilesChange}
              onClick={(event: any) => {
                event.target.value = null // to allow re-uploads of the same file
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <List sx={{ padding: 0 }}>{_map(fileItems, handleRenderFileItem)}</List>
          </Grid>
        </Grid>
      }
      actions={
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'text',
                  color: 'inherit',
                  onClick: handleCloseDialog
                }}
                propsLoading={{ color: 'inherit' }}
                isLoading={false}
              >
                Cancel
              </ButtonLoading>
            </Grid>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  onClick: handleSubmitAttachFile,
                  disableElevation: true,
                  disabled:
                    attachments?.length === 0 ||
                    attachments?.some((item: FileItem) => item.status !== FileStatus.SUCCESS) ||
                    isUpdatingCase
                }}
                isLoading={isUpdatingCase}
                propsLoading={{ color: 'inherit' }}
              >
                Submit
              </ButtonLoading>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogTitleProps={{
        sx: {
          marginInline: '22px',
          paddingY: '20px',
          paddingX: '0px'
        }
      }}
      dialogContentProps={{
        sx: {
          padding: '0px 22px',
          minHeight: '250px'
        }
      }}
      dialogProps={{
        sx: {
          '& .MuiDialog-paper': {
            maxWidth: '580px'
          }
        }
      }}
    />
  )
}

export default DialogUploadSupportPackage
