import _find from 'lodash/find'
import _get from 'lodash/get'

import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SettingsIcon } from '@opswat/react-icon'
import { Box, RoundedSwitch, Typography } from '@opswat/react-ui'

import { useSubscribeCriticalUserMutation } from 'myopswat-web/src/api/support'
import useGetPortalPermissions from 'myopswat-web/src/hooks/useGetPortalPermissions'
import useHasPermissions from 'myopswat-web/src/hooks/useHasPermissions'
import { myOrganizationCriticalAlertUsersPageURL } from 'myopswat-web/src/routes/myOrganizationRoutes'

import { CommonLinearBox } from '../components/CommonLinearBox'

interface SubscribeAlertsProps {
  orgId: string
  criticalAlertUsers: any[]
  userEmail: string
  hasOrg?: boolean
  isLoading?: boolean
}

export const SubscribeAlerts: React.FC<SubscribeAlertsProps> = ({
  orgId,
  criticalAlertUsers = [],
  userEmail,
  hasOrg,
  isLoading
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { permissions } = useGetPortalPermissions()
  const hasFullOrgCriticalAlertPerm = useHasPermissions({
    targetPerms: ['full_my_organizations_criticalalert'],
    userPerms: permissions
  })
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [subscribeCriticalUser] = useSubscribeCriticalUserMutation()

  const isSubscribeCritical = useMemo(() => {
    return !!_find(criticalAlertUsers, item => _get(item, 'email') === userEmail)
  }, [criticalAlertUsers, userEmail])

  useEffect(() => {
    setIsSubscribed(isSubscribeCritical)
  }, [isSubscribeCritical])

  const handleSwitchChange = async (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    await handleUpdateSubscribed(checked)
  }

  const handleUpdateSubscribed = async (subscribeFlg: boolean) => {
    try {
      const response = await subscribeCriticalUser({
        orgId,
        subscribeFlg
      }).unwrap()

      setIsSubscribed(response?.success ? subscribeFlg : !subscribeFlg)

      const message = subscribeFlg ? t('updateSubscribeSuccess') : t('updateUnSubscribeSuccess')
      enqueueSnackbar(response?.success ? message : t('updateSubscribeFail'), {
        variant: response?.success ? 'success' : 'error'
      })
    } catch {
      enqueueSnackbar(t('updateSubscribeFail'), { variant: 'error' })
    }
  }

  const handleClickSetting = (e: React.MouseEvent<Element, MouseEvent>) => {
    navigate(myOrganizationCriticalAlertUsersPageURL)
    e.preventDefault()
  }

  if (!hasOrg) return null

  return (
    <CommonLinearBox isLoading={isLoading}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          gap: 2
        }}
      >
        <Typography variant="body1" color={'#242424'}>
          Subscribe to Critical Alerts
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 1,
            flex: '1 1 auto'
          }}
        >
          <RoundedSwitch checked={isSubscribed} onChange={handleSwitchChange} />
          {hasFullOrgCriticalAlertPerm && (
            <Box
              sx={{
                cursor: 'pointer'
              }}
              onClick={handleClickSetting}
            >
              <SettingsIcon />
            </Box>
          )}
        </Box>
      </Box>
    </CommonLinearBox>
  )
}
