import React from 'react'
import { useNavigate } from 'react-router-dom'

import { FalseDetectionIcon } from '@opswat/react-icon'
import { Box, Typography } from '@opswat/react-ui'

import { reportFalseDetectionSubmitPageURL } from 'myopswat-web/src/routes'

import { CommonLinearBox } from '../components/CommonLinearBox'

export const FalseDetection = () => {
  const navigate = useNavigate()

  const handleFalseDetectionClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    navigate(reportFalseDetectionSubmitPageURL)
    e.preventDefault()
  }

  return (
    <CommonLinearBox>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          height: '100%',
          gap: 2,
          cursor: 'pointer'
        }}
        onClick={handleFalseDetectionClick}
      >
        <Typography
          variant="body1"
          sx={{
            color: '#242424',
            flex: '1 1 auto'
          }}
        >
          False Detection Report
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 1,
            flex: '1 1 auto'
          }}
        >
          <FalseDetectionIcon />
        </Box>
      </Box>
    </CommonLinearBox>
  )
}
