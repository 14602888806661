import { SolidRightIcon } from '@opswat/react-icon'
import { Box, TableCell, Typography, useTheme } from '@opswat/react-ui'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import { OEM, UTILITY } from 'myopswat-web/src/constants/product-type'
import { homePageURL } from 'myopswat-web/src/routes'
import { FC } from 'react'
import { Link } from 'react-router-dom'

interface NameCellProps {
  data: any
  level: number
  isCollapsible: boolean
  openCollapse: boolean
  onToggleCollapse: () => void
}

export const NameCell: FC<NameCellProps> = ({ data, level, isCollapsible, openCollapse, onToggleCollapse }) => {
  const theme = useTheme()

  return (
    <TableCell sx={{ p: 0.25, width: 400, paddingLeft: level * 2 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isCollapsible ? (
          <Box
            component="span"
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              transform: `rotate(${openCollapse ? 90 : 0}deg)`,
              transition: 'transform 0.15s ease',
              cursor: 'pointer'
            }}
            onClick={onToggleCollapse}
          >
            <SolidRightIcon size={20} />
          </Box>
        ) : (
          <span style={{ minWidth: 20, display: 'inline-block' }}></span>
        )}
        {level === 0 ? (
          <Typography
            variant="body2"
            color="primary"
            component={Link}
            to={`${homePageURL}/${_get(data, 'slug', '')}`}
            sx={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer'
              },
              marginLeft: 1
            }}
          >
            {_get(data, 'name')}
          </Typography>
        ) : (
          <Typography variant="body2" color="textPrimary" sx={{ marginLeft: 1 }}>
            {_get(data, 'name')}
          </Typography>
        )}
        {_includes(_get(data, 'tags'), UTILITY) && <TagLabel label="Utility" color={theme.palette.info.main} />}
        {level === 0 && _includes(_get(data, 'tags'), OEM) && <TagLabel label="OEM" color={theme.palette.info.main} />}
      </div>
    </TableCell>
  )
}

const TagLabel: FC<{ label: string; color: string }> = ({ label, color }) => (
  <Box
    component="span"
    sx={{
      bgcolor: color,
      borderRadius: '4px',
      paddingLeft: '8px',
      paddingRight: '8px',
      ml: 2
    }}
  >
    <Typography variant="caption">{label}</Typography>
  </Box>
)
