import { PhoneSupportIcon } from '@opswat/react-icon'
import { Box, Skeleton, Typography } from '@opswat/react-ui'
import React from 'react'
import PhoneList from './PhoneList'

interface PhoneSupportProps {
  regions: {
    location: string
    children: { value: string; label: string }[]
  }[]
  isLoading?: boolean
  supportLevel?: string
}

export const PhoneSupport: React.FC<PhoneSupportProps> = ({ regions, isLoading, supportLevel }) => {
  return (
    <Box
      sx={{
        border: '1px solid #E9EAEB',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
          height: 66,
          background: 'linear-gradient(263.88deg, #E1E9FE 0%, #FFFFFF 80.38%)',
          flexShrink: 0
        }}
      >
        <Typography variant="h3" color={'#242424'}>
          Phone Support
        </Typography>
        <PhoneSupportIcon />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {isLoading ? (
          <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" />
        ) : (
          <PhoneList regions={regions} supportLevel={supportLevel} />
        )}
      </Box>
    </Box>
  )
}
