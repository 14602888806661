import { Box, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import { FC, useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { renderToString } from 'react-dom/server'
import { getSeriesPercentages } from '../../sections/ChartSection/utils'

interface IProps {
  data: any
  maxCategory?: number
}

function hslToHex(h: number, s: number, l: number): string {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n: number) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function generateBluePalette(n: number): string[] {
  const baseHue = 220; // Blue hue
  const saturation = 100; // Full saturation for vivid blue
  const lightnessStart = 10; // Start with a dark blue
  const lightnessEnd = 90; // End with a light blue

  const colors: string[] = [];

  if (n < 2) {
      [hslToHex(baseHue, saturation, lightnessStart)]
  }

  for (let i = 0; i < n; i++) {
      // Calculate lightness for each step
      const lightness = lightnessStart + ((lightnessEnd - lightnessStart) / (n - 1)) * i;
      colors.push(hslToHex(baseHue, saturation, lightness));
  }

  return colors;
}

const PurchaseRateChart: FC<IProps> = ({ data, maxCategory = 4 }) => {
  const [totalLicenses, setTotalLicenses] = useState(0)
  const [licensesData, setLicensesData] = useState([])

  const tooltipComponent = (series: number[], seriesIndex: number) => {
    const percentages = getSeriesPercentages(series)
    const formatter = Intl.NumberFormat('en', { notation: 'compact' })
    const totalOrganizations = _get(licensesData, [seriesIndex, 'total_organizations'], 0)
    return renderToString(
      <div className="custom-chart-tooltip">
        <span className="custom-chart-tooltip-title">{percentages[seriesIndex]}%</span>
        <span className="custom-chart-tooltip-content">{_get(licensesData, [seriesIndex, 'name'], '')}</span>
        <span className="custom-chart-tooltip-content">
          {formatter.format(totalOrganizations)} {`Organization${totalOrganizations > 1 ? 's' : ''}`}
        </span>
        <span className="custom-chart-tooltip-content">
          {formatter.format(series[seriesIndex])} {`License${series[seriesIndex] > 1 ? 's' : ''}`}
        </span>
      </div>
    )
  }
  useEffect(() => {
    setTotalLicenses(_get(data, 'total_licenses', 0))
    setLicensesData(_get(data, 'data', []))
  }, [data])

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flex: 7 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', alignItems: 'flex-start' }}>
          <Typography variant="subtitle1" color="#707682" textTransform="uppercase">
            Customer Product Purchase Distribution
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Typography variant="h1" fontSize="24px" fontWeight={400}>
              {totalLicenses} Licenses
            </Typography>
            <Typography variant="subtitle2">Total Purchases</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 5 }}>
        <ReactApexChart
          key={crypto.randomUUID()}
          id="customer-purchase-rate-chart"
          series={licensesData.map(d => _get(d, 'total_licenses'))}
          type="donut"
          width="200px"
          height="200px"
          options={{
            colors: licensesData.length > 0 ? generateBluePalette(licensesData.length) : undefined,
            chart: {
              animations: {
                enabled: false
              }
            },
            plotOptions: {
              pie: {
                expandOnClick: false
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            tooltip: {
              custom: ({ series, seriesIndex }) => tooltipComponent(series, seriesIndex)
            },
            states: {
              active: {
                filter: {
                  type: 'none' // Prevents darkening on click
                }
              },
              hover: {
                filter: {
                  type: 'lighten',
                  value: 0.1
                }
              }
            },
          }}
        />
      </Box>
    </Box>
  )
}

export default PurchaseRateChart
