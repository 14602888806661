export const DIALOGS_WEB = {
  NPS_SURVEY: 'nps-survey',
  NPS_SURVEY_ORIGIN: 'nps-survey-origin',
  NPS_SURVEY_HIDDEN: 'nps-survey-hidden',
  NPS_NOTIFICATION: 'nps-notification',
  REFERRAL_SOURCE: 'referral-source',
  PERMISSION_DENIED: 'permission-denied',
  OCM_NO_ORGANIZATION: 'ocm-no-organization',
  INVALID_CUSTOMER_ORGANIZATION: 'invalid-customer-organization'
}
