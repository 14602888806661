import { CSSProperties, FC } from 'react'

import { Tooltip, Typography, TypographyLineClamp } from '@opswat/react-ui'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import _get from 'lodash/get'

interface IProps {
  mainLabel: string
  subLabel: string
  sfdcData: any
  sx?: CSSProperties
}

const CustomerTooltipSubOrgLabel: FC<IProps> = ({ mainLabel, subLabel, sfdcData, sx }) => {
  const oppCloseDate = _get(sfdcData, 'CloseDate')
  const oppType = _get(sfdcData, 'Type__c', '--')
  const formattedDate = formatDatetime(oppCloseDate, DATE_FORMATS.DATE)
  const tooltipTitle = `${mainLabel}
    ${formattedDate} - ${oppType}
    Organization Name: ${subLabel}
  `
  return mainLabel ? (
    <Tooltip
      arrow
      placement="top"
      title={tooltipTitle}
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: '500px',
            color: '#1B273C',
            backgroundColor: '#E9EAEB',
            whiteSpace: 'pre-line'
          }
        },
        arrow: {
          sx: {
            color: '#E9EAEB'
          }
        }
      }}
    >
      <Typography
        style={{
          wordBreak: 'break-all',
          display: '-webkit-box',
          WebkitLineClamp: 1,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...sx
        }}
      >
        {mainLabel}
        <span>{` (${formattedDate} - ${oppType} - ${subLabel?.substring(0, 5)}...)`}</span>
      </Typography>
    </Tooltip>
  ) : (
    <TypographyLineClamp
      line={1}
      variant="body2"
      tooltipValue={subLabel}
      tooltipPlacement="top"
      tooltipProps={{
        tooltip: {
          sx: {
            color: '#1B273C',
            backgroundColor: '#E9EAEB',
            whiteSpace: 'pre-line'
          }
        },
        arrow: {
          sx: {
            color: '#E9EAEB'
          }
        }
      }}
      sx={sx}
    >
      {subLabel}
    </TypographyLineClamp>
  )
}

export default CustomerTooltipSubOrgLabel
