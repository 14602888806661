export const US_PLATINUM = 'US In-Country Platinum'

export enum SUPPORT_LEVEL {
  STANDARD = 'Standard',
  SILVER = 'Silver',
  GOLD = 'Gold',
  PLATINUM = 'Platinum',
  US_PLATINUM = 'US In-Country Platinum',
  EMERALD = 'Emerald'
}

export const PRODUCTS_OS_MAP = {
  'MetaDefender Core': ['Linux', 'Windows'],
  'MetaDefender ICAP Server': ['Linux', 'Windows'],
  'MetaDefender Kiosk': ['Linux', 'Windows'],
  'MetaDefender Storage Security': ['Linux', 'Windows'],
  'MetaDefender Endpoint Security SDK': ['Linux', 'Windows'],
  'MetaDefender IT-OT Access NAC': ['Linux', 'Windows']
}

export const SINGLE_PHONE_SUPPORT_MAP = {
  [SUPPORT_LEVEL.US_PLATINUM]: {
    label: 'US',
    value: '+1 855 677 9281'
  }
}
