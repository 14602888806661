import _range from 'lodash/range'

import { useContext, useState } from 'react'
import { Controller, useWatch } from 'react-hook-form'

import { Autocomplete, Grid, Skeleton, TextField } from '@opswat/react-ui'

import moreProductIcon from 'myopswat-web/src/assets/images/more-products-icon.png'

import SubmitCaseProduct from '../components/SubmitCaseProduct'
import { SupportSubmitCaseContext } from '../interface'
import { SUBMIT_CASE_OPTION } from '../constants'

const SubmitCaseProductStep: React.FC<any> = () => {
  const { control, productList, isLoadingProductList, showMoreProducts, setShowMoreProducts, handleSelectProduct } =
    useContext(SupportSubmitCaseContext)

  const productId = useWatch({ control, name: 'productId' })

  return (
    <>
      {isLoadingProductList && (
        <Grid
          container
          columns={{
            xs: 2,
            sm: 3,
            md: 4,
            lg: 6
          }}
          spacing={2}
        >
          {_range(12).map(index => (
            <Grid item xs={1} key={index}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="128px"
                sx={{
                  borderRadius: '5px'
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {!isLoadingProductList && (
        <Grid
          container
          columns={{
            xs: 2,
            sm: 3,
            md: 4,
            lg: 6
          }}
          spacing={2}
        >
          {productList.slice(0, 11).map(item => (
            <Grid item xs={1} key={item.id}>
              <SubmitCaseProduct
                id={item.id}
                name={item.name}
                imageUrl={item.productIcon}
                isSelected={productId === item.id}
                handleSelectProduct={(id: string) => {
                  handleSelectProduct(id)
                  setShowMoreProducts(false)
                }}
              />
            </Grid>
          ))}
          {productList.length > 11 && (
            <Grid item xs={1}>
              <SubmitCaseProduct
                id={SUBMIT_CASE_OPTION.MORE}
                name={SUBMIT_CASE_OPTION.MORE}
                imageUrl={moreProductIcon}
                isSelected={showMoreProducts}
                handleSelectProduct={() => {
                  handleSelectProduct('')
                  setShowMoreProducts(true)
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
      {showMoreProducts && (
        <Controller
          name="productId"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              placeholder="Select Organization"
              options={productList.slice(11).map((item: any) => ({ label: item.name, value: item.id }))}
              isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  placeholder="Select Product"
                  sx={{
                    minHeight: 'auto',
                    marginTop: '12px'
                  }}
                />
              )}
              onChange={(_: any, newValue: any) => {
                handleSelectProduct(newValue)
              }}
            />
          )}
        />
      )}
    </>
  )
}

export default SubmitCaseProductStep
