import { Configuration } from '@botpress/webchat'

export enum CHAT_BOT_CUSTOM_EVENT {
  TOPIC_CHANGED = 'topicChanged',
  CONVERSATION = 'conversation',
  CONVERSATION_STARTED = 'conversationStarted',
  LIVE_CHAT_REQUESTED = 'liveChatRequested'
}

export const SUPPORT_CHATBOT_CONFIG: Configuration = {
  botName: ' ',
  botAvatar: 'https://myopswat-staging.s3.us-east-2.amazonaws.com/images/chatbot_avatar.gif',
  composerPlaceholder: 'Message AI Assistant',
  showPoweredBy: false
}

export const SUPPORT_CHATBOT_PLACEHOLDER = 'Need help or get support? Start here'

export const SUPPORT_CHATBOT_HELPER_TEXT = {
  ACTION: 'Try something like',
  KEYWORD_1: '"File scan stuck in processing state in Core"',
  KEYWORD_2: '"Does MetaDefender Kiosk support encrypted devices?"'
}
