import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSubmitCaseBlocker } from 'myopswat-web/src/hooks/useSubmitCaseBlocker'
import SupportSubmitCaseProvider from 'myopswat-web/src/pages/SupportSubmitCasePage/context'
import { supportServicesPageURL } from 'myopswat-web/src/routes'

import { Grid, TypographyDivider } from '@opswat/react-ui'

const SupportSubmitCaseContainer = () => {
  const navigate = useNavigate()

  const { handleCheckSubmitCase } = useSubmitCaseBlocker()

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Support',
        path: supportServicesPageURL,
        onClick: (url: string) => {
          handleCheckSubmitCase(() => navigate(url))
        }
      },
      {
        label: 'Submit A Case'
      }
    ]
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TypographyDivider breadcrumbsArray={breadcrumbs} isDivider={false} />
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <SupportSubmitCaseProvider />
      </Grid>
    </Grid>
  )
}

export default SupportSubmitCaseContainer
