import { PageLoading } from '@opswat/react-ui'

import imageBg from 'myopswat-web/src/assets/images/mfa-bg.png'
import { useSaml2Logout } from 'myopswat-web/src/hooks/useSaml2Logout'

export default function LogoutContainer() {
  useSaml2Logout()

  return <PageLoading background={imageBg} color="primary" />
}
