import { useModal } from '@ebay/nice-modal-react'
import { Skeleton, Switch } from '@opswat/react-ui'
import { ORGANIZATION_ADMIN_ROLE } from 'myopswat-web/src/constants'
import { useMemo } from 'react'
import ConfirmModal from './MFA/ConfirmModal'
import useHasPermissions from '../../../hooks/useHasPermissions'
interface IProps {
  isLoading: boolean
  organization: any
  afterSave: () => void
  permissions: string[]
}

const OrganizationSecurity = ({ isLoading, organization, afterSave, permissions }: IProps) => {
  const hasFullOrgSecurityPerm = useHasPermissions({targetPerms: ['full_my_organizations_security'], userPerms: permissions})
  const mfaConfirmModal = useModal(ConfirmModal)

  const handleChange = (checked: boolean) => {
    mfaConfirmModal
      .show({
        organization: organization,
        isEnable: checked
      })
      .then(() => {
        afterSave()
        mfaConfirmModal.hide()
      })
  }

  return (
    <Switch
      checked={organization?.requiredMfa}
      disabled={!hasFullOrgSecurityPerm}
      label={
        isLoading ? (
          <Skeleton animation="wave" variant="text" width="300px" />
        ) : (
          'Require Multi-factor Authentication for Users'
        )
      }
      handleChange={handleChange}
    />
  )
}

export default OrganizationSecurity
