import React, { FC } from 'react'

import _get from 'lodash/get'

import { DialogAdvanced } from '@opswat/react-ui'

import { selectDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useTypedSelector } from 'myopswat-web/src/store'
import { DIALOGS_WEB } from '../../constants/dialogs'
import PermissionDeniedForm from '../Form/PermissionDeniedForm'

const DialogPermissionsDenied: FC<unknown> = () => {
  const dialogType = useTypedSelector(selectDialogs)

  return (
    <DialogAdvanced
      open={_get(dialogType, DIALOGS_WEB.PERMISSION_DENIED, false)}
      disableBackdropClick={false}
      content={<PermissionDeniedForm />}
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}

export default DialogPermissionsDenied
