import React from 'react'
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form'

export type CustomerLicensesContextProps = {
  controlProduct: Control<any>
  controlCloud: Control<any>
  setValueProduct: UseFormSetValue<any>
  setValueCloud: UseFormSetValue<any>
  getValuesProduct: UseFormGetValues<any>
  permissions: any
  reportData: any
  reportError: any
  organizationList: any[]
  activateSuccessful: boolean
  isGeneratingReport: boolean
  isFetchingCustomerTree: boolean
  handleGenerateReport: () => void
  handleOnClickChart: (key: string, value: any) => void
  setCsvFilter: (data: any) => void
  defaultProductFilters: any
}

export const CustomerLicensesContext = React.createContext<CustomerLicensesContextProps>({
  controlProduct: {} as Control<any>,
  controlCloud: {} as Control<any>,
  setValueProduct: {} as UseFormSetValue<any>,
  setValueCloud: {} as UseFormSetValue<any>,
  getValuesProduct: {} as UseFormGetValues<any>,
  permissions: {},
  reportData: {},
  reportError: {},
  organizationList: [],
  activateSuccessful: false,
  isGeneratingReport: false,
  isFetchingCustomerTree: false,
  handleGenerateReport: () => {
    return
  },
  handleOnClickChart: () => {
    return
  },
  setCsvFilter: () => {
    return
  },
  defaultProductFilters: {}
})

export const NO_PRODUCT_LICENSES_MESSAGE = 'No product licenses found.'
export const NO_CLOUD_LICENSES_MESSAGE = 'No cloud licenses found.'
