import _debounce from 'lodash/debounce'
import { ChangeEvent, useEffect, useMemo } from 'react'

import {
  Button,
  Checkbox,
  DropdownFilter,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextFieldSearch,
  Typography
} from '@opswat/react-ui'

import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'

import { FormControl } from '@opswat/react-ui'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ORGANIZATION_EVENT_ACTION_TYPES, ORGANIZATION_EVENT_ACTIONS, TIME_FILTERS } from './constants'
import { EventHistoryFilterForm } from './types'

interface IProps {
  filters: EventHistoryFilterForm
  onSearch: (searchData: EventHistoryFilterForm) => void
}

const EventHistoryFilter = ({ filters, onSearch }: IProps) => {
  const { t: translate } = useTranslation()

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { dirtyFields }
  } = useForm<EventHistoryFilterForm>({
    mode: 'onSubmit',
    defaultValues: filters,
    values: filters
  })

  const selectedActions = useWatch({
    control: control,
    name: 'actions'
  })

  const q = useWatch({
    control: control,
    name: 'q'
  })

  const isChangedTextSearch = useMemo(() => {
    return dirtyFields.q
  }, [dirtyFields.q])

  const onSuccess = (data: EventHistoryFilterForm) => {
    onSearch(data)
  }

  const handleSelectActions = (selectedActionType: string, checked: boolean) => {
    let newActions
    if (checked) {
      const checkedActions = Object.keys(ORGANIZATION_EVENT_ACTIONS).filter((action) => {
        return ORGANIZATION_EVENT_ACTIONS[action] === selectedActionType
      })
      newActions = [...selectedActions, ...checkedActions]
    } else {
      newActions = selectedActions.filter(action => ORGANIZATION_EVENT_ACTIONS[action] !== selectedActionType)
    }
    return newActions
  }

  useEffect(() => {
    if (isChangedTextSearch) {
      const handleSearch = _debounce(() => {
        const searchData = {
          ...getValues(),
          q: q
        }
        onSearch(searchData)
      }, 300)

      handleSearch()
      return () => {
        handleSearch.cancel()
      }
    }
  }, [q, isChangedTextSearch])

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={(cProps: any) => (
            <TextFieldSearch
              placeholder={translate('filterUserName') || ''}
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onClearText={() => cProps.field.onChange('')}
              onKeyUp={(e: any) => {
                if (e.target.value === '') {
                  onSearch({
                    ...getValues(),
                    q: ''
                  })
                }
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs="auto">
        <DropdownFilter
          button={
            <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
              Filter
            </Button>
          }
          content={
            <Grid container>
              <Grid item xs={6}>
                <Controller
                  name="actions"
                  control={control}
                  render={({ field }) => (
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Action</Typography>
                      </Grid>

                      {Object.keys(ORGANIZATION_EVENT_ACTION_TYPES).map((key, index) => {
                        return (
                          <Grid item xs={12} key={index.toString()}>
                            <FormControlLabel
                              key={`user-filter-status-${index}`}
                              control={
                                <Checkbox
                                  checked={selectedActions.map((action) => ORGANIZATION_EVENT_ACTIONS[action]).includes(key)}
                                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    field.onChange(handleSelectActions(key, event.target.checked))
                                  }
                                />
                              }
                              label={<Typography variant="subtitle2">{ORGANIZATION_EVENT_ACTION_TYPES[key]}</Typography>}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={'time'}
                  control={control}
                  render={({ field }) => (
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Time</Typography>
                      </Grid>

                      <FormControl>
                        <RadioGroup
                          value={field.value}
                          aria-labelledby="row-radio-buttons-group-label"
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            field.onChange(event.target.value)
                          }}
                        >
                          <Grid container>
                            {Object.keys(TIME_FILTERS).map((key, index) => {
                              return (
                                <Grid item xs={12} key={index.toString()}>
                                  <FormControlLabel
                                    key={key}
                                    value={key}
                                    control={<Radio size="small" />}
                                    label={<Typography variant="subtitle2">{TIME_FILTERS[key]}</Typography>}
                                  />
                                </Grid>
                              )
                            })}
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                />
              </Grid>
            </Grid>
          }
          onResetFilter={() => {
            reset({
              q: q,
              actions: [],
              time: ''
            })
          }}
          onResetChange={() => {
            reset({ ...filters, q })
          }}
          onApply={() => {
            handleSubmit(onSuccess)()
          }}
        />
      </Grid>
    </Grid>
  )
}

export default EventHistoryFilter
