import { Box, TemplateSection, TypographyDivider } from '@opswat/react-ui'

import { supportServicesPageURL } from 'myopswat-web/src/routes'
import SupportServiceCaseTable from './components/CasesTableV2'

const SupportServiceAllCases = () => {
  const breadcrumbsHomeArray = [
    {
      label: 'Support',
      path: supportServicesPageURL
    },
    {
      label: 'All Cases'
    }
  ]

  return (
    <TemplateSection>
      {/* Breadcrumbs  */}
      <Box>
        <TypographyDivider breadcrumbsArray={breadcrumbsHomeArray} isDivider={false} />
      </Box>

      {/* Case Table */}
      <SupportServiceCaseTable />
    </TemplateSection>
  )
}

export default SupportServiceAllCases
