import React from 'react'

import { Box, Link, Tooltip, Typography } from '@opswat/react-ui'

interface IProps {
  icon: React.ReactNode
  label: React.ReactNode
  link?: string
}

const BoxResource: React.FC<IProps> = ({ icon, label, link }) => {
  const linkRef = React.useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverflowing] = React.useState(false)

  React.useEffect(() => {
    const checkOverflow = () => {
      if (linkRef.current) {
        setIsOverflowing(linkRef.current.offsetWidth < linkRef.current.scrollWidth)
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [link])

  return (
    <Tooltip
      arrow
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            color: '#1B273C',
            backgroundColor: '#E9EAEB',
            maxWidth: '400px'
          }
        },
        arrow: {
          sx: {
            color: '#E9EAEB'
          }
        }
      }}
      title={isOverflowing ? link : ''}
    >
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F4F4F5',
          padding: '12px',
          '&:hover': {
            backgroundColor: '#EFF4FF',
            cursor: 'pointer'
          }
        }}
        component={Link}
        href={link || ''}
        target="_blank"
        underline="none"
      >
        <Box
          sx={{
            pr: 1.5,
            py: 1.25,
            borderRight: '1px solid #D2D4D6'
          }}
        >
          <Box sx={{ width: '16px', height: '16px' }}>{icon}</Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '16px',
            flexGrow: 1,
            overflow: 'hidden'
          }}
        >
          <Typography variant="subtitle1" color={'#1B273C'} mb={1}>
            {label}
          </Typography>

          <Typography
            ref={linkRef}
            variant="caption"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '14px',
              color: '#485161'
            }}
          >
            {link}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  )
}

export default BoxResource
