import { useModal } from '@ebay/nice-modal-react'
import { OptionIcon } from '@opswat/react-icon'
import {
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableAdvanced,
  TemplateSection,
  Typography
} from '@opswat/react-ui'
import _isEmpty from 'lodash/isEmpty'
import { useOrganizationCriticalAlertUsersQuery } from 'myopswat-web/src/api/organization'
import { OrganizationCriticalAlertUserFilterInput } from 'myopswat-web/src/api/organization/types'
import { ORGANIZATION_ADMIN_ROLE } from 'myopswat-web/src/constants'
import { useMemo, useState } from 'react'
import AddUserModal from './AddUser/AddUserModal'
import RemoveUserModal from './RemoveUser/RemoveUserModal'
import CritalAlertUserFilter from './filters'
import useHasPermissions from '../../../hooks/useHasPermissions'

const defaultQuery: OrganizationCriticalAlertUserFilterInput = {
  q: ''
}

interface IProps {
  organization: any
  isFetching: boolean
  permissions: string[]
}

const CritalAlertUserList = ({ organization, isFetching, permissions }: IProps) => {
  const hasFullOrgCriticalAlertPerm = useHasPermissions({targetPerms: ['full_my_organizations_criticalalert'], userPerms: permissions})
  const [query, setQuery] = useState<OrganizationCriticalAlertUserFilterInput>(defaultQuery)
  const {
    data,
    isFetching: isFetchingUsers,
    refetch
  } = useOrganizationCriticalAlertUsersQuery(query, {
    refetchOnMountOrArgChange: true
  })

  const addUserModal = useModal(AddUserModal)
  const removeUserModal = useModal(RemoveUserModal)

  const tableData = useMemo(() => {
    if (_isEmpty(data)) return []

    return data.map((item: any) => ({
      id: item.id,
      name: item.name,
      email: item.email
    }))
  }, [data])

  const handleSearch = (searchData: OrganizationCriticalAlertUserFilterInput) => {
    setQuery(prev => ({ ...prev, ...searchData }))
  }

  const isLoading = useMemo(() => {
    return isFetching || isFetchingUsers
  }, [isFetching, isFetchingUsers])

  const isAdmin = useMemo(() => {
    return organization.roles?.includes(ORGANIZATION_ADMIN_ROLE)
  }, [organization])

  const onAddUser = () => {
    addUserModal.show({ organization: organization }).then(async () => {
      await refetch()
    })
  }
  const handleRemoveUser = (user: any) => () => {
    removeUserModal
      .show({
        organization: organization,
        alertUser: user
      })
      .then(async () => {
        await refetch()
      })
  }

  const columns = useMemo(() => {
    return [
      {
        header: 'Name',
        body: (data: any) => <Typography variant="body2">{data?.name || '--'}</Typography>,
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'Email',
        body: (data: any) => <Typography variant="body2">{data?.email}</Typography>,
        style: { minWidth: 200, width: 200 }
      },
      {
        header: '',
        body: (data: any) =>
          hasFullOrgCriticalAlertPerm ? (
            <Dropdown
              button={
                <IconButton>
                  <OptionIcon />
                </IconButton>
              }
              content={
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={handleRemoveUser(data)}>Remove</ListItemButton>
                  </ListItem>
                </List>
              }
            />
          ) : (
            <></>
          ),
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [isAdmin])
  return (
    <TemplateSection spacing={2}>
      <CritalAlertUserFilter onSearch={handleSearch} query={query} onAddUser={onAddUser} hasPerm={hasFullOrgCriticalAlertPerm} />
      <TableAdvanced columns={columns} isLoading={isLoading} data={tableData} />
    </TemplateSection>
  )
}

export default CritalAlertUserList
