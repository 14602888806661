import { useCallback } from 'react'

import { useSubmitSupportCaseMutation } from '../api/support/submit-case'
import {
  selectIsDirtySubmitCase,
  selectSubmitCaseData,
  selectSubmitCaseSuccessful
} from '../containers/SupportServicesContainer/supportServicesContainerSlice'
import { supportServicesSubmitCasePageURL } from '../routes'
import { useTypedSelector } from '../store'

import useConfirmationDialog from './useConfirmationDialog'

export const useSubmitCaseBlocker = () => {
  const { openConfirmation } = useConfirmationDialog()
  const submitCaseData = useTypedSelector(selectSubmitCaseData)
  const submitCaseSuccessful = useTypedSelector(selectSubmitCaseSuccessful)
  const isDirtySubmitCase = useTypedSelector(selectIsDirtySubmitCase)

  const [submitSupportCase] = useSubmitSupportCaseMutation()

  const handleCheckSubmitCase = useCallback(
    async (callback: () => void) => {
      if (window.location.pathname === supportServicesSubmitCasePageURL && !submitCaseSuccessful && isDirtySubmitCase) {
        // check store
        const confirmDiscard = await openConfirmation({
          content: 'Are you sure you want to leave this page? All unsaved changes will be lost.',
          confirmText: 'Confirm',
          cancelText: 'Cancel'
        })
        if (confirmDiscard) {
          submitSupportCase({ ...submitCaseData, isCancel: true })
            .then(() => {
              callback()
            })
            .catch(() => {
              callback()
            })
        }
      } else {
        callback()
      }
    },
    [window.location.pathname, submitCaseSuccessful, submitCaseData, isDirtySubmitCase]
  )

  return { handleCheckSubmitCase }
}
