import { useContext } from 'react'
import { useWatch } from 'react-hook-form'

import { Grid } from '@opswat/react-ui'

import SubmitCaseSeverity from '../components/SubmitCaseSeverity'
import { SUBMIT_CASE_SEVERITY } from '../constants'
import { SupportSubmitCaseContext } from '../interface'

const SubmitCaseSeverityStep: React.FC<any> = () => {
  const { control, handleSelectSeverity } = useContext(SupportSubmitCaseContext)

  const severity = useWatch({ control, name: 'severity' })

  return (
    <Grid
      container
      columns={{
        xs: 1,
        sm: 2,
        md: 4
      }}
      spacing={2}
      sx={{
        alignItems: 'stretch'
      }}
    >
      {Object.values(SUBMIT_CASE_SEVERITY).map((item: any, index: number) => (
        <Grid item xs={1} key={item}>
          <SubmitCaseSeverity
            level={index + 1}
            name={item}
            isSelected={severity === `Severity ${index + 1}`}
            handleSelectSeverity={(id: number) => {
              handleSelectSeverity(id)
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default SubmitCaseSeverityStep
