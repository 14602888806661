import { SeverityIcon } from '@opswat/react-icon'
import { Box, Button, Typography } from '@opswat/react-ui'

interface IProps {
  level: number
  name: string
  isSelected: boolean
  handleSelectSeverity: (id: number) => void
}

const SubmitCaseSeverity: React.FC<IProps> = ({ level, name, isSelected, handleSelectSeverity }) => {
  const handleGetSeverityColor = (level: number) => {
    switch (level) {
      case 1:
        return isSelected ? '#FFFFFF' : '#D00300'
      case 2:
        return isSelected ? '#FFFFFF' : '#ED6706'
      case 3:
        return isSelected ? '#FFFFFF' : '#FDBD0D'
      case 4:
        return isSelected ? '#FFFFFF' : '#008A00'
      default:
        return ''
    }
  }

  return (
    <Button
      fullWidth
      sx={{
        height: '100%',
        backgroundColor: isSelected ? '#1D6BFC' : '#E9EAEB',
        borderRadius: '5px',
        padding: '24px 12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: isSelected ? '#195DDB' : '#D2D4D6'
        }
      }}
      onClick={() => handleSelectSeverity(level)}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          gap: '4px'
        }}
      >
        <SeverityIcon
          level={level}
          size={level === 1 ? 16 : 18}
          color={isSelected ? '#FFFFFF' : handleGetSeverityColor(level)}
          inactiveColor={isSelected ? '#5E95FD' : '#BCBFC3'}
        />
        <Typography
          variant="subtitle2"
          sx={{
            color: isSelected ? '#FFFFFF' : '#000000'
          }}
        >
          {level}
        </Typography>
      </Box>
      <Typography
        variant="caption"
        height="40px"
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: isSelected ? '#FFFFFF' : '#000000'
        }}
      >
        {name}
      </Typography>
    </Button>
  )
}

export default SubmitCaseSeverity
