export const LICENSE_STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Expiring',
    value: 'expiring'
  },
  {
    label: 'Expired',
    value: 'expired'
  }
]

export const LICENSE_TYPE_OPTIONS = [
  {
    label: 'Evaluation',
    value: 'EVALUATED'
  },
  {
    label: 'Purchased',
    value: 'PURCHASED'
  }
]

export const LICENSE_AVAILABILITY_OPTIONS = [
  {
    label: 'In Use',
    value: 'in_use'
  },
  {
    label: 'Not In Use',
    value: 'not_in_use'
  }
]

export const LICENSE_NEAR_CAP_OPTIONS = [
  {
    label: '> 80%',
    value: 'near_cap'
  }
]

export const ALL_VALUE_OPTION = 'all'
