import { CircularLoading, Grid, Typography } from '@opswat/react-ui'

const SubmitCaseLoadingView = () => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        height: '80vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 8
      }}
    >
      <CircularLoading type="large" />
      <Typography variant="body2">This may take a few seconds</Typography>
    </Grid>
  )
}

export default SubmitCaseLoadingView
