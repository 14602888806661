import { FC } from 'react'

import { Grid, Typography } from '@opswat/react-ui'

interface IProps {
  title: React.ReactNode
  value: React.ReactNode
  unit?: any
  hidden?: boolean
  isNotNumeric?: boolean
}

const CloudServiceLabel: FC<IProps> = ({ title, value, unit, hidden = false, isNotNumeric = false }) => {
  return (
    <Grid
      item
      container
      sx={{
        visibility: hidden ? 'hidden' : 'visible'
      }}
    >
      <Grid item xs={5}>
        <Typography component="label" fontSize="12px" lineHeight="20px" fontWeight={500}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {value !== undefined ? (
          <Typography component="span" fontSize="12px" lineHeight="20px" fontWeight={500}>
            {value}
            {unit && (
              <Typography component="span" fontSize="12px" lineHeight="20px" fontWeight={400}>
                {' '}
                {isNotNumeric && unit}
                {!isNotNumeric && Number(value) === 1 ? unit.singular : unit.plural}
              </Typography>
            )}
          </Typography>
        ) : (
          '--'
        )}
      </Grid>
    </Grid>
  )
}

export default CloudServiceLabel
