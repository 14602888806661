import React from 'react'

import { Control, UseFormHandleSubmit, UseFormSetValue, UseFormTrigger } from 'react-hook-form'

export type SupportSubmitCaseContextProps = {
  control: Control<any>
  setValue: UseFormSetValue<any>
  handleSubmit: UseFormHandleSubmit<any>
  trigger: UseFormTrigger<any>
  organizationList: any[]
  isLoadingOrganizationList: boolean
  productList: any[]
  isLoadingProductList: boolean
  suggestionList: any
  isLoadingSuggestionList: boolean
  currentView: number
  caseId: string
  caseNumber: string
  completedFormSteps: Set<number>
  hasUploadedPackage: boolean
  hasFullMyCustomerCasesPerm: boolean
  showMoreProducts: boolean
  setShowMoreProducts: (value: boolean) => void
  setHasUploadedPackage: (value: boolean) => void
  handleSelectProduct: (value: any) => void
  handleSelectPlatform: (value: any) => void
  handleSelectIssueType: (value: any) => void
  handleSelectSeverity: (value: any) => void
  handleSubmitCase: () => void
  handleNextView: () => void
  handleOpenDialogUpload: () => void
  handleCancelSubmitCase: () => void
  handleResolveSubmitCase: () => void
  handleGetKBSuggestions: (keyword: string, productId: string) => Promise<any>
  handleGoBack: () => void
}

export const SupportSubmitCaseContext = React.createContext<SupportSubmitCaseContextProps>({
  control: {} as Control<any>,
  setValue: {} as UseFormSetValue<any>,
  handleSubmit: {} as UseFormHandleSubmit<any>,
  trigger: {} as UseFormTrigger<any>,
  organizationList: [],
  isLoadingOrganizationList: true,
  productList: [],
  isLoadingProductList: true,
  suggestionList: [],
  isLoadingSuggestionList: true,
  hasFullMyCustomerCasesPerm: false,
  currentView: 0,
  caseId: '',
  caseNumber: '',
  completedFormSteps: new Set<number>([]),
  hasUploadedPackage: false,
  showMoreProducts: false,
  setShowMoreProducts: () => {
    return
  },
  setHasUploadedPackage: () => {
    return
  },
  handleSelectProduct: () => {
    return
  },
  handleSelectPlatform: () => {
    return
  },
  handleSelectIssueType: () => {
    return
  },
  handleSelectSeverity: () => {
    return
  },
  handleSubmitCase: () => {
    return
  },
  handleNextView: () => {
    return
  },
  handleOpenDialogUpload: () => {
    return
  },
  handleCancelSubmitCase: () => {
    return
  },
  handleResolveSubmitCase: () => {
    return
  },
  handleGetKBSuggestions: () => {
    return Promise.resolve({})
  },
  handleGoBack: () => {
    return
  }
})
