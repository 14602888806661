import React, { FC, useState, useEffect } from 'react'

import _get from 'lodash/get'

import { TemplateSection } from '@opswat/react-ui'

import {
  useDownloadedProductAddMutation,
  useDownloadedProductsQuery,
  useIcapIntegrationsQuery,
  useLazyProductQuery
} from 'myopswat-web/src/api/product'

import { IDownloadedProductInput, IDownloadedProductsFilter } from 'myopswat-web/src/api/product/types'
import BoxDownloadedFilter from './BoxDownloadedFilter'
import BoxDownloadedTable from './BoxDownloadedTable'

const DownloadableProduct: FC<unknown> = () => {
  const [downloadedFilter, setDownloadedFilter] = useState<IDownloadedProductsFilter>({
    q: '',
    time: ''
  })

  const {
    data: downloadedProductsData,
    isFetching: isFetchingDownloadedProducts,
    refetch: refetchDownloadProducts
  } = useDownloadedProductsQuery(downloadedFilter)

  const [downloadedProductAdd, { data: downloadedProductAddData }] = useDownloadedProductAddMutation()

  const { data: icapIntegrationsData, refetch: refecthIcapIntegrations } = useIcapIntegrationsQuery()

  const [getProduct, { data: productDetailData, isFetching: isFetchingProductDetail }] = useLazyProductQuery()

  const handleDownloadedProductAdd = async (data: IDownloadedProductInput, isICAP?: boolean) => {
    try {
      await downloadedProductAdd(data).unwrap()
      if (isICAP) {
        refecthIcapIntegrations()
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (_get(downloadedProductAddData, 'success')) {
      refetchDownloadProducts()
    }
  }, [downloadedProductAddData])

  useEffect(() => {
    refetchDownloadProducts()
  }, [downloadedFilter, refetchDownloadProducts])

  return (
    <TemplateSection spacing={2}>
      <BoxDownloadedFilter setDownloadedFilter={setDownloadedFilter} downloadedFilter={downloadedFilter} />
      <BoxDownloadedTable
        downloadedProductsData={downloadedProductsData}
        isFetchingDownloadedProducts={isFetchingDownloadedProducts}
        handleDownloadedProductAdd={handleDownloadedProductAdd}
        icapIntegrationsData={icapIntegrationsData}
        productDetailData={productDetailData}
        getProduct={getProduct}
        isFetchingProductDetail={isFetchingProductDetail}
      />
    </TemplateSection>
  )
}

export default DownloadableProduct
