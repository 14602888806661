import { Button, Grid, TextFieldSearch } from '@opswat/react-ui'
import _debounce from 'lodash/debounce'
import { FC, useEffect, useMemo } from 'react'

import { OrganizationCriticalAlertUserFilterInput } from 'myopswat-web/src/api/organization/types'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface IProps {
  query: OrganizationCriticalAlertUserFilterInput
  onSearch: (searchData: OrganizationCriticalAlertUserFilterInput) => void
  onAddUser: () => void
  hasPerm: boolean
}

const CritalAlertUserFilter: FC<IProps> = ({ query, onSearch, onAddUser, hasPerm }) => {
  const { t: translate } = useTranslation()

  const {
    control,
    formState: { dirtyFields }
  } = useForm<OrganizationCriticalAlertUserFilterInput>({
    mode: 'onSubmit',
    defaultValues: query
  })

  const q = useWatch({
    control: control,
    name: 'q'
  })

  const isChangedTextSearch = useMemo(() => {
    return dirtyFields.q
  }, [dirtyFields])

  useEffect(() => {
    if (isChangedTextSearch) {
      const handleSearch = _debounce(() => {
        const searchData = {
          q: q
        }
        onSearch(searchData)
      }, 300)

      handleSearch()
      return () => {
        handleSearch.cancel()
      }
    }
  }, [q, isChangedTextSearch])

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={(cProps: any) => (
            <TextFieldSearch
              placeholder={translate('filterUserName') || ''}
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onClearText={() => cProps.field.onChange('')}
              onKeyUp={(e: any) => {
                if (e.target.value === '') {
                  onSearch({
                    q: ''
                  })
                }
              }}
            />
          )}
        />
      </Grid>
      {hasPerm && (
        <Grid item xs="auto">
          <Grid container spacing={2}>
            <Grid item xs="auto">
              <Button onClick={onAddUser} variant="contained" color="primary">
                Add User
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default CritalAlertUserFilter
